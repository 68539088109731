import React from "react";

interface DropdownProps {
  title: string;
  options: Array<{ warehouseId: string; warehouseName: string }>;
  label: string;
  isOpen: boolean;
  selectedOption: string | null;
  onToggle: () => void;
  onOptionChange: (optionId: string) => void;
  onClose: () => void;
  position?: string;
  section?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  title,
  options,
  label,
  isOpen,
  selectedOption,
  onToggle,
  onOptionChange,
  onClose,
  position,
  section,
}) => {
  function truncateText(text: string, charLimit: number = 25): string {
    if (text.length > charLimit) {
      return text.slice(0, charLimit) + "...";
    }
    return text;
  }

  return (
    <div
      className={`relative inline-block text-left ${
        section === "finance" ? "lg:w-52 w-full" : "w-full"
      } ${position === "top" ? "z-40" : "z-30"}`}
    >
      <p className="text-xs mb-2 ms-1">{label}</p>

      <button
        onClick={onToggle}
        className="inline-flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
        type="button"
      >
        {selectedOption
          ? truncateText(
              options.find((option) => option.warehouseId === selectedOption)
                ?.warehouseName || title
            )
          : truncateText(title)}

        <svg
          className="w-4 h-4 ml-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          className="absolute left-0 z-50 mt-2 w-full origin-top-right bg-white border border-gray-300 rounded-md shadow-lg"
          onBlur={onClose}
          tabIndex={-1}
        >
          <div
            className="py-1 max-h-60 overflow-y-auto"
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "#d1d5db transparent",
            }}
          >
            {options.map((option) => (
              <div
                key={option.warehouseId}
                className={`flex items-center w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 cursor-pointer ${
                  selectedOption === option.warehouseId ? "bg-gray-100" : ""
                }`}
                onClick={() => onOptionChange(option.warehouseId)}
              >
                {option.warehouseId !== "null" && (
                  <div className="relative flex items-center justify-center w-5 h-5 rounded-full border-2 border-gray-800">
                    {selectedOption === option.warehouseId && (
                      <div className="w-3 h-3 rounded-full bg-black"></div>
                    )}
                  </div>
                )}
                <span className="ml-3">{option.warehouseName}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
