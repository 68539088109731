import Cookies from "js-cookie";
import axios from "./config";
import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  emp_id: string;
}

export function getHeader() {
  const token = Cookies.get("token") || "";
  const header = {
    Authorization: token,
  };
  return header;
}

const getUserInfo = () => {
  const token = Cookies.get("token");

  if (!token) {
    throw new Error("Authorization token not found");
  }

  return jwtDecode<DecodedToken>(token);
};

const getAddressTable = async (param:any,data:any): Promise<any> => {
  const user_info = getUserInfo();

  const response = await axios.post<any>(
    `/nwc/addresses/`,
    data,
    {
      headers: getHeader(),
      params:param
    }
  );
  return response.data;
};

export default getAddressTable;
