import axios from "./config";

export const getExportData = async (
  month: string | null,
  year: Number | null
): Promise<any> => {
  const response = await axios.get<any>(
    `nwc/export/customer-statement?month=${month}&year=${year}`,
    {
      responseType: "blob",
    }
  );
  return response;
};

export const getDataTobePrinted = async (
  month: string | null,
  year: Number | null
): Promise<any> => {
  const response = await axios.get<any>(
    `nwc/customer-statement?month=${month}&year=${year}`
  );
  return response;
};

export const getTableData = async (
    month: string | null,
    year: Number | null
  ): Promise<any> => {
    const response = await axios.get<any>(
      `nwc/customer-statement?month=${month}&year=${year}`
    );
    return response;
  };
