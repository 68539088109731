import React, { useState, useEffect } from "react";
import logo from "../../assets/logo.svg";
import OpenEye from "../../assets/open-eye.svg";
import CloseEye from "../../assets/close-eye.svg";
import LoaderButton from "../../components/LoaderButton";
import LanguageSelector from "../../components/LangSelector";
import { useTranslation } from "react-i18next";
interface NewPasswordProps {
  loader: boolean;
  handleSubmit: any;
  handleBack?: any;
  checkError: string;
}

const NewPassword: React.FC<NewPasswordProps> = ({
  handleSubmit,
  loader,
  handleBack,
  checkError,
}) => {
  const [showPassword1, setShowPassword1] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>();
  const [confirmPass, setConfirmPassword] = useState<string>();
  const [error, setError] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const [isRTL, setIsRTL] = useState(false);

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setError(""); // Clear error on password change
  };

  const handleChangeConfirmPassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
    if (e.target.value !== password) {
      setError("Passwords do not match.");
    } else {
      setError(""); // Clear error if they match
    }
  };

  const dir = document.documentElement.getAttribute("dir");
  useEffect(() => {
    let isRtl = false;
    isRtl = dir === "rtl";
    setIsRTL(isRtl);
  }, [dir]);

  return (
    <>
      {" "}
      <div className="relative w-full md:w-1/2 flex flex-col justify-center items-center bg-white md:p-24 sm:p-14 p-10">
        <div className="mb-8">
          <img src={logo} alt="Logo" className="h-18" />
        </div>
        <div className="absolute top-[-12%] md:top-10 right-[13%]">
          <LanguageSelector />
        </div>
        <h2 className="text-2xl font-bold mb-6 text-purple">New Password</h2>

        <div className="mb-4 w-full relative">
          <label className="block text-gray-700">New Password</label>
          <input
            type={showPassword1 ? "text" : "password"}
            value={password}
            onChange={handleChangePassword}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 bg-lightgrey h-12"
          />
          <button
            className={`absolute top-11 transform -translate-y-1/2 ${
              isRTL ? "left-3" : "right-3"
            }`}
            onClick={() => setShowPassword1(!showPassword1)}
            type="button"
          >
            {showPassword1 ? (
              <img src={CloseEye} alt="Hide password" />
            ) : (
              <img src={OpenEye} alt="Show password" />
            )}
          </button>
        </div>

        <div className="mb-4 w-full relative">
          <label className="block text-gray-700">Confirm Password</label>
          <input
            type={showPassword ? "text" : "password"}
            value={confirmPass}
            onChange={handleChangeConfirmPassword}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 bg-lightgrey h-12"
          />
          <button
            className={`absolute top-11 transform -translate-y-1/2 ${
              isRTL ? "left-3" : "right-3"
            }`}
            onClick={() => setShowPassword(!showPassword)}
            type="button"
          >
            {showPassword ? (
              <img src={CloseEye} alt="Hide password" />
            ) : (
              <img src={OpenEye} alt="Show password" />
            )}
          </button>
        </div>

        <div className="mb-4 w-full text-red-600 text-sm text-center mt-6">
          {error}
        </div>
        <div className="mb-4 w-full text-red-600 text-sm text-center mt-6">
          {checkError}
        </div>

        <LoaderButton
          text="Submit"
          onClick={() => handleSubmit(password)}
          isLoading={loader}
          disabled={(password && error) ? true : false}
        />
      </div>
    </>
  );
};

export default NewPassword;
