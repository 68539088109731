import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../pages/login/index";
import SignUp from "../pages/login/signUp";
import Salesman from "../pages/salesman/Salesman";
import ErrorPage from "../pages/common/error";
import URL_MAPPING from "./constants";
import CustomerTable from "../pages/employee/index";
import DashboardZamzam from "../pages/zamzam/Dashboardzamzam";
import Finance from "../pages/zamzam/Finance";
import Dashboard from "../pages/dashboard/index";
import CustomerDetails from "../pages/customer_details/customer_details";
import TerritoryPlanner from "../pages/territory";
import ResetPass from "../pages/login/resetPass";
import GoogleAuth from "../pages/login/googleAuth";
import Landing from "../pages/landing/index";
import Cookies from "js-cookie";
import DashboardAnalytics from "../pages/dashboard_analytics/index";
import EmployeeTable from "../pages/employee/index";
import MasterList from "../pages/customer_details/masterList";
import Planogram from "../pages/planogram/index";
import Notifications from "../pages/notification_screen/index";
import VisitReport from "../pages/visit_report/index";
import DepositReport from "../pages/deposit_report/index";
import SalesReport from "../pages/sales_summary/index";
import InvoiceReport from "../pages/invoice_report/index";
import MapView from "../pages/map_view/MapView";
import KAKRReport from "../pages/ka_kr_survey_report/index";import DeliveryReport from "../pages/delivery_driver/delivery_report/index";
import ROLE_GROUPS from "./roles";
import { getUserInfo } from "../utility";
import DeliveryDashboard from "../pages/delivery_driver/dashboard/index";
import IventoryZamZam from "../pages/zamzam/IventoryZamZam";
import CreateOrderZamZam from "../pages/zamzam/CreateOrderZamZam";
import AgingReport from "../pages/aging_report/index";
import OrderHistory from "../pages/zamzam/OrderHistory";
import ManageAddr from "../pages/zamzam/ManageAddr";
import StockReport from "../pages/ka_kr_stock_report";
import Survey from "../pages/zamzam/Pages/Survey";
import Dynamic from "../pages/zamzam/Pages/DynamicPage";
interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  [key: string]: any; // To accept any additional props
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  allowedRoles = [],
  ...rest
}) => {
  const isAuthenticated = Cookies.get("token");
  const userRoles = getUserInfo();

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" replace />;
  }

  if (userRoles) {
    const hasAccess = allowedRoles?.includes(userRoles?.role);
    if (!hasAccess) {
      return <Navigate to="*" replace />;
    }
  }

  return <Component {...rest} />;
};

const AllRoutes: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path={URL_MAPPING.LOGIN} element={<Login />} />
      <Route path={URL_MAPPING.LOGIN_ROOT} element={<Login />} />
      <Route path={URL_MAPPING.SIGNUP} element={<SignUp />} />
      <Route path={URL_MAPPING.RESETPASS} element={<ResetPass />} />
      <Route path={URL_MAPPING.GOOGLEAUTH} element={<GoogleAuth />} />
      <Route path="*" element={<ErrorPage />} />

      {/* protected routes */}
      <Route
        path={URL_MAPPING.ANALYTICS}
        element={
          <ProtectedRoute
            component={DashboardAnalytics}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />
      <Route
        path={URL_MAPPING.SALESMAN}
        element={
          <ProtectedRoute
            component={Salesman}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />
      <Route
        path={URL_MAPPING.ACTIVITYREPORT}
        element={
          <ProtectedRoute
            component={CustomerTable}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />
      <Route
        path={URL_MAPPING.DASHBOARD}
        element={
          <ProtectedRoute
            component={Dashboard}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />
      <Route
        path={URL_MAPPING.CUSTOMERLISTDETAILS}
        element={
          <ProtectedRoute
            component={CustomerDetails}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />
      <Route
        path={URL_MAPPING.TERRITORIES}
        element={
          <ProtectedRoute
            component={TerritoryPlanner}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />

      <Route
        path={URL_MAPPING.LANDING}
        element={
          <ProtectedRoute
            component={Landing}
            allowedRoles={[
              ...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR,
              ...ROLE_GROUPS.DELIVERY_DRIVER,
            ]}
          />
        }
      />
      <Route
        path={URL_MAPPING.AGINGREPORT}
        element={
          <ProtectedRoute
            component={AgingReport}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />

      <Route
        path={URL_MAPPING.EMPLOYEETABLE}
        element={
          <ProtectedRoute
            component={EmployeeTable}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />

      <Route
        path={URL_MAPPING.MASTERLIST}
        element={
          <ProtectedRoute
            component={MasterList}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />

      <Route
        path={URL_MAPPING.PLANOGRAM}
        element={
          <ProtectedRoute
            component={Planogram}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />
      <Route
        path={URL_MAPPING.NOTIFICATIONS}
        element={
          <ProtectedRoute
            component={Notifications}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />
      <Route
        path={URL_MAPPING.VISITREPORT}
        element={
          <ProtectedRoute
            component={VisitReport}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />
      <Route
        path={URL_MAPPING.DEPOSITREPORT}
        element={
          <ProtectedRoute
            component={DepositReport}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />
      <Route
        path={URL_MAPPING.SALESREPORT}
        element={
          <ProtectedRoute
            component={SalesReport}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />
      <Route
        path={URL_MAPPING.INVOICEREPORT}
        element={
          <ProtectedRoute
            component={InvoiceReport}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />
      <Route
        path={URL_MAPPING.MAPVIEW}
        element={
          <ProtectedRoute
            component={MapView}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />
      <Route
        path={URL_MAPPING.DELIVERYDASHBOARD}
        element={
          <ProtectedRoute
            component={DeliveryDashboard}
            allowedRoles={[...ROLE_GROUPS.DELIVERY_DRIVER]}
          />
        }
      />
      <Route
        path={URL_MAPPING.DELIVERYREPORT}
        element={
          <ProtectedRoute
            component={DeliveryReport}
            allowedRoles={[...ROLE_GROUPS.DELIVERY_DRIVER]}
          />
        }
      />
      <Route
        path={URL_MAPPING.INVENTORY}
        element={
          <ProtectedRoute
            component={DashboardZamzam}
            allowedRoles={[...ROLE_GROUPS.ZAMZAM_ADMIN ,...ROLE_GROUPS.ZAMZAM_INVENTORY]}
          />
        }
      />
      <Route
        path={URL_MAPPING.CREATEORDER}
        element={
          <ProtectedRoute
            component={CreateOrderZamZam}
            allowedRoles={[...ROLE_GROUPS.ZAMZAM_ADMIN ,...ROLE_GROUPS.ZAMZAM_ORDER]}
          />
        }
      />
      <Route
        path={URL_MAPPING.FINANCE}
        element={
          <ProtectedRoute
            component={Finance}
            allowedRoles={[...ROLE_GROUPS.ZAMZAM_ADMIN ,...ROLE_GROUPS.ZAMZAM_FINANCE]}
          />
        }
      />
           <Route path={URL_MAPPING.SURVEY} element={<Survey />} />
           <Route path={URL_MAPPING.DYNAMIC} element={<Dynamic />} />
      <Route
        path={URL_MAPPING.ORDERHISTORY}
        element={
          <ProtectedRoute
            component={OrderHistory}
            allowedRoles={[...ROLE_GROUPS.ZAMZAM_ADMIN , ...ROLE_GROUPS.ZAMZAM_ORDER]}
          />
        }
      />
      <Route
        path={URL_MAPPING.MANAGEADDR}
        element={
          <ProtectedRoute
            component={ManageAddr}
            allowedRoles={[...ROLE_GROUPS.ZAMZAM_ADMIN ,...ROLE_GROUPS.ZAMZAM_ORDER]}
          />
        }
      />
             <Route
        path={URL_MAPPING.KAKRREPORT}
        element={
          <ProtectedRoute
            component={KAKRReport}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />
        <Route
        path={URL_MAPPING.STOCKREPORT}
        element={
          <ProtectedRoute
            component={StockReport}
            allowedRoles={[...ROLE_GROUPS.PRESELLER_SALESMAN_SUPERVISOR]}
          />
        }
      />   
    </Routes>
  );
};

export default AllRoutes;
