import React, { useState } from "react";

interface SearchableDropdownProps {
  addressOptions: { id: string; name: string }[];
  selectedAddress: string;
  handleAddressSelectChange: (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  loading: boolean;
  error: string | null;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  addressOptions,
  selectedAddress,
  handleAddressSelectChange,
  loading,
  error,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  console.log(addressOptions, "address options");

  const filteredOptions = addressOptions?.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  console.log(filteredOptions, "filtered options");

  const handleSelect = (selectedValue: string) => {
    const syntheticEvent = {
      target: { value: selectedValue },
    } as React.ChangeEvent<HTMLSelectElement>;

    handleAddressSelectChange(syntheticEvent);
    setSearchTerm(
      addressOptions.find((opt) => opt.id === selectedValue)?.name || ""
    );
    setIsOpen(false);
  };

  return (
    <div className="relative w-[25vw]">
      <div className="flex items-center bg-gray-100 py-2 px-4 rounded-md">
        <input
          type="text"
          placeholder="Select Address"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={() => setIsOpen(true)}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 sm:text-sm"
        />
      </div>

      {isOpen && (
        <div className="ms-4 w-[95%] mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-40 overflow-y-auto z-10">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <div
                key={option.id}
                className="p-2 hover:bg-indigo-100 cursor-pointer"
                onClick={() => handleSelect(option.id)}
              >
                {option.name}
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500">No results found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
