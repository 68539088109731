import React from "react";

export type TableColumn = {
  header: string;
  key: string;
  formatter?: (value: any) => string;
};

interface TableData {
  results: Array<{
    StorageLocName: string;
    NavStockToItem: Array<{
      MaterialId: string;
      MaterialDesc: string;
      AvailableStock: string;
      InQualityInspection: string;
      Blocked: string;
      Return: string;
      InTransit: string;
      Unit: string;
      UnitText: string;

    }>;
    TotalAvailableStock: string;
    TotalInQualityInspection: string;
    TotalBlocked: string;
    TotalReturns: string;
    TotalInTransits: string;
  }>;
}

type DynamicTableProps = {
  columns: TableColumn[];
  data: TableData | null;
  metadata?: {
    date?: string;
    logoSrc?: string;
    title?: string;
    logoSrcnwc?: string;
  };
};

const ComponentToPrint: React.FC<DynamicTableProps> = ({
  columns,
  data,
  metadata,
}) => {
  console.log('data3', data);  
  return (
    <div id="print-content" className="hidden print:block">
      <div className="flex justify-between items-center mb-6">
        <div className="flex flex-col gap-2 text-8">
          <p>Date: {metadata?.date || "-"}</p>
      
            <div className="flex item center space-x-2">
              <img src={metadata?.logoSrc} alt="Logo" className="h-16" />
              <img src={metadata?.logoSrcnwc} alt="NWC Logo" className="h-16 self-start" />
            </div>

      
        </div>
        <div className="text-center">
          <h1 className="text-2xl font-bold">
            {metadata?.title || "Dynamic Report"}
          </h1>
        </div>
      </div>

      {data?.results.map((result, resultIndex) => (
        <div key={resultIndex} className="mb-10">
          <h2 className="text-lg font-bold mb-4">{result.StorageLocName}</h2>
          <table className="w-full mb-6 border-collapse border border-gray-300">
            <thead>
              <tr>
                {columns.map((col, index) => (
                  <th
                    key={index}
                    className="border border-gray-300 p-2 bg-gray-100 text-left"
                  >
                    {col.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {result.NavStockToItem.length > 0 ? (
                result.NavStockToItem.map((item, itemIndex) => (
                  <tr key={itemIndex} className="text-xs hover:bg-gray-50">
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {result.StorageLocName}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                     {item.MaterialId || "0"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                     {item.MaterialDesc || "0"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {item.UnitText || "0"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {parseInt(item.AvailableStock) || "0"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {parseInt(item.InQualityInspection) || "0"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {parseInt(item.Blocked) || "0"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {parseInt(item.Return) || "0"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {parseInt(item.InTransit) || "0"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={9} className="text-center py-4 text-xs">
                    No results found
                  </td>
                </tr>
              )}
            </tbody>
            <thead className="bg-[#005879] sticky bottom-0 z-10 text-gray-100">
              <tr>
                <th className="px-4 py-3 text-gray-900 border text-left text-xs font-bold">
                  Total
                </th>
                <th className="px-4 py-3 border text-left text-xs font-bold"></th>
                <th className="px-4 py-3 border text-left text-xs font-bold"></th>
                <th className="px-4 py-3 border text-left text-xs font-bold"></th>
                <th className="px-4 py-3 text-gray-900 border text-left text-xs font-bold">
                  {parseInt(result.TotalAvailableStock) || "0"}
                </th>
                <th className="px-4 py-3 text-gray-900 border text-left text-xs font-bold">
                  {parseInt(result.TotalInQualityInspection) || "0"}
                </th>
                <th className="px-4 py-3 text-gray-900 border text-left text-xs font-bold">
                  {parseInt(result.TotalBlocked) || "0"}
                </th>
                <th className="px-4 py-3 text-gray-900 border text-left text-xs font-bold">
                  {parseInt(result.TotalReturns) || "0"}
                </th>
                <th className="px-4 py-3 text-gray-900 border text-left text-xs font-bold">
                  {parseInt(result.TotalInTransits) || "0"}
                </th>
              </tr>
            </thead>
          </table>
        </div>
      ))}

      <div className="flex justify-between pt-24">
        <div className="w-1/2 text-center border-t border-gray-300 pt-4">
          {/* <p>Accountant's Signature</p> */}
        </div>
        <div className="w-1/2 text-center border-t border-gray-300 pt-4">
          {/* <p>Salesman's Signature</p> */}
        </div>
      </div>
    </div>
  );
};

export default ComponentToPrint;