import React from "react";
import { TableData } from "../Finance";
import logo from "../../../assets/main-logo.png";
import nwc from "../../../assets/nwc.jpg";

export type TableColumn = {
  header: string;
  key: string;
  formatter?: (value: any) => string;
};

type DynamicTableProps = {
  columns: TableColumn[];
  tableData: TableData | null;
  metadata?: {
    date?: string;
    logoSrc?: string;
    title?: string;
    logoSrcnwc?: string;
  };
  month: string | null;
  year: Date | null;
};

const ComponentToPrintFinance: React.FC<DynamicTableProps> = ({
  columns,
  tableData,
  metadata,
  month,
  year,
}) => {
  console.log(year?.getFullYear(), " <<<< DataSummesion");

  function convertToMonth(monthString: string | null): string {
    if (!monthString) {
      return "Invalid month";
    }

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthIndex = parseInt(monthString, 10) - 1;
    if (monthIndex >= 0 && monthIndex < 12) {
      return monthNames[monthIndex];
    } else {
      return "Invalid month";
    }
  }
  return (
    <div id="print-content" className="hidden print:block">
      <div className="flex justify-between items-center mb-6">
        <div className="flex flex-col gap-2 text-8">
          <p>Date: {metadata?.date || "-"}</p>

            <div className="flex item center space-x-2">
              <img src={logo} alt="Logo" className="h-16" />
              <img src={nwc} alt="NWC Logo" className="h-16 self-start" />
            </div>

    
        </div>
        <div className="text-center">
          <h1 className="text-2xl font-bold">
            {metadata?.title || "Dynamic Report"}
          </h1>
        </div>
        <div className="max-w-[50px]">
          <p className="text-sm">
            <b>Month : </b>
            {convertToMonth(month)}
          </p>
          <p className="text-sm">
            <b>Year: </b>
            {year?.getFullYear()}
          </p>
        </div>
      </div>
      <div className="text-left mb-6">
        <p>
          <b>Payer ID :</b> {tableData?.payer_id || "N/A"}
        </p>
        <p>
          {" "}
          <b>Payer Name :</b> {tableData?.payer_name || "N/A"}
        </p>
        <p>
          {" "}
          <b>VAT :</b> {tableData?.vat || "N/A"}
        </p>
        <p>
          {" "}
          <b>CR :</b> {tableData?.cr || "N/A"}
        </p>
      </div>

      <table className="w-full mb-6 border-collapse border border-gray-300">
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th
                key={index}
                className="border border-gray-300 p-2 bg-gray-100 text-left"
              >
                {col.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData && tableData.transactions?.length > 0 ? (
            tableData.transactions.map((items, index) => (
              <tr key={index} className="text-xs hover:bg-gray-50">
                <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                  {items.transaction_type || "N/A"}
                </td>
                <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                  {items.transaction_date || "N/A"}
                </td>
                <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                  {items.transaction_time || "N/A"}
                </td>
                <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                  {items.document_no || "N/A"}
                </td>
                <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                  {items.credit || 0}
                </td>
                <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                  {items.debit || 0}
                </td>
                <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                  {items.carry_forward_balance || 0}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={9} className="text-center py-4 text-xs">
                No results found
              </td>
            </tr>
          )}
        </tbody>
        <thead className="text-gray-100">
          <tr>
            <th className="px-4 py-3 text-gray-800 border text-left text-xs font-bold">
              Total
            </th>
            <th className="px-4 py-3 border text-left text-xs font-bold"></th>
            <th className="px-4 py-3 border text-left text-xs font-bold"></th>
            <th className="px-4 py-3 border text-left text-xs font-bold"></th>
            <th className="px-4 py-3 border text-left text-xs font-bold text-gray-800">
              {tableData?.total_credit || "N/A"}
            </th>
            <th className="px-4 py-3 border text-left text-xs font-bold text-gray-800">
              {tableData?.total_debit || "N/A"}
            </th>
            <th className="px-4 py-3 border text-left text-xs font-bold text-gray-800">
              {tableData?.total_carry_forward_balance || "N/A"}
            </th>
          </tr>
        </thead>
      </table>

      <div className="flex flex-col items-end font-bold space-y-2">
        <p>Opening Balance : {tableData?.opening_balance || "N/A"}</p>
        <p>Closing Balance : {tableData?.closing_balance || "N/A"}</p>
      </div>

      <div className="flex justify-between pt-24">
        <div className="w-1/2 text-center border-t border-gray-300 pt-4">
          {/* <p>Accountant's Signature</p> */}
        </div>
        <div className="w-1/2 text-center border-t border-gray-300 pt-4">
          {/* <p>Salesman's Signature</p> */}
        </div>
      </div>
    </div>
  );
};

export default ComponentToPrintFinance;
