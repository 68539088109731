import React from "react";
import BarChart from "../../../components/BarChart";
import PieChart from "../../../components/PieChart";
import { formatNumber, formatNumberWithoutFraction } from "../../../utility";
import ActiveMember from "../../../assets/active-member.svg";
import InActiveMember from "../../../assets/inactive-member.svg";
import OnLeave from "../../../assets/on-leave.svg";
import { useTranslation } from "react-i18next";

interface ChannelProps {
  dashobardData: any;
}

const Channel: React.FC<ChannelProps> = ({ dashobardData }) => {
  const dataForBarChart = [
    {
      visits: "A",
      value: dashobardData?.visit_metrics?.successful_visits_count
        ? dashobardData?.visit_metrics?.successful_visits_count
        : 0,
    },
    {
      visits: "B",
      value: dashobardData?.visit_metrics?.collection_visits_count
        ? dashobardData?.visit_metrics?.collection_visits_count
        : 0,
    },
    {
      visits: "C",
      value: dashobardData?.visit_metrics?.failed_visits_count
        ? dashobardData?.visit_metrics?.failed_visits_count
        : 0,
    },
    {
      visits: "D",
      value: dashobardData?.visit_metrics?.total_visits_count
        ? dashobardData?.visit_metrics?.total_visits_count
        : 0,
    },
    {
      visits: "E",
      value: dashobardData?.visit_metrics?.total_visits_count
        ? dashobardData?.visit_metrics?.total_visits_count
        : 0,
    },
    {
      visits: "F",
      value: dashobardData?.visit_metrics?.total_visits_count
        ? dashobardData?.visit_metrics?.total_visits_count
        : 0,
    },
    {
      visits: "G",
      value: dashobardData?.visit_metrics?.total_visits_count
        ? dashobardData?.visit_metrics?.total_visits_count
        : 0,
    },
    {
      visits: "H",
      value: dashobardData?.visit_metrics?.total_visits_count
        ? dashobardData?.visit_metrics?.total_visits_count
        : 0,
    },
  ];

  const pieChartDataCallPlan = [
    {
      name: "24 Hrs",
      value: 20,
      color: "#00C48C",
      id: "24 Hrs",
    },
    {
      name: "48 Hrs",
      value: dashobardData?.visit_metrics?.unplanned_visits_count
        ? dashobardData?.visit_metrics?.unplanned_visits_count
        : 0,
      color: "#FFAA2A",
      id: "48 Hrs",
    },
    {
      name: "48+ Hrs",
      value: dashobardData?.visit_metrics?.pending_visits_count
        ? dashobardData?.visit_metrics?.pending_visits_count
        : 0,
      color: "#FF4D68",
      id: "48+ Hrs",
    },
  ];

  const timeSlotAdherance = [
    {
      name: "Within Timeslot",
      value: 20,
      color: "#00C48C",
      id: "Within Timeslot",
    },
    {
      name: "Out Of Timeslot",
      value: dashobardData?.visit_metrics?.unplanned_visits_count
        ? dashobardData?.visit_metrics?.unplanned_visits_count
        : 0,
      color: "#FFAA2A",
      id: "Out Of Timeslot",
    },
  ];
  const numberOfStops = [
    {
      name: "Number Of Deliveries",
      value: 20,
      color: "#00C48C",
      id: "Number Of Deliveries",
    },
    {
      name: "Number Of Stops",
      value: dashobardData?.visit_metrics?.unplanned_visits_count
        ? dashobardData?.visit_metrics?.unplanned_visits_count
        : 0,
      color: "#FFAA2A",
      id: "48 Hrs",
    },
  ];
  const { t } = useTranslation();

  const getFormattedValue = (data: any) => {
    if (Number(data) === 0) {
      return 0;
    }
    const value = Number(data / 4);
    return value > 0.25 ? value : 0.25;
  };

  return (
    <>
      <div className="md:w-full  h-auto lg:h-[calc(100vh-140px)]  lg:overflow-y-scroll flex flex-col gap-2 pr-2 scrollbar scrollbar-thin ">
        <div className="flex justify-between gap-2  w-full flex-col md:flex-row bg-[#F5F5F5] p-2 rounded-md">
          <div className="bg-white border-2 border-purple p-[20px] flex flex-col w-full rounded">
            <div className="text-left">
              <h2 className="text-13 font-bold ">
                {t("DashboardAnalytics.teamCount")}
              </h2>
            </div>
            <div className="flex justify-center gap-3 items-center pt-1">
              <div className="flex flex-col w-full rounded ">
                <div className="flex gap-2 items-center justify-between">
                  <p className="font-normal text-13">
                    {t("DashboardAnalytics.preseller")}
                  </p>
                  <p className="text-xl font-bold">
                    {formatNumberWithoutFraction(
                      dashobardData?.team_metrics?.total_preseller_count || 0
                    )}
                  </p>
                </div>
                <div className="flex gap-2 items-center justify-between">
                  <p className="font-normal text-13">
                    {t("DashboardAnalytics.salesman")}
                  </p>
                  <p className="text-xl font-bold">
                    {formatNumberWithoutFraction(
                      dashobardData?.team_metrics?.total_salesman_count || 0
                    )}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center min-w-[80px] pl-3 border-l-2">
                {/* <div className="h-12 w-[6px] rounded-lg bg-purple mr-2"></div> */}
                <p className="text-13  font-normal	">
                  {t("DashboardAnalytics.total")}
                </p>
                <p className="text-purple text-[39px] font-bold">
                  {formatNumberWithoutFraction(
                    dashobardData?.team_metrics?.total_team_count || 0
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className=" bg-green-50 p-[20px] w-full rounded border-cugreen border-2">
            <div className="text-left flex flex-col justify-between">
              <h2 className="text-green-600 text-base font-semibold">
                {t("DashboardAnalytics.activeMembers")}
              </h2>
              <div className="flex justify-between items-center pt-1">
                <p className=" text-green-600 text-[39px] font-bold mt-2">
                  {formatNumberWithoutFraction(
                    dashobardData?.team_metrics?.total_active_count || 0
                  )}
                </p>
                <img src={ActiveMember} alt="" />
              </div>
            </div>
          </div>

          <div className=" bg-red-50 p-[20px] w-full rounded  border-[#FF4D68] border-2">
            <div className="text-left flex justify-between flex-col">
              <h2 className="text-red-600 text-base	  font-semibold">
                {t("DashboardAnalytics.inactiveMembers")}
              </h2>

              <div className="flex justify-between pt-1 items-center">
                <p className="text-[39px] text-[#FF4D68] font-bold">
                  {formatNumberWithoutFraction(
                    dashobardData?.team_metrics?.total_inactive_count || 0
                  )}
                </p>
                <img src={InActiveMember} alt="" />
              </div>
            </div>
          </div>

          <div className="bg-blue-50 p-[20px] w-full rounded border-2 border-[#408BFC]">
            <div className="text-left flex justify-between flex-col">
              <h2 className="text-blue-600 text-base font-semibold">
                {t("DashboardAnalytics.employeesOnLeave")}
              </h2>
            </div>
            <div className="flex justify-between pt-1 items-center">
              <p className=" text-blue-600 text-[39px] font-bold">
                {formatNumberWithoutFraction(
                  dashobardData?.team_metrics?.total_on_leave_count || 0
                )}
              </p>
              <img src={OnLeave} alt="" />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 bg-[#F5F5F5] p-2 rounded">
          <div className="bg-white p-4 border-2 flex flex-col rounded">
            <h2 className="text-[13px] font-bold mb-4">Pending Orders</h2>
            <div className="h-[200px] w-full mb-4">
              <BarChart
                data={dataForBarChart}
                barColors={[
                  "#FF4D68",
                  "#FFAA2A",
                  "#BE52F2",
                  "#408BFC",
                  "#00C48C",
                  "#00E5DE",
                  "#870091",
                  "#000000",
                ]}
              />
            </div>
            <div className="flex flex-col justify-center gap-2">
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <div
                    className="h-4 w-4 rounded-full"
                    style={{ backgroundColor: "#FF4D68" }}
                  ></div>
                  <span className=" text-[13px]">Pending</span>
                </div>
                <span className="ml-2 text-base">
                  {formatNumberWithoutFraction(
                    dashobardData?.visit_metrics?.failed_visits_count || 0
                  )}
                </span>
              </div>
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <div
                    className="h-4 w-4 rounded-full"
                    style={{ backgroundColor: "#FFAA2A" }}
                  ></div>
                  <span className=" text-[13px]">Horeca</span>
                </div>
                <span className="ml-2 text-base">
                  {formatNumberWithoutFraction(
                    dashobardData?.visit_metrics?.failed_visits_count || 0
                  )}
                </span>
              </div>
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <div
                    className="h-4 w-4 rounded-full"
                    style={{ backgroundColor: "#BE52F2" }}
                  ></div>
                  <span className=" text-[13px]">Key Account</span>
                </div>
                <span className="ml-2 text-base">
                  {formatNumberWithoutFraction(
                    dashobardData?.visit_metrics?.failed_visits_count || 0
                  )}
                </span>
              </div>
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <div
                    className="h-4 w-4 rounded-full"
                    style={{ backgroundColor: "#408BFC" }}
                  ></div>
                  <span className=" text-[13px]">Key Retail</span>
                </div>
                <span className="ml-2 text-base">
                  {formatNumberWithoutFraction(
                    dashobardData?.visit_metrics?.failed_visits_count || 0
                  )}
                </span>
              </div>
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <div
                    className="h-4 w-4 rounded-full"
                    style={{ backgroundColor: "#00C48C" }}
                  ></div>
                  <span className=" text-[13px]">Direct Distribution</span>
                </div>
                <span className="ml-2 text-base">
                  {formatNumberWithoutFraction(
                    dashobardData?.visit_metrics?.failed_visits_count || 0
                  )}
                </span>
              </div>
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <div
                    className="h-4 w-4 rounded-full"
                    style={{ backgroundColor: "#00E5DE" }}
                  ></div>
                  <span className=" text-[13px]">Home Delivery</span>
                </div>
                <span className="ml-2 text-base">
                  {formatNumberWithoutFraction(
                    dashobardData?.visit_metrics?.failed_visits_count || 0
                  )}
                </span>
              </div>
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <div
                    className="h-4 w-4 rounded-full"
                    style={{ backgroundColor: "#870091" }}
                  ></div>
                  <span className=" text-[13px]">Wholesale</span>
                </div>
                <span className="ml-2 text-base">
                  {formatNumberWithoutFraction(
                    dashobardData?.visit_metrics?.failed_visits_count || 0
                  )}
                </span>
              </div>
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <div
                    className="h-4 w-4 rounded-full"
                    style={{ backgroundColor: "#000000" }}
                  ></div>
                  <span className=" text-[13px]">Others</span>
                </div>
                <span className="ml-2 text-base">
                  {formatNumberWithoutFraction(
                    dashobardData?.visit_metrics?.failed_visits_count || 0
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="bg-white border-2 p-4 flex flex-col rounded">
            <h2 className="text-[13px] font-semibold">Delivered Orders</h2>
            <div className="flex pt-2 flex-col-reverse ">
              <div className="flex flex-col w-full gap-2">
                <div className="flex justify-between">
                  <div className="flex gap-2">
                    <div
                      className="h-4 w-4 rounded-full"
                      style={{ backgroundColor: "#FF4D68" }}
                    ></div>
                    <span className=" text-[13px]">24 Hrs</span>
                  </div>
                  <span className="ml-2 text-base">
                    {formatNumberWithoutFraction(
                      dashobardData?.visit_metrics?.failed_visits_count || 0
                    )}
                  </span>
                </div>
                <div className="flex justify-between">
                  <div className="flex gap-2">
                    <div
                      className="h-4 w-4 rounded-full"
                      style={{ backgroundColor: "#FFAA2A" }}
                    ></div>
                    <span className=" text-[13px]">48 Hrs</span>
                  </div>
                  <span className="ml-2 text-base">
                    {formatNumberWithoutFraction(
                      dashobardData?.visit_metrics?.failed_visits_count || 0
                    )}
                  </span>
                </div>
                <div className="flex justify-between">
                  <div className="flex gap-2">
                    <div
                      className="h-4 w-4 rounded-full"
                      style={{ backgroundColor: "#BE52F2" }}
                    ></div>
                    <span className=" text-[13px]">48+ Hrs</span>
                  </div>
                  <span className="ml-2 text-base">
                    {formatNumberWithoutFraction(
                      dashobardData?.visit_metrics?.failed_visits_count || 0
                    )}
                  </span>
                </div>
              </div>
              <div className="h-[250px] w-full flex justify-center items-center">
                <PieChart data={pieChartDataCallPlan} />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 bg-[#F5F5F5] p-2 rounded">
          <div className="bg-white border-2 p-4 flex flex-col rounded">
            <h2 className="text-[13px] font-semibold">
              Delivered Orders Timeslot Adherence
            </h2>
            <div className="flex pt-2 flex-col-reverse ">
              <div className="flex flex-col w-full gap-2">
                <div className="flex justify-between">
                  <div className="flex gap-2">
                    <div
                      className="h-4 w-4 rounded-full"
                      style={{ backgroundColor: "#00C48C" }}
                    ></div>
                    <span className=" text-[13px]">Within Timeslot</span>
                  </div>
                  <span className="ml-2 text-base">
                    {formatNumberWithoutFraction(
                      dashobardData?.visit_metrics?.failed_visits_count || 0
                    )}
                  </span>
                </div>
                <div className="flex justify-between">
                  <div className="flex gap-2">
                    <div
                      className="h-4 w-4 rounded-full"
                      style={{ backgroundColor: "#FFAA2A" }}
                    ></div>
                    <span className=" text-[13px]">Out Of Timeslot</span>
                  </div>
                  <span className="ml-2 text-base">
                    {formatNumberWithoutFraction(
                      dashobardData?.visit_metrics?.failed_visits_count || 0
                    )}
                  </span>
                </div>
              </div>
              <div className="h-[250px] w-full flex justify-center items-center">
                <PieChart data={timeSlotAdherance} />
              </div>
            </div>
          </div>
          <div className="bg-white border-2 p-4 flex flex-col rounded">
            <h2 className="text-[13px] font-semibold">Number Of Stops</h2>
            <div className="flex pt-2 flex-col-reverse ">
              <div className="flex flex-col w-full gap-2">
                <div className="flex justify-between">
                  <div className="flex gap-2">
                    <div
                      className="h-4 w-4 rounded-full"
                      style={{ backgroundColor: "#00C48C" }}
                    ></div>
                    <span className=" text-[13px]">Number Of Deliveries</span>
                  </div>
                  <span className="ml-2 text-base">
                    {formatNumberWithoutFraction(
                      dashobardData?.visit_metrics?.failed_visits_count || 0
                    )}
                  </span>
                </div>
                <div className="flex justify-between">
                  <div className="flex gap-2">
                    <div
                      className="h-4 w-4 rounded-full"
                      style={{ backgroundColor: "#FFAA2A" }}
                    ></div>
                    <span className=" text-[13px]">Number Of Stops</span>
                  </div>
                  <span className="ml-2 text-base">
                    {formatNumberWithoutFraction(
                      dashobardData?.visit_metrics?.failed_visits_count || 0
                    )}
                  </span>
                </div>
              </div>
              <div className="h-[250px] w-full flex justify-center items-center">
                <PieChart data={numberOfStops} />
              </div>
            </div>
          </div>
          <div className="bg-white p-4 border-2 flex flex-col rounded">
            <h2 className="text-13 font-bold">Collection</h2>
            <div className="flex flex-row justify-between items-center flex-wrap py-2" >
              <p className="font-bold text-3xl text-green-400 pt-2">
                {formatNumber(
                  dashobardData?.visit_metrics?.total_collected_amount || 0
                )}{" "}
                {t("DashboardAnalytics.SAR")}
              </p><p>Total Amount Collected</p>
            </div>
            <div className="border-t-2 flex justify-between pt-2">
              {/* todo */}
              <div>
                <p className="text-16 font-bold">
                  {formatNumber(
                    dashobardData?.visit_metrics
                      ?.total_collected_amount_by_card || 0
                  )}{" "}
                  {t("DashboardAnalytics.SAR")} 
                </p>
                
                <p className="text-13 font-semibold">
                  {t("DashboardAnalytics.byCard")}
                </p>
              </div>
              <div className="border w-[1px]"></div>
              <div>
                <p className="text-16 font-bold">
                  {formatNumber(
                    dashobardData?.visit_metrics
                      ?.total_collected_amount_by_cash || 0
                  )}{" "}
                  {t("DashboardAnalytics.SAR")}
                </p>
                <p className="text-13 font-semibold">
                  {t("DashboardAnalytics.byCash")}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white p-2 border-2 p-4 flex flex-col gap-8 rounded  min-h-[120px] flex-wrap">
            <h2 className="text-13 font-bold ">
            Delivered Quantity
            </h2>
            <div className="flex items-center gap-2">
            <p className="text-purple font-bold text-[39px]">
              {formatNumber(dashobardData?.visit_metrics?.drop_size_count || 0)}
            </p>Cartons Sold
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Channel;
