import React, { useEffect, useState, useCallback } from "react";
import DashboardPage from "../../layout";
import getCustomerList from "../../api/masterList";
import PaginationComponent from "../../components/Pagination";
import {
  formatCurrentStartDate,
  formatCurrentEndDate,
  formatNumber,
  TimeZoneDisplay,
  getPagePerSize,
} from "../../utility";
import { getGlobalFilter } from "../../api/filters";
import Export from "../../assets/icons/Export.svg";
import Filter from "../../assets/icons/Filter.svg";
import Loader from "../../components/Loader";
import TimeFrameSelector from "../../components/TimeFrameSelector";
import { formatDate } from "../../utility";
import Close from "../../assets/close-circle.svg";
import Search from "../../assets/Search.svg";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import DropdownMulti from "../../components/DropdownMultiple";
import debounce from "lodash/debounce";
import { toast, ToastContainer } from "react-toastify";

const CustomerTable: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const customersPerPage = getPagePerSize();
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [customers, setCustomersData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [status, setStatus] = useState<any>("");
  const [globalFilters, setGlobalFilters] = useState<any>(null);
  const [timeFrame, setSelectTimeFrame] = useState<any>("Today");
  const [division, setDivision] = useState<any>([]);
  const [salesOffice, setSalesOffice] = useState<any>([]);
  const [emp, setEmployee] = useState<any>("");
  const [customerID, setCustomerID] = useState<any>([]);
  const [route, setRouteID] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [regions, setRegions] = useState<any>([]);
  const [paymentTermId, setPaymentTermId] = useState<any>([]);
  const [sortType, setSortType] = useState<any>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [channel, setChannel] = useState<any>([]);
  const [teretorryId, setTeretorryId] = useState<any>([]);
  const [loadingFilter, setFilterLoading] = useState<any>(false);
  const startIndex = (currentPage - 1) * customers?.size;
  const handleFilterClick = () => {
    setOpen(true);
  };

  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const getGlobalFilters = async (data: any) => {
    setFilterLoading(true);
    try {
      if (loadingFilter) {
        return;
      }
      const response = await getGlobalFilter(data);
      setGlobalFilters(response);
    } catch (error) {
      console.error("Error fetching global filters:", error);
    } finally {
      setFilterLoading(false);
    }
  };

  const handleClearFilters = () => {
    setRouteID([]);
    setEmployee([]);
    setRegions([]);
    setSalesOffice([]);
    setDivision([]);
    setChannel([]);
    setStatus(null);
    setStartDate(getCurrentDate());
    setEndDate(getCurrentDate());
    setOpen(false);
    setSelectTimeFrame("Today");
    setTeretorryId([]);
    setPaymentTermId("");
    handleClearApply("");
  };

  const handleClearApply = (sort: string) => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };
    const params = {
      page: 1,
      size: customersPerPage,
    };
    setCurrentPage(1);
    getCustomerData(data, params);
    handleClose();
  };

  const handleChange = (event: any) => {
    updateSearchTerm(event);
    setSearchQuery(event);
  };

  const getPayload = (data: any) => {
    if (status) {
      data["status"] = status;
    }
    if (teretorryId.length > 0) {
      data["territory_ids"] = teretorryId;
    }
    if (channel.length > 0) {
      data["channel_ids"] = channel;
    }
    if (division.length > 0) {
      data["division_ids"] = division;
    }
    if (customerID.length > 0) {
      data["customer_ids"] = customerID;
    }
    if (emp.length > 0) {
      data["subordinate_ids"] = emp;
    }
    if (regions.length > 0) {
      data["regions"] = regions;
    }
    if (salesOffice.length > 0) {
      data["sales_office_ids"] = salesOffice;
    }

    if (paymentTermId.length > 0) {
      data["payment_term_ids"] = paymentTermId;
    }
    if (route.length > 0) {
      data["route_ids"] = route;
    }
  };

  const updateSearchTerm = useCallback(
    debounce((value: string) => {
      handleSearch(value);
    }, 500),
    [
      status,
      teretorryId,
      channel,
      division,
      customerID,
      emp,
      regions,
      salesOffice,
      paymentTermId,
      route,
      startDate,
      endDate,
    ]
  );

  const handleSearch = (value: string) => {
    setCurrentPage(1);
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };
    if (value) {
      data["search"] = value;
    }
    getPayload(data);

    const params = {
      page: 1,
      size: customersPerPage,
    };
    getCustomerData(data, params);
  };

  const handleSortFillter = (data: string) => {
    setSortType(data);
    setIsDropdownOpen(false);
    handleApply(data);
  };

  const handleClose = () => setOpen(false);

  const getCustomerData = async (data: any, params: any) => {
    setCustomersData([]);
    setLoading(true);
    await getCustomerList(params, data)
      .then((res) => {
        setLoading(false);
        setCustomersData(res);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching Customer data:", error);
      });
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    checkTimeFrame(e.target.value, endDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
    checkTimeFrame(startDate, e.target.value);
  };

  useEffect(() => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };

    getPayload(data);

    if (sortType) {
      data["sort_by"] = sortType;
    }

    const params = {
      page: currentPage,
      size: customersPerPage,
    };
    getCustomerData(data, params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    const data: any = {};

    if (regions.length > 0) {
      data["regions"] = regions;
    }
    if (salesOffice.length > 0) {
      data["sales_office_ids"] = salesOffice;
    }
    if (emp.length > 0) {
      data["subordinate_ids"] = emp;
    }
    if (teretorryId.length > 0) {
      data["territory_ids"] = teretorryId;
    }
    if (route.length > 0) {
      data["route_ids"] = route;
    }
    data["endpoint"] = "customer-master";
    data["start_date"] = formatCurrentStartDate(new Date(startDate));
    data["end_date"] = formatCurrentEndDate(new Date(endDate));

    getGlobalFilters(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkTimeFrame = (start: any, end: any) => {
    if (start === formatDate(new Date()) && end === formatDate(new Date())) {
      setSelectTimeFrame("Today");
    } else if (
      start === formatDate(new Date().setDate(new Date().getDate() - 1)) &&
      end === formatDate(new Date().setDate(new Date().getDate() - 1))
    ) {
      setSelectTimeFrame("Yesterday");
    } else if (
      start === formatDate(new Date().setDate(1)) &&
      end === formatDate(new Date())
    ) {
      setSelectTimeFrame("Month till date");
    } else {
      setSelectTimeFrame("");
    }
  };

  const handleApply = (sort: string) => {
    setCurrentPage(1);
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };
    getPayload(data);

    if (searchQuery) {
      data["search"] = searchQuery;
    }
    if (sort) {
      data["sort_by"] = sort;
    }

    const params = {
      page: 1,
      size: customersPerPage,
    };
    getCustomerData(data, params);
    handleClose();
  };

  const handleFiltersUpdate = () => {
    const data: any = {};

    if (regions.length > 0) {
      data["regions"] = regions;
    }
    if (salesOffice.length > 0) {
      data["sales_office_ids"] = salesOffice;
    }
    if (emp.length > 0) {
      data["subordinate_ids"] = emp;
    }
    if (teretorryId.length > 0) {
      data["territory_ids"] = teretorryId;
    }
    if (route.length > 0) {
      data["route_ids"] = route;
    }
    data["endpoint"] = "master-list";
    data["start_date"] = formatCurrentStartDate(new Date(startDate));
    data["end_date"] = formatCurrentEndDate(new Date(endDate));
    console.log("Filters Updated:", data);
    getGlobalFilters(data);
  };

  const handleExport = async () => {
    setLoading(true);
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      export: true,
      time_zone: TimeZoneDisplay(),
    };

    if (searchQuery) {
      data["search"] = searchQuery;
    }
    getPayload(data);
    const params = {};
    try {
      await exportActiviyData(data, params);
    } catch (error) {
      console.error("Error exporting data:", error);
    } finally {
      setLoading(false);
    }
  };

  const exportActiviyData = async (data: any, params: any) => {
    await getCustomerList(params, data)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `master_report.csv`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching Employee data:", error);
        toast.warn(error?.response?.data?.detail)
      });
  };

  useEffect(() => {
    setSalesOffice([]);
    setTeretorryId([]);
    setRouteID([]);
    setEmployee([]);
  }, [regions]);

  useEffect(() => {
    setTeretorryId([]);
    setRouteID([]);
    setEmployee([]);
  }, [salesOffice]);

  useEffect(() => {
    setRouteID([]);
    setEmployee([]);
    setChannel([]);
  }, [teretorryId]);

  return (
    <DashboardPage>
      <ToastContainer/>
      <div className="bg-gray-50 sm:pl-0 ">
        <div className="hidden mb-2 sm:flex flex-col sm:flex-row justify-between items-center bg-white p-2 border-b-2 pr-8">
          <div className="w-full sm:w-auto mb-4 sm:mb-0 pl-6">
            <PaginationComponent
              totalCount={customers?.total}
              itemsPerPage={customers?.size}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>

          <div className="flex flex-col sm:flex-row items-center w-full sm:w-auto space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="relative w-full sm:w-auto">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={Search} alt={t("masterList.searchPlaceholder")} />
              </div>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => handleChange(e.target.value)}
                placeholder={t("masterList.searchPlaceholder")}
                className="w-full sm:w-auto pl-10 pr-4 py-2 text-sm border rounded text-gray-600"
                style={{ borderColor: "#e0e0e0" }}
              />
            </div>
            <div className="hidden sm:flex space-x-4 items-center relative">
              {/* <button
                onClick={handleSort}
                className="flex items-center px-4 py-2 text-sm text-gray-600 rounded transition-all"
              >
                <img src={Sort} alt="Sort Icon" className="w-4 h-4 mr-2" />
                Sort by
              </button> */}
              {isDropdownOpen && (
                <ul className="absolute z-50 right-30 top-10 w-48 bg-white border border-gray-300 rounded shadow-lg">
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${
                      sortType === "ASC" && "bg-lightgrey"
                    }`}
                    onClick={() => handleSortFillter("ASC")}
                  >
                    {t("masterList.ascending")}
                  </li>
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer  ${
                      sortType === "DESC" && "bg-lightgrey"
                    }`}
                    onClick={() => handleSortFillter("DESC")}
                  >
                    {t("masterList.descending")}
                  </li>
                </ul>
              )}
              <button
                onClick={handleFilterClick}
                className="flex items-center gap-2 px-4 py-2 text-13  rounded transition-all"
              >
                <img
                  src={Filter}
                  alt={t("masterList.filter")}
                  className="w-4 h-4 "
                />
                {t("masterList.filter")}
              </button>

              <button
                onClick={handleExport}
                className={`flex items-center gap-2 px-4 py-2 text-13 font-medium border rounded transition-all ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
              >
                {loading ? (
                  <span className="flex items-center gap-2">
                    <span className="loader w-4 h-4 border-2 border-t-2 border-gray-300 rounded-full animate-spin"></span>
                    {t("masterList.exporting")}
                  </span>
                ) : (
                  <>
                    {t("masterList.export")}
                    <img
                      src={Export}
                      alt={t("masterList.export")}
                      className="w-4 h-4 "
                    />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="sm:hidden flex justify-between items-center p-2 border-b-2 bg-white">
          <div className="relative w-full sm:w-auto">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              {/* Add your search icon here */}
              <img src={Search} alt={t("masterList.searchPlaceholder")} />
            </div>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder={t("masterList.searchPlaceholder")}
              className="w-full sm:w-auto pl-10 pr-4 py-2 text-sm border rounded text-gray-600"
              style={{ borderColor: "#e0e0e0" }}
            />
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={handleExport}
              className={`flex items-center gap-2 px-4 py-2 text-13 font-medium border rounded transition-all ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading}
            >
              {loading ? (
                <span className="flex items-center gap-2">
                  <span className="loader w-4 h-4 border-2 border-t-2 border-gray-300 rounded-full animate-spin"></span>
                  {t("masterList.exporting")}
                </span>
              ) : (
                <>
                  {t("masterList.export")}
                  <img
                    src={Export}
                    alt={t("masterList.export")}
                    className="w-4 h-4 "
                  />
                </>
              )}
            </button>
            <button
              className="px-2 py-2 text-gray-600 rounded transition-all"
              onClick={() => setOpen(!open)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        {open && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end sm:items-start items-center z-50 ">
            <div className="relative bg-white shadow-lg w-full max-w-md h-full overflow-auto">
              <div className="absolute z-30 top-[50%] left-[45%]">
                {loadingFilter && <Loader />}
              </div>
              <div className="flex justify-between p-6 border-b-2">
                <div className="text-16 font-bold">
                  {t("masterList.filters.title")}
                </div>
                <div>
                  <img
                    className="cursor-pointer"
                    onClick={() => setOpen(false)}
                    src={Close}
                    alt={t("masterList.filters.title")}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between h-[calc(100vh-100px)] px-12 pt-6 scrollbar scrollbar-thin overflow-y-auto">
                <div className="flex flex-col gap-[30px]">
                  <div className="flex gap-4">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.region")}
                        options={globalFilters?.regions.map((sales: any) => ({
                          value: sales,
                          label: sales,
                        }))}
                        selectedValues={regions}
                        onClose={handleFiltersUpdate}
                        onChange={setRegions}
                        placeholder={t("visitreports.filters.selectRegion")}
                      />
                    </div>
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.salesOffice")}
                        options={
                          globalFilters?.sales_offices.map((sales: any) => ({
                            value: sales.id,
                            label: sales.name,
                          })) || []
                        }
                        selectedValues={salesOffice}
                        onClose={handleFiltersUpdate}
                        onChange={setSalesOffice}
                        disabled={regions.length === 0 || loadingFilter}
                        placeholder={t(
                          "visitreports.filters.selectSalesOffice"
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.territoryID")}
                        options={
                          globalFilters?.territories?.map((emp: any) => ({
                            value: String(emp.territory_id),
                            label: emp.territory_identifier,
                          })) || []
                        }
                        selectedValues={teretorryId}
                        onChange={setTeretorryId}
                        onClose={handleFiltersUpdate}
                        disabled={regions.length === 0 || loadingFilter}
                        placeholder={t(
                          "visitreports.filters.selectTerritoryID"
                        )}
                      />
                    </div>
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.channelID")}
                        options={
                          globalFilters?.channels?.map((emp: any) => ({
                            value: emp.id,
                            label: emp.name,
                          })) || []
                        }
                        selectedValues={channel}
                        onClose={handleFiltersUpdate}
                        onChange={setChannel}
                        disabled={regions.length === 0 || loadingFilter}
                        placeholder={t("visitreports.filters.selectChannelID")}
                      />
                    </div>
                  </div>
                  <div className="w-full flex gap-4">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.routeID")}
                        options={
                          globalFilters?.routes?.map((emp: any) => ({
                            value: `${emp.route_id}`,
                            label: emp.route_identifier,
                          })) || []
                        }
                        selectedValues={route}
                        onClose={handleFiltersUpdate}
                        onChange={setRouteID}
                        disabled={regions.length === 0 || loadingFilter}
                        placeholder={t("visitreports.filters.selectRouteID")}
                      />
                    </div>
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("ActivityReport.employeeName")}
                        options={
                          globalFilters?.employees.map((emp: any) => ({
                            value: emp.id,
                            label: emp.name_eng,
                          })) || []
                        }
                        selectedValues={emp}
                        onClose={handleFiltersUpdate}
                        onChange={setEmployee}
                        disabled={regions.length === 0 || loadingFilter}
                        placeholder={t("ActivityReport.employeeName")}
                        searchable={true}
                      />
                    </div>
                  </div>

                  {/* channel  and Status */}
                  <div className="flex gap-4">
                    <div className="w-full">
                      <DropdownMulti
                        label={t("masterList.filters.customerID")}
                        options={
                          globalFilters?.customers?.map((emp: any) => ({
                            value: emp.id,
                            label: emp.name_eng,
                          })) || []
                        }
                        selectedValues={customerID}
                        onChange={setCustomerID}
                        disabled={regions.length === 0 || loadingFilter}
                        placeholder={t("masterList.filters.selectCustomerID")}
                      />
                    </div>
                    {/* <div className="w-1/2">
                      <Dropdown
                        label={t("masterList.filters.status")}
                        options={
                          globalFilters?.customer_status?.map((emp: any) => ({
                            value: emp,
                            label: emp === "A" ? "Active" : "Inactive",
                          })) || []
                        }
                        selectedValue={status}
                        disabled={regions.length === 0 || loadingFilter}
                        onChange={setStatus}
                        placeholder={t("masterList.filters.selectStatus")}
                      />
                    </div> */}
                  </div>

                  <div className="date-range-picker flex gap-4">
                    <div className="flex gap-4 w-full">
                      <div className="w-full">
                        <label className="block text-10 text-gray-500 pb-1">
                          {t("masterList.filters.startDate")}
                        </label>
                        <input
                          type="date"
                          className="rounded p-2 border-2 border-gray-400 w-full"
                          value={startDate}
                          max={new Date(endDate).toISOString().split("T")[0]}
                          onChange={handleStartDateChange}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                      <div className="w-full">
                        <label className="block text-10 text-gray-500 pb-1">
                          {t("masterList.filters.endDate")}
                        </label>
                        <input
                          type="date"
                          className="rounded p-2 border-2 border-gray-400 w-full"
                          value={endDate}
                          max={new Date().toISOString().split("T")[0]}
                          min={new Date(startDate).toISOString().split("T")[0]}
                          onChange={handleEndDateChange}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <div className="w-full">
                      <DropdownMulti
                        label={t("masterList.filters.paymentTermID")}
                        options={
                          globalFilters?.payment_terms?.map((emp: any) => ({
                            value: emp.id,
                            label: emp.name,
                          })) || []
                        }
                        selectedValues={paymentTermId}
                        onChange={setPaymentTermId}
                        placeholder={t("masterList.filters.selectID")}
                      />
                    </div>
                  </div>

                  <div className="w-full">
                    <TimeFrameSelector
                      customClass={"w-full border border-gray-300 rounded p-2"}
                      setSelectTimeFrame={setSelectTimeFrame}
                      timeFrame={timeFrame}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                </div>

                <div className="flex justify-between mt-4 pb-10">
                  <Button
                    onClick={handleClearFilters}
                    label={t("masterList.filters.clearFilters")}
                    type="grey"
                  />
                  <Button
                    onClick={() => handleApply(sortType)}
                    label={t("masterList.filters.applyFilters")}
                    type="purple"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          // id="printable-area"
          className="h-[calc(100vh-200px)] md:h-[calc(100vh-120px)] p-2 pt-0 overflow-auto  page-container scrollbar scrollbar-thin "
        >
          <table className="min-w-full bg-white border border-black-200 table-auto border-separate border-spacing-0">
            <thead className="bg-gray-200 sticky top-[0px] z-20">
              <tr>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-nowrap">
                  {t("activityreports.tableHeaders.srno")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.territoryID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.routeID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.outletID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.employeeName")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.customerName")}
                </th>
                {/* <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.customerArabicName")}
                </th> */}
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.payerID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.cusStatus")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.paytermBlocked")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.blockedDate")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.blockTime")}
                </th>

                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.commericalRegisterNumber")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.entryDate")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.validFrom")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.validTo")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.promissory")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.promNoteEntryDate")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.promNoteValidFrom")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.promNoteValidTo")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.building")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.city")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.district")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.postalCode")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.street")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.createdOn")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.salesOrganization")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.distributionChannel")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.distChannelText")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.division")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.divisionText")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.paymentTerms")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.paymentTermsText")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.customerTaxCode")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.salesOffice")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.creditLimit")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.receivables")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.avaiableLimit")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.CreditLimitDeciation")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.presellerID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.presellerName")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.supervisorID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.supervisorName")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.teamleadID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.teamleadName")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.longitude")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.latitude")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.vatNumber")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.lastLocationCaptureAuditedDate")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.lastAuditedTime")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.auditedByUser")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.customerVirtualAccount")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.iban")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.comment1")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.comment2")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.brandName")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.lastInvoiceDate")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.lastOrderDate")}
                </th>
              </tr>
            </thead>
            <tbody>
              {customers?.items?.length > 0 &&
                !loading &&
                customers.items.map((customer: any, index: number) => (
                  <tr key={customer.emp_id} className="hover:bg-gray-50">
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {startIndex + index + 1}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.territory_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.route_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.outlet_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.emp_name || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {i18n.language === "ar"
                        ? customer.customer_name_arb
                        : customer.customer_name_eng}
                    </td>
                    {/* <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.customer_name_arb || "-"}
                    </td> */}
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.payer_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.customer_status || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.payterm_blocked || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.blocked_date || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.blocked_time || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.commercial_register_number || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.entry_date || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.valid_from || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.valid_to || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.promissory || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.prom_note_entry_date || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.prom_note_valid_from || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.prom_note_valid_to || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.building || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.city || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.district || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.postal_code || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.street || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.created_on || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.sales_organization || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.distribution_channel || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.dist_channel_text || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.division || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.division_text || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.payment_terms || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.payment_terms_text || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.customer_tax_code || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.sales_office || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {formatNumber(customer.credit_limit || 0)}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.receivables || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.available_limit || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.credit_limit_deviation || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.preseller_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.preseller_name || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.supervisor_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.supervisor_name || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.teamlead_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.teamlead_name || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.longitude || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.latitude || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.vat_number || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.last_location_capture_audited_date || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.last_audited_time || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.audited_by_user || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.customer_virtual_account || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.iban || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.comment_1 || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.comment_2 || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.brand_name || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.last_invoice_date || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.last_order_date || "-"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {customers?.items?.length === 0 && !loading && (
            <>
              <div className="px-4 py-3 border text-center text-black-600 w-full flex items-center justify-center">
                {t("masterList.noresultsfound")}
              </div>
            </>
          )}
          {loading && <Loader />}
        </div>
        <div className="sm:hidden bg-white p-2 border-t-2 mt-auto">
          <PaginationComponent
            totalCount={customers?.total}
            itemsPerPage={customers?.size}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </DashboardPage>
  );
};

export default CustomerTable;
