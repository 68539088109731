import React from "react";
import Dropdown from "../components/Dropdown";
import print from "../assets/printer.svg";
import exportpng from "../assets/Export.svg";
import { useTranslation } from "react-i18next";

interface ViewInventoryProps {
  toggleTableToShow: () => void;
  toggleTableToHide: () => void;
  showTable: boolean;
  warehouseDropdown: {
    isOpen: boolean;
    selectedOption: string | null;
  };
  setWarehouseDropdown: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      selectedOption: string | null;
    }>
  >;
  productDropdown: {
    isOpen: boolean;
    selectedOption: string | null;
  };
  setProductDropdown: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      selectedOption: string | null;
    }>
  >;
  wareHouseOptions: Array<{ warehouseId: string; warehouseName: string }>;
  productOptions: Array<{ productId: string; productName: string }>;
  exportData: () => void;
  printData: () => void;
}

const ViewInventory: React.FC<ViewInventoryProps> = ({
  toggleTableToShow,
  toggleTableToHide,
  showTable,
  warehouseDropdown,
  setWarehouseDropdown,
  productDropdown,
  setProductDropdown,
  wareHouseOptions,
  productOptions,
  exportData,
  printData,
}) => {
  const { t } = useTranslation();
  return (
    <div className="sticky top-0 left-0 z-30 w-full">
      <p className="text-sm font-bold mx-4 sm:mx-5 lg:mx-0 bg-[#fff]">
        {t("inventory.viewInventory")}
      </p>
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between bg-gray-200 py-3 px-4 mt-3 border rounded-md w-full shadow-sm gap-4">
        <div className="flex flex-wrap lg:flex-nowrap items-center gap-4 w-full lg:w-[60%]">
          <Dropdown
            title={t("inventory.selectWarehouse")}
            options={wareHouseOptions}
            label={t("inventory.warehouse")}
            isOpen={warehouseDropdown.isOpen}
            selectedOption={warehouseDropdown.selectedOption}
            onToggle={() =>
              setWarehouseDropdown((prev) => ({
                ...prev,
                isOpen: !prev.isOpen,
              }))
            }
            onOptionChange={(id) =>
              setWarehouseDropdown({ isOpen: false, selectedOption: id })
            }
            onClose={() =>
              setWarehouseDropdown((prev) => ({ ...prev, isOpen: false }))
            }
            position="top"
          />
          <Dropdown
            title={t("inventory.selectProduct")}
            options={productOptions.map((item) => ({
              warehouseId: item.productId,
              warehouseName: item.productName,
            }))}
            label={t("inventory.product")}
            isOpen={productDropdown.isOpen}
            selectedOption={productDropdown.selectedOption}
            onToggle={() =>
              setProductDropdown((prev) => ({
                ...prev,
                isOpen: !prev.isOpen,
              }))
            }
            onOptionChange={(id) =>
              setProductDropdown({ isOpen: false, selectedOption: id })
            }
            onClose={() =>
              setProductDropdown((prev) => ({ ...prev, isOpen: false }))
            }
            position="bottom"
          />
          <div className="w-full sm:w-auto">
            {showTable ? (
              <button
                onClick={toggleTableToHide}
                className="w-full sm:w-24 bg-[#005879] text-gray-100 border rounded-md py-2.5 text-xs hover:bg-[#00465f] focus:outline-none focus:ring focus:ring-blue-300 lg:mt-6 sm:mt-0"
              >
                {t("inventory.clearData")}
              </button>
            ) : (
              <button
                onClick={toggleTableToShow}
                className="w-full sm:w-28 bg-[#005879] text-gray-100 border rounded-md py-2.5 text-xs hover:bg-[#00465f] focus:outline-none focus:ring focus:ring-blue-300 lg:mt-6 sm-mt-0"
              >
                {t("inventory.viewInventoryButton")}
              </button>
            )}
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-center lg:justify-end gap-4 w-full lg:w-auto lg:mt-5 sm:mt-0">
          <button
            onClick={exportData}
            className="w-full sm:w-24 border border-[#1dbda1] text-[#1dbda1] bg-transparent rounded-md py-2 text-xs hover:bg-[#e6f7f4] focus:outline-none focus:ring focus:ring-teal-300"
          >
            <div className="flex items-center justify-center w-full">
              <img className="me-2" src={exportpng} alt="" />
              {t("inventory.export")}
            </div>
          </button>
          <button
            onClick={printData}
            className="w-full sm:w-24 bg-[#1dbda1] text-gray-100 rounded-md py-2 text-xs hover:bg-[#17a488] focus:outline-none focus:ring focus:ring-teal-300"
          >
            <div className="flex items-center justify-center w-full">
              <img className="me-2" src={print} alt="" />
              {t("inventory.print")}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewInventory;
