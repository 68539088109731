import React, { useState, useEffect } from "react";
import Header from "../../layout/header";
import MyTeam from "../../assets/landingPage-icon/MyTeam.svg";
import MyTeamHover from "../../assets/landingPage-icon/MyTeams-hover.svg";
import MyTeamClick from "../../assets/landingPage-icon/MyTeam-click.svg";
import Customers from "../../assets/landingPage-icon/Customers.svg";
import CustomersHover from "../../assets/landingPage-icon/Customers-hover.svg";
import CustomersClick from "../../assets/landingPage-icon/Customers-click.svg";
import Planogram from "../../assets/landingPage-icon/Planogram.svg";
import PlanogramHover from "../../assets/landingPage-icon/Planogram-hover.svg";
import PlanogramClick from "../../assets/landingPage-icon/Planogram-click.svg";
import Territory from "../../assets/landingPage-icon/Territory.svg";
import TerritoryHover from "../../assets/landingPage-icon/Territory-hover.svg";
import TerritoryClick from "../../assets/landingPage-icon/Territory-click.svg";
import Print from "../../assets/landingPage-icon/Print.svg";
import PrintHover from "../../assets/landingPage-icon/Print-hover.svg";
import Finance from "../../assets/landingPage-icon/Finanace.svg";
import FinanceHovar from "../../assets/landingPage-icon/Finance-hover.svg";
import AdminClick from "../../assets/landingPage-icon/Admin-click.svg";
import URL_MAPPING from "../../routes/constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import getSliderImages from "../../api/landing";
import Carousel from "../../components/Carousel";
import { getUserInfo } from "../..//utility";

const Index: React.FC = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState<number | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clickedButton, setClickedButton] = useState<number | null>(null);
  const [images, setSliderImages] = useState<any>([]);
  const { t } = useTranslation();

  let userRole:any = getUserInfo();
  let buttonData:any[] = [];

  if (userRole?.role === "Supervisor"){
    buttonData = [
      {
        id: 1,
        label: t("Landing.myTeam"),
        icons: { default: MyTeam, hover: MyTeamHover, click: MyTeamClick },
        url: URL_MAPPING.ANALYTICS,
        disabled: false,
        name: "My Team",
        section: "My Team",
      },
      {
        id: 2,
        label: t("Landing.customers"),
        icons: {
          default: Customers,
          hover: CustomersHover,
          click: CustomersClick,
        },
        url: URL_MAPPING.MASTERLIST,
        disabled: false,
        name: "Customers",
        section: "Customers",
      },
      {
        id: 3,
        label: t("Landing.planogram"),
        icons: {
          default: Planogram,
          hover: PlanogramHover,
          click: PlanogramClick,
        },
        url: URL_MAPPING.PLANOGRAM,
        disabled: false,
        name: "Planogram",
        section: "Planogram",
      },
      // {
      //   id: 4,
      //   label: t("Landing.admin"),
      //   icons: { default: Admin, hover: AdminHover, click: AdminClick },
      //   url: "test",
      //   disabled: false,
      //   name: "Admin",
      // },
      {
        id: 5,
        label: t("Landing.territory"),
        icons: {
          default: Territory,
          hover: TerritoryHover,
          click: TerritoryClick,
        },
        url: URL_MAPPING.TERRITORIES,
        disabled: true,
        name: "Territory",
        section: "Territory",
      },

      {
        id: 6,
        label: t("Landing.print"),
        icons: { default: Print, hover: PrintHover, click: AdminClick },
        url: URL_MAPPING.DEPOSITREPORT,
        disabled: false,
        name: "Print",
        section: "Print",
      },
      {
        id: 7,
        label: t("Landing.finance"),
        icons: { default: Finance, hover: FinanceHovar, click: AdminClick },
        url: URL_MAPPING.CUSTOMERLIST,
        disabled: true,
        name: "Finance",
        section: "Finance",
      },
    ];
  }else if(userRole?.role==='Delivery Driver'){

    buttonData = [
      {
        id: 1,
        label: t("Landing.myTeam"),
        icons: { default: MyTeam, hover: MyTeamHover, click: MyTeamClick },
        url: URL_MAPPING.DELIVERYDASHBOARD,
        disabled: false,
        name: "My Team",
        section: "Delivery_My_Team",
      },
      {
        id: 2,
        label: t("Landing.planogram"),
        icons: {
          default: Planogram,
          hover: PlanogramHover,
          click: PlanogramClick,
        },
        url: URL_MAPPING.PLANOGRAM,
        disabled: false,
        name: "Planogram",
        section: "Delivery_Planogram",
      },
      {
        id: 3,
        label: t("Landing.print"),
        icons: { default: Print, hover: PrintHover, click: AdminClick },
        url: URL_MAPPING.DEPOSITREPORT,
        disabled: false,
        name: "Print",
        section: "Delivery_Print",
      },
    ];
  }
    

  const handleButtonClick = (button: any) => {
    localStorage.setItem("section", button.section);
    navigate(button.url);
  };

  const getSliderImagesData = async () => {
    await getSliderImages()
      .then((res) => {
        setSliderImages(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    localStorage.setItem("section", "default");
  }, []);

  useEffect(() => {
    getSliderImagesData();
  }, []);

  return (
    <div>
      <div className="w-full  fixed z-50 ">
        <Header hide={true} />
      </div>
      <div className="flex flex-col items-center py-4 pt-[54px]">
        <Carousel images={images} />
        <hr className="w-[calc(100vw-50px)] border-t-2 border-gray-300  mb-6" />
        <div className="grid grid-cols-2 md:flex md:justify-center md:gap-16  gap-4 w-full max-w-7xl pb-[100px]">
          {buttonData?.map((button, index) => {
            let iconSrc = button.icons.default;
            if (clickedButton === index) iconSrc = button.icons.click;
            else if (activeButton === index) iconSrc = button.icons.hover;

            return (
              <button
                key={button.id}
                className={`group flex flex-col items-center cursor-pointer mt-12 justify-center  ${
                  button.disabled
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:text-purple transition-colors"
                }`}
                onMouseEnter={() => setActiveButton(index)}
                onMouseLeave={() => setActiveButton(null)}
                onClick={() => handleButtonClick(button)}
                disabled={button.disabled}
              >
                <img
                  src={iconSrc}
                  alt={button.label}
                  className={`${
                    button.disabled
                      ? ""
                      : "transition-transform duration-100 ease-in-out hover:scale-110"
                  } w-32 h-32 mb-2 transform `}
                />
                <div className="transition-colors group:hover:text-purple">
                  <b>{button.label}</b>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Index;
