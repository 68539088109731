import React, { useEffect, useState } from "react";
import getShipmentDropdown from "../api/createOrder";
import { useTranslation } from "react-i18next";
interface NewOrderProps {
  toggleTableToShow: () => void;
  handleClear: () => void;
  showTable: boolean;
  showSummesion: boolean;
  toggleSummesion: () => void;
  loading: boolean;
  handleSelectoption: any;
  wareHouseId: any;
}

interface WarehouseOption {
  warehouseId: string;
  warehouseName: string;
}

const NewOrder: React.FC<NewOrderProps> = ({
  toggleTableToShow,
  handleClear,
  showTable,
  showSummesion,
  toggleSummesion,
  handleSelectoption,
  wareHouseId,
}) => {
  const [dropdownOptions, setDropdownOptions] = useState<WarehouseOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const fetchShipmentPoints = async () => {
    setLoading(true);
    try {
      const response = await getShipmentDropdown({});
      const parsedResponse: WarehouseOption[] = response.warehouses;
      setDropdownOptions(parsedResponse);
    } catch (err: any) {
      setError("Failed to load shipment points");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchShipmentPoints();
  }, []);

  return (
    <div className="sticky [top:0%] [left:10%] z-5">
      <p className="text-xs font-bold"> {t("createOrder.createOrder")}
      </p>
      <div className="flex items-center justify-between h-20 bg-gray-200 py-2 px-4 mt-3 border rounded-md">
        <div className="flex items-end justify-center space-x-2">
          <div className="w-48">
            <label
              htmlFor="shipment-point"
              className="block text-sm font-medium text-gray-700"
            >
         {t("createOrder.ShipmentPoint")}
            </label>
            <select
              id="shipment-point"
              name="shipment-point"
              value={wareHouseId}
              onChange={(e) => handleSelectoption(e)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option>{t("createOrder.selectship")}</option>
              {loading ? (
                <option>Loading...</option>
              ) : error ? (
                <option>{error}</option>
              ) : dropdownOptions && dropdownOptions.length > 0 ? (
                dropdownOptions.map((option) => (
                  <option key={option.warehouseId} value={option.warehouseId}>
                    {option.warehouseName}
                  </option>
                ))
              ) : (
                <option>No options available</option>
              )}
            </select>
          </div>

          {showTable ? (
            <button
              onClick={handleClear}
              className="bg-[#005879] text-gray-100 border rounded-md p-2 text-sm w-24 mt-5"
            >
          {t("createOrder.clearData")}
            </button>
          ) : (
            <button
              onClick={toggleTableToShow}
              className="bg-[#005879] text-gray-100 border rounded-md p-2 text-sm w-32 mt-5"
            >
          {t("createOrder.Continue")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewOrder;
