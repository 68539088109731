import React, { useState, useRef, useEffect } from "react";
import Button from "../../components/LoaderButton";
import logo from "../../assets/logo.svg";
import Back from "../../assets/back-arrow.svg";
import { useTranslation } from "react-i18next";
interface OtpVerificationProps {
  loader: boolean;
  handleSubmit: any;
  handleBack?: any;
  error?: any;
  handleGetOtpThroughMail?: any;
  methodType?: string;
  setTimeLeft?:any;
  timeLeft?:any;
  username:any
}

const OtpVerification: React.FC<OtpVerificationProps> = ({
  loader,
  handleSubmit,
  handleBack,
  error,
  handleGetOtpThroughMail,
  methodType,
  setTimeLeft,
  timeLeft,
  username
}) => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const otpLength = 6;

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").slice(0, otpLength); 
    const newOtp = pastedData.split(""); 
    setOtp(newOtp);

    newOtp.forEach((digit, index) => {
      const inputElement = inputRefs.current[index]; 
      if (inputElement) {
        inputElement.value = digit; 
      }
    });
  
    const nextInputIndex = newOtp.length < otpLength ? newOtp.length : otpLength - 1;
    inputRefs.current[nextInputIndex]?.focus();
  };

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer); // Cleanup the timer on unmount or re-render
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  const handleChange = (e: any, index: number) => {
    const { value } = e.target;
    if (value === "") {
      const newOtp = [...otp];
      newOtp[index] = ""; // Clear current input

      // Focus the previous input if available
      if (index > 0 && inputRefs.current[index - 1]) {
        inputRefs.current[index - 1]?.focus();
      }

      setOtp(newOtp);
    } else if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otp?.length - 1 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
    if (e.key === "Enter") {
      handleSubmit(otp);
    }
  };

  useEffect(() => {
    if (inputRefs?.current[0]) {
      inputRefs?.current[0].focus();
    }
  }, []);


  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <>
      {" "}
      <div className=" relative w-full md:w-1/2 flex flex-col justify-center items-center bg-white md:px-20  sm:p-10 p-4 ">
        <div className="mb-8 ">
          <img
            src={Back}
            alt="Logo"
            className="absolute left-8 cursor-pointer mt-[-90px] md:mt-0"
            onClick={handleBack}
          />
          <img src={logo} alt="Logo" className="h-18" />
        </div>

        <div className="mb-6 px-4 py-4 text-center">
          <h2 className="text-2xl font-bold text-purple mb-1">
            {methodType === 'MFA' ? t("SignUp.enterYourVerficationCode") : t("SignUp.enterYourVerficationCodeFromMail")}
          </h2>
          <p className="text-grey max-w-[335px] text-base">
            {t("SignUp.toIncreaseSecurity")}
          </p>
        </div>
        <div className="flex w-full justify-between gap-2">
          {otp.map((digit, index) => (
            <input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              type="number"
              maxLength={1}
              value={digit}
              onPaste={handlePaste}
              className="w-full max-w-[64px] h-[64px] bg-lightgrey p-3 text-center text-28 rounded"
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onFocus={(e) => e.target.select()}
            />
          ))}
        </div>
        {(username && methodType) === 'MFA' &&<div onClick={handleGetOtpThroughMail} className="hover:text-purple-400  underline  mb-4 w-full text-purple text-13 font-bold text-center mt-6 cursor-pointer ">
         <span className="p-1"> {t('SignUp.getTheOtp')}</span>
        </div>}
        <div className="mb-4 w-full text-red-600 text-sm text-center mt-6">
          {error}
        </div>
        <div className="mt-6 w-full">
          <Button
            text={t("SignUp.verify")}
            onClick={() => handleSubmit(otp)}
            isLoading={loader}
            disabled={loader}
          />
        </div>
        {(username && methodType) === 'EMAIL_OTP' && <>
          <div className="mt-3 text-grey text-13">
          {timeLeft === 0 ? (
            <p className=" text-grey text-13 cursor-pointer" onClick={handleGetOtpThroughMail}>
              {t("SignUp.requestCodeAgain")}
            </p>
          ) : (
            <>
              <p className=" text-grey text-13 ">
                {t("SignUp.requestCodeAgain")}{' '}
                <b className="text-black">{formatTime(timeLeft)}</b>
              </p>
            </>
          )}
        </div>
        </>}
      </div>
    </>
  );
};

export default OtpVerification;
