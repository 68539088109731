import React, { useEffect, useState } from "react";
import icon from "../assets/summesion.png";
import Loader from "../../../components/Loader";
import { addAddress } from "../api/editAddress";
import { Map, Marker, APIProvider } from "@vis.gl/react-google-maps";
import getAddressTable from "../api/manageAddressTable";
import { toast, ToastContainer } from "react-toastify";
import SearchableDropdown from "./SearchableDropdown";
import { useTranslation } from "react-i18next";
interface ViewInventoryProps {
  toggleSummesion: () => void;
  showSummesion: boolean;
  loading: boolean;
  setLoading: (value: boolean) => void;
  warehouseData: any;
  createInvovice: any;
  salesQuantities: any;
  setSalesQuantities: any;
  selectedAddress: any;
  setSelectedAddress: any;
  fetchAddresses: any;
  addressOptions: any;
}

interface NewOrderProps {
  toggleTableToShow: () => void;
  toggleTableToHide: () => void;
  showTable: boolean;
  showSummesion: boolean;
  toggleSummesion: () => void;
  loading: boolean;
}

const saudiCenter = { lat: 24.774265, lng: 46.738586 };
const saudiBounds = {
  north: 33.0,
  south: 16.0,
  east: 60.0,
  west: 34.0,
};





const FetchedData: React.FC<ViewInventoryProps> = ({
  showSummesion,
  toggleSummesion,
  loading,
  setLoading,
  warehouseData,
  createInvovice,
  salesQuantities,
  setSalesQuantities,
  selectedAddress,
  setSelectedAddress,
  fetchAddresses,
  addressOptions,
}) => {

  const [selectedTab, setSelectedTab] = useState("Available Products");
  const [savedDetails, setsavedDetails] = useState<any>();
  const [addTitle, setAddressTitle] = useState<any>();
  const [buildNo, setBuildNo] = useState<any>();
  const [city, setCity] = useState<any>();
  const [distict, setDistrict] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [lat, setLat] = useState<any>();
  const [lng, setLng] = useState<any>();
  const [mobile, setMobile] = useState<any>();
  const [name, setName] = useState<any>();
  const [postalCode, setPostalCode] = useState<any>();
  const [street, setStreet] = useState<any>();
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [error, setError] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [sumData, setSumData] = useState({
    total_available_stocks: 0,
    total_sales_quantity: 0,
  });
  const [inputValues, setInputValues] = useState<{ [key: number]: string }>({}); // Stores input field values
  const { t } = useTranslation();
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAddressSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedAddress(e.target.value);
  };

  const getAllSubmissions = () => {
    setSumData({
      total_available_stocks: 0,
      total_sales_quantity: 0,
    });

    salesQuantities?.map((item: any) => {
      setSumData((prev) => ({
        ...prev,
        total_sales_quantity: prev.total_sales_quantity + item.quantity,
      }));
    });
    warehouseData.items?.map((item: any) => {
      setSumData((prev) => ({
        ...prev,
        total_available_stocks:
          prev.total_available_stocks + item.available_stocks,
      }));
    });
  };

  useEffect(() => {
    if (showSummesion) {
      getAllSubmissions();
    }
  }, [showSummesion]);

  const handleTabChange = (tab: any) => {
    if (tab === 1) {
      setSelectedTab("Available Products");
      setButtonText("Select Address");
      setsavedDetails(null);
    } else {
      if (salesQuantities.length === 0) {
        return toast.warn("Enter Quantity");
      } else {
        setButtonText("Place Order");
        setSelectedTab("Select Address");
        const add = addressOptions?.filter(
          (item: any) => item.id == selectedAddress
        );
        if (add) {
          setsavedDetails(add[0]);
        }
      }
    }
  };

  const [buttonText, setButtonText] = useState("Select Address");

  const handleButtonClick = () => {
    console.log(salesQuantities);
    if ("Place Order" === buttonText) {
      if (!selectedAddress) {
        return toast.warn("Select Address");
      } else {
        createInvovice();
      }
    } else if ("Select Address" === buttonText) {
      if (salesQuantities.length === 0) {
        return toast.warn("Enter Quantity");
      } else {
        setButtonText("Place Order");
        setSelectedTab("Select Address");
      }
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [dynamicID, setDynamicId] = useState();
  const handleOpenModal = (postal_code: any) => {
    handleClear();
    setDynamicId(postal_code);
    setShowModal(true);
  };

  const handleClear = () => {
    setAddressTitle("");
    setBuildNo("");
    setStreet("");
    setCity("");
    setDistrict("");
    setPostalCode("");
    setName("");
    setMobile("");
    setEmail("");
    setLat("");
    setLng("");
    setMarkerPosition(null);
  };

  useEffect(() => {
    console.log(selectedAddress, "test");
  }, [selectedAddress]);

  const handleSaveAddress = async () => {
    setLoading(true);

    if (!lat || !lng) {
      setLat(saudiCenter.lat);
      setLng(saudiCenter.lng);
    }

    setPopupOpen(true);
  };

  const handleContinue = async () => {
    try {
      const data = {
        address_title: addTitle,
        building_no: buildNo,
        city: city,
        district: distict,
        email: email,
        latitude: String(lat),
        longitude: String(lng),
        mobile: mobile,
        name: name,
        postal_code: postalCode,
        street: street,
      };
      const response = await addAddress(data);
      toast.success("Address Added Successfully");
      fetchAddresses();
      setSelectedAddress(String(response.id));
      setTimeout(() => {
        setShowModal(false);
        setPopupOpen(false);
      }, 2000);
    } catch (err: any) {
      console.error("Error saving address:", err.message);
      setError("Failed to save address. Please try again.");
      toast.error("Error!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const add = addressOptions?.filter(
      (item: any) => item.id == selectedAddress
    );
    if (add) {
      setsavedDetails(add[0]);
    }
  }, [addressOptions, selectedAddress]);

  const handleMapClick = (e: any) => {
    const geocoder = new google.maps.Geocoder();
    const lat = e.detail.latLng.lat;
    const lng = e.detail.latLng.lng;
    setLng(lng);
    setLat(lat);
    setMarkerPosition({ lat, lng });
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results && results.length > 0) {
        const result = results[0];
        const addressComponents = result.address_components;

        // Extract desired information
        const getComponent = (type: string) =>
          addressComponents.find((component) => component.types.includes(type))
            ?.long_name;

        const address = result.formatted_address;
        const city = getComponent("locality");
        const postalCode = getComponent("postal_code");
        const district = getComponent("administrative_area_level_2");
        const street = getComponent("route");

        setCity(city);
        setPostalCode(postalCode);
        setDistrict(district);
        setStreet(street);
      } else {
        console.error("Geocode failed due to: " + status);
      }
    });
  };

  const onClose = () => {
    setPopupOpen(false);
  };

  const handleInputChange = (
    id: number,
    value: string,
    availableStocks: any
  ) => {
    const parsedValue = Number(value);
    const avStock = Number(availableStocks);
    console.log(avStock, parsedValue);

    if (parsedValue > avStock) {
      return toast.warn(
        `Sales quantity cannot be greater than available stocks (${avStock})`
      );
    }
    if (parsedValue < 0) {
      return toast.warn("Sales quantity should be greater than or equal to 0");
    }
    setInputValues((prev) => ({ ...prev, [id]: value }));
    setSalesQuantities((prev: any) => {
      let updatedQuantities = [...prev];

      if (parsedValue === 0) {
        // If the quantity is 0, remove the object with the matching productId
        updatedQuantities = updatedQuantities.filter(
          (item) => item.productId !== id
        );
      } else {
        // If the quantity is not 0, update or add the object
        const index = updatedQuantities.findIndex(
          (item) => item.productId === id
        );

        if (index === -1) {
          // If the productId doesn't exist, add a new entry
          updatedQuantities.push({ productId: id, quantity: parsedValue });
        } else {
          // If it exists, update the quantity
          updatedQuantities[index].quantity = parsedValue;
        }
      }

      return updatedQuantities;
    });
  };

  return (
    <div
      className="overflow-auto max-h-[calc(100vh-190px)] scrollbar-none relative"
      style={{
        WebkitOverflowScrolling: "touch",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
    >
      <ToastContainer />
      <div className="flex flex-col items-start space-y-2 py-2 px-4 rounded-md">
        <p className="text-sm font-semibold text-gray-700">Fetched Data</p>
        <div className="flex items-center justify-start space-x-2">
          <button
            onClick={() => handleTabChange(1)}
            disabled={selectedTab === "Available Products"}
            className={`text-sm font-medium px-4 py-2 rounded-md shadow ${
              selectedTab === "Available Products"
                ? "bg-gray-400 text-gray-200 cursor-not-allowed"
                : "bg-[#005879] text-white"
            }`}
          >
          {t("createOrder.Available_Products")}
          
            
          </button>
          <span className="text-gray-500">{">"}</span>
          <button
            onClick={() => handleTabChange(2)}
            disabled={selectedTab === "Select Address"}
            className={`text-sm font-medium px-4 py-2 rounded-md shadow ${
              selectedTab === "Select Address"
                ? "bg-gray-400 text-gray-200 cursor-not-allowed"
                : "bg-[#005879] text-white"
            }`}
          >
             {t("createOrder.Select_Address")}
          </button>
        </div>
      </div>

      {selectedTab === "Available Products" ? (
        <>
          <table
            className="min-w-full bg-white border-none border-black-200"
            style={{ borderSpacing: "0px 10px", borderCollapse: "separate" }}
          >
            <thead className="bg-[#E8F6FC] sticky top-1 z-5">
              <tr>
                <th className="px-4 py-3 border text-left text-xs font-medium text-black-500">
                {t("createOrder.Product_ID")}
                </th>
                <th className="px-4 py-3 border text-left text-xs font-medium text-black-500">
                {t("createOrder.Description")}
                </th>
                <th className="px-4 py-3 border text-left text-xs font-medium text-black-500">
                {t("createOrder.Unit")}
                </th>
                <th className="px-4 py-3 border text-left text-xs font-medium text-black-500">
                {t("createOrder.Available_Stocks")}
                </th>
                <th className="px-4 py-3 border text-left text-xs font-medium text-black-500">
                  <div className="flex items-center space-x-2">
                    <p className="text-xs">                {t("createOrder.Sales_Quantity")}</p>
                    <img
                      // onClick={toggleSummesion}

                      className="cursor-pointer"
                      src={icon}
                      alt="summesion"
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {warehouseData ? (
                warehouseData?.map((warehouse: any, index: number) => (
                  <tr key={index} className="text-xs hover:bg-gray-50">
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.MaterialId || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.MaterialDesc || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.UnitText || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {parseInt(warehouse.AvailableStock) || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      <input
                        type="number"
                        value={inputValues[warehouse.MaterialId] || ""}
                        onChange={(e) =>
                          handleInputChange(
                            warehouse.MaterialId,
                            e.target.value,
                            warehouse.AvailableStock
                          )
                        }
                        className="w-full px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter qty"
                      />
                    </td>
                  </tr>
                ))
              ) : loading ? (
                <tr>
                  <td colSpan={9} className="h-40 text-center align-middle">
                    <div className="flex justify-center items-center h-full">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={9} className="text-center py-4 text-xs">
                    No results found
                  </td>
                </tr>
              )}
            </tbody>
            {showSummesion && (
              <thead className="bg-[#005879] sticky bottom-0 z-10 text-gray-100">
                <tr>
                  <th className="px-4 py-3 border text-left text-xs font-bold"></th>
                  <th className="px-4 py-3 border text-left text-xs font-bold"></th>
                  <th className="px-4 py-3 border text-left text-xs font-bold">
                  {t("createOrder.Total")}
                  </th>
                  <th className="px-4 py-3 border text-left text-xs font-bold">
                    {sumData.total_available_stocks}
                  </th>
                  <th className="px-4 py-3 border text-left text-xs font-bold">
                    {sumData.total_sales_quantity}
                  </th>
                </tr>
              </thead>
            )}
          </table>
        </>
      ) : (
        <div className="flex items-center  mt-4">
          <SearchableDropdown
            addressOptions={addressOptions}
            selectedAddress={selectedAddress}
            handleAddressSelectChange={handleAddressSelectChange}
            loading={loading}
            error={error}
          />
          <span className="text-gray-500 py-2 px-4 font-medium">OR</span>
          <button
            onClick={handleOpenModal}
            className="bg-[#1dbda1] text-white text-sm font-medium px-4 py-2 rounded-md shadow flex items-center"
          >
            <span className="mr-2">+</span>    {t("createOrder.Add_New_Address")}
          </button>
        </div>
      )}

      <div className="fixed bottom-0 left-0 w-full bg-white border-t border-gray-300">
        <div className="flex justify-end items-center p-4">
          <hr className="absolute w-full top-0 border-gray-300" />
          <button
            onClick={handleButtonClick}
            className="bg-[#005680] text-white px-4 py-2 rounded-md text-sm font-medium"
          >
            {buttonText}
          </button>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 w-[95%] h-[90%] max-w-7xl relative flex flex-col">
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-xl"
            >
              &times;
            </button>

            <h2 className="text-lg font-semibold mb-4">{t("createOrder.Add_New_Address")}</h2>
            <div className="flex flex-grow">
              {" "}
              <div className="w-1/2 h-full border bg-gray-100 rounded-md">
                <APIProvider
                  apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY || ""}
                >
                  <Map
                    style={{ width: "100%", height: "100%" }} // Full width and height
                    defaultCenter={saudiCenter}
                    defaultZoom={5} // Adjust as needed
                    gestureHandling={"greedy"}
                    disableDefaultUI={true}
                    minZoom={5}
                    maxZoom={20}
                    zoomControl={true}
                    restriction={{
                      latLngBounds: saudiBounds,
                      strictBounds: true,
                    }}
                    onClick={handleMapClick}
                  >
                    {markerPosition && (
                      <Marker
                        position={markerPosition} // Use the marker's position state
                      />
                    )}
                  </Map>
                </APIProvider>
              </div>
              <div className="w-1/2 pl-6 h-full overflow-y-auto">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSaveAddress();
                  }}
                >
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.Building_Number")}
                      </label>
                      <input
                        type="text"
                        className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                        required
                        value={buildNo}
                        onChange={(e) => setBuildNo(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.Street")}
                      </label>
                      <input
                        type="text"
                        className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                        required
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.City")}
                      </label>
                      <input
                        type="text"
                        className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                        required
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.District")}
                      </label>
                      <input
                        type="text"
                        className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                        required
                        value={distict}
                        onChange={(e) => setDistrict(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.Postal_Code")}
                      </label>
                      <input
                        type="text"
                        className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                        required
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.Address_Title")}
                      </label>
                      <input
                        type="text"
                        className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                        required
                        value={addTitle}
                        onChange={(e) => setAddressTitle(e.target.value)}
                      />
                    </div>
                    <br />
                    <div className="col-span-2">
                      <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.Receiver_Name")}
                      </label>
                      <input
                        type="text"
                        required
                        className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.Receiver_Mobile")}
                      </label>
                      <div className="flex items-center">
                        <span className="border border-gray-300 bg-gray-100 px-3 py-2 text-sm rounded-l-md">
                          +966
                        </span>
                        <input
                          type="tel"
                          className="w-full border border-gray-300 rounded-r-md py-2 px-3 text-sm"
                          required
                          pattern="^\d{9}$"
                          placeholder="Enter 9-digit mobile number"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                          onBlur={(e) => {
                            const value = e.target.value;
                            if (value.length < 9 || value.length > 9) {
                              toast.error("Mobile number must be 9 digits");
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.Receiver_Email_ID")}
                      </label>
                      <input
                        type="email"
                        className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={(e) => {
                          const email = e.target.value;
                          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                          if (!emailRegex.test(email)) {
                            toast.error("Invalid email format");
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="absolute bottom-6 right-6">
                    <button
                      className="bg-[#1dbda1] text-white text-sm font-medium px-6 py-2 rounded-md shadow mt-4"
                      type="submit"
                    >
                       {t("createOrder.Save_Address")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {savedDetails && (
        <div className="p-4 w-[70%] pl-0">
          <div className="flex bg-lightgrey p-6 gap-6">
            {/* Left Section: Address Details */}
            <div className="w-1/2">
              <div className="flex mt-2 gap-6">
                <div>
                  <p className="text-sm">Address Title</p>
                  <p className="tracking-wide font-semibold">
                    {savedDetails.address_title}
                  </p>
                </div>
                <div>
                  <p className="text-sm">Building Number</p>
                  <p className="tracking-wide font-semibold">
                    {savedDetails.building_no}
                  </p>
                </div>
              </div>

              <div className="flex mt-2 gap-6">
                <div>
                  <p className="text-sm">Street</p>
                  <p className="tracking-wide font-semibold">
                    {savedDetails.street}
                  </p>
                </div>
                <div>
                  <p className="text-sm">City</p>
                  <p className="tracking-wide font-semibold">
                    {savedDetails.city}
                  </p>
                </div>
                <div>
                  <p className="text-sm">District</p>
                  <p className="tracking-wide font-semibold">
                    {savedDetails.district}
                  </p>
                </div>
              </div>

              <p className="text-sm mt-2">Postal Code</p>
              <p className="tracking-wide font-semibold">
                {savedDetails.postal_code}
              </p>
            </div>

            {/* Divider */}
            <div className="border-l border-gray-400"></div>

            {/* Right Section: Receiver Details */}
            <div className="w-1/2">
              <p className="text-sm">Receiver Name</p>
              <p className="tracking-wide font-semibold">{savedDetails.name}</p>

              <p className="text-sm mt-2">Receiver Mobile</p>
              <p className="tracking-wide font-semibold">
                {savedDetails.mobile}
              </p>

              <p className="text-sm mt-2">Receiver Email ID</p>
              <p className="tracking-wide font-semibold">
                {savedDetails.email}
              </p>
            </div>
          </div>
        </div>
      )}

      {isPopupOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-[60%] p-6 relative">
            <div className="p-4">
              <div className="p-4 pl-0 text-16 font-semibold">
                New Address Created
              </div>
              <div>
                <button
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
                  onClick={onClose}
                >
                  ✕
                </button>
              </div>
              <div className="flex bg-[#F5F5F5] p-6 gap-6">
                {/* Left Section: Address Details */}
                <div className="w-1/2">
                  <div className="flex mt-2 gap-6">
                    <div>
                      <p className="text-sm">Address Title</p>
                      <p className="tracking-wide font-semibold">{addTitle}</p>
                    </div>
                    <div>
                      <p className="text-sm">Building Number</p>
                      <p className="tracking-wide font-semibold">{buildNo}</p>
                    </div>
                  </div>

                  <div className="flex mt-2 gap-6">
                    <div>
                      <p className="text-sm">Street</p>
                      <p className="tracking-wide font-semibold">{street}</p>
                    </div>
                    <div>
                      <p className="text-sm">City</p>
                      <p className="tracking-wide font-semibold">{city}</p>
                    </div>
                    <div>
                      <p className="text-sm">District</p>
                      <p className="tracking-wide font-semibold">{distict}</p>
                    </div>
                  </div>

                  <p className="text-sm mt-2">Postal Code</p>
                  <p className="tracking-wide font-semibold">{postalCode}</p>
                </div>

                {/* Divider */}
                <div className="border-l border-gray-400"></div>

                {/* Right Section: Receiver Details */}
                <div className="w-1/2">
                  <p className="text-sm">Receiver Name</p>
                  <p className="tracking-wide font-semibold">{name}</p>

                  <p className="text-sm mt-2">Receiver Mobile</p>
                  <p className="tracking-wide font-semibold">{mobile}</p>

                  <p className="text-sm mt-2">Receiver Email ID</p>
                  <p className="tracking-wide font-semibold">{email}</p>
                </div>
              </div>

              <div className="flex justify-center mt-6">
                <button
                  className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
                  onClick={handleContinue}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FetchedData;
