import Loader from "../../../components/Loader";
import React, { useState, useEffect } from "react";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { DeliveryData, InvoiceData, Order } from "../OrderHistory";
import Modal, { ModalOrderData } from "./Models";
import DeliveryModal from "./DeliveryModal";
import InvoiceModal from "./InvoiceModal";

interface OrderTableProps {
  tableData: Order[];
  loading: boolean;
  setLoading: (value: boolean) => void;
  handleShowModal: (orderId: number) => void;
  showModal: boolean;
  handleHideModal: () => void;
  data: ModalOrderData | undefined;
  printData: () => void;
  handleShowDeliveryModal: (orderId: number) => void;
  showDeliveryModal: boolean;
  deliveryModalData: DeliveryData | undefined;
  handleHideDeliveryModal: () => void;
  printDelivery: () => void;
  handleShowInvoiceModal: (orderId: number) => void;
  invoiceModalData: InvoiceData | undefined;
  showInvoiceModal: boolean;
  handleHideInvoiceModal: () => void;
  handlePrintData: () => void;
}
const OrderTable: React.FC<OrderTableProps> = ({
  tableData,
  loading,
  setLoading,
  handleShowModal,
  showModal,
  handleHideModal,
  data,
  printData,
  handleShowDeliveryModal,
  showDeliveryModal,
  deliveryModalData,
  handleHideDeliveryModal,
  printDelivery,
  handleShowInvoiceModal,
  invoiceModalData,
  showInvoiceModal,
  handleHideInvoiceModal,
  handlePrintData,
}) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const toggleRow = (index: number) => {
    setExpandedRows((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const statusClasses = (status: string) => {
    switch (status) {
      case "PLACED":
        return "bg-green-100 text-green-800 border-green-800";
      case "CONFIRMED":
        return "bg-yellow-100 text-yellow-800 border-yellow-800";
      case "SHIPPED":
        return "bg-red-100 text-red-800 border-red-800";
      case "DELIVERED":
        return "bg-blue-100 text-blue-800 border-blue-800";
      case "INVOICED":
        return "bg-[#f1d4ff] text-[#bf5cf4] border-[#bf5cf4]";
      case "PENDING":
        return "bg-[#fdebd3] text-[#f5a623] border-[#f5a623]";

      default:
        return "";
    }
  };

  // console.log(tableData.length, "<<<<<<<<<--------------tableData");

  const formatDateTime = (isoString: string, type: string): string => {
    const date = new Date(isoString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const amPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;

    if (type === "date") {
      return `${day}/${month}/${year}`;
    } else {
      return `${day}/${month}/${year} - ${formattedHours}:${minutes} ${amPm}`;
    }
  };

  return (
    <div className="p-2 bg-gray-50">
      {showModal && (
        <Modal
          handleHideModal={handleHideModal}
          data={data}
          printData={printData}
        />
      )}
      {showDeliveryModal && (
        <DeliveryModal
          data={deliveryModalData}
          handleHideDeliveryModal={handleHideDeliveryModal}
          printDelivery={printDelivery}
        />
      )}
      <InvoiceModal
        isOpen={showInvoiceModal}
        data={invoiceModalData}
        onClose={handleHideInvoiceModal}
        handlePrintData={handlePrintData}
      />
      <div className="bg-white shadow-sm rounded-md overflow-hidden">
        <div
          className="h-[71vh] overflow-y-auto scrollbar-hidden"
          style={{
            WebkitOverflowScrolling: "touch",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <table className="min-w-full border-collapse">
            <thead className="bg-blue-50 sticky top-0 z-20">
              <tr>
                <th className="px-1 py-3 text-left text-xs font-medium text-gray-700"></th>
                {[
                  "Order ID",
                  "SAP Order ID",
                  "Order Created Date",
                  "Address Title",
                  "Status",
                  "Delivery ID",
                  "Delivery Date",
                  "Invoice",
                  "Invoice Date",
                ].map((header) => (
                  <th
                    key={header}
                    className="px-6 py-3 text-left text-xs text-gray-900 font-semibold"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            {!loading ? (
              <tbody className="divide-y divide-gray-200">
                {tableData.map((row, index) => (
                  <React.Fragment key={index}>
                    <tr className="cursor-pointer hover:bg-gray-100">
                      <td
                        className="px-1 py-4 text-xs text-gray-900"
                        onClick={() => toggleRow(index)}
                      >
                        {expandedRows.includes(index) ? (
                          <IoMdArrowDropdown className="text-lg" />
                        ) : (
                          <IoMdArrowDropright className="text-lg" />
                        )}
                      </td>
                      <td
                        onClick={() => handleShowModal(row.orderId)}
                        className="px-6 py-4 text-xs text-gray-900"
                      >
                        {row.orderId || "N/A"}
                      </td>
                      <td className="px-6 py-4 text-xs text-gray-900">
                        {row.SAPOrderId || "N/A"}
                      </td>
                      <td className="px-6 py-4 text-xs text-gray-900">
                        {formatDateTime(row.orderCreatedDate, "date&time") ||
                          "N/A"}
                      </td>
                      <td className="px-6 py-4 text-xs text-gray-900">
                        {row.addressTitle  || "N/A"}
                      </td>
                      <td className="px-6 py-4 text-xs">
                        <span
                          className={`inline-flex items-center justify-center w-24 text-center px-2 py-1 text-xs border-[1px] rounded-md ${statusClasses(
                            row.status
                          )}`}
                        >
                          {row.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-xs text-gray-900"></td>
                      <td className="px-6 py-4 text-xs text-gray-900"></td>
                      <td className="px-6 py-4 text-xs text-gray-900"></td>
                      <td className="px-6 py-4 text-xs text-gray-900"></td>
                    </tr>
                    {expandedRows.includes(index) && (
                      <>
                        {row.invoices.map((invoice, subIndex) => (
                          <tr
                            key={`invoice-${subIndex}`}
                            className="bg-gray-50"
                          >
                            <td className="px-1 py-4 text-xs text-gray-900"></td>
                            <td className="px-6 py-4 text-xs text-gray-900"></td>
                            <td className="px-6 py-4 text-xs text-gray-900"></td>
                            <td className="px-6 py-4 text-xs text-gray-900"></td>
                            <td className="px-6 py-4 text-xs text-gray-900"></td>
                            <td className="px-6 py-4 text-xs text-gray-900"></td>
                            <td className="px-6 py-4 text-xs text-blue-500 font-bold"></td>
                            <td className="px-6 py-4 text-xs text-gray-900"></td>
                            <td
                              onClick={() =>
                                handleShowInvoiceModal(invoice.invoiceId)
                              }
                              className="px-6 py-4 text-xs text-blue-500 font-bold cursor-pointer"
                            >
                              <a
                                href={invoice.invoicePath}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {invoice.invoiceId || "N/A"}
                              </a>
                            </td>
                            <td className="px-6 py-4 text-xs text-gray-900">
                              {formatDateTime(invoice.invoiceDate, "date") ||
                                "N/A"}
                            </td>
                          </tr>
                        ))}
                        {row.deliveries.map((delivery, subIndex) => (
                          <tr
                            key={`shipment-${subIndex}`}
                            className="bg-gray-50"
                          >
                            <td className="px-1 py-4 text-xs text-gray-900"></td>
                            <td className="px-6 py-4 text-xs text-gray-900"></td>
                            <td className="px-6 py-4 text-xs text-gray-900"></td>
                            <td className="px-6 py-4 text-xs text-gray-900"></td>
                            <td className="px-6 py-4 text-xs text-gray-900"></td>
                            <td className="px-6 py-4 text-xs text-gray-900"></td>
                            <td
                              onClick={() =>
                                handleShowDeliveryModal(
                                  delivery.sap_delivery_id
                                )
                              }
                              className="px-6 py-4 text-xs text-blue-500 font-bold cursor-pointer"
                            >
                              {delivery.sap_delivery_id || "N/A"}
                            </td>
                            <td className="px-6 py-4 text-xs text-gray-900">
                              {formatDateTime(delivery.deliveryDate, "date") ||
                                "N/A"}
                            </td>
                            <td className="px-6 py-4 text-xs text-blue-500 font-bold"></td>
                            <td className="px-6 py-4 text-xs text-gray-900"></td>
                          </tr>
                        ))}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            ) : (
              <tbody className="h-48">
                <tr>
                  <td colSpan={10} className="h-40 text-center align-middle">
                    <div className="flex justify-center items-center h-full">
                      <Loader />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          {tableData.length === 0 && ! loading && <p className="text-center text-sm mt-3">No Data Found</p>}
        </div>
      </div>
    </div>
  );
};

export default OrderTable;