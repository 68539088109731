import React, { useEffect, useState, useRef } from "react";
import DashboardWrapper from "../../layout/index";
import getLeaderBoardList, { getDashboardKpiData } from "../../api/dashobard";
import LeaderTable from "../../components/LeaderTable";
import Loader from "../../components/Loader";
import Dropdown from "../../components/Dropdown";
import { formatCurrentEndDate, formatCurrentStartDate } from "../../utility";
import { getGlobalFilter } from "../../api/filters";
import TimeFrameSelector from "../../components/TimeFrameSelector";
import { formatDate } from "../../utility";
import { useLocation } from "react-router-dom";
import Filter from "../../assets/icons/Filter.svg";
import Close from "../../assets/close-circle.svg";
import Channel1060 from "./Channel1060";
import Channel50 from "./Channel50";
import Channel20 from "./Channel20";
import Channel40 from "./Channel40";
import { useTranslation } from "react-i18next";
import DropdownMulti from "../../components/DropdownMultiple";

const Dashboard = () => {
  const [leaderData, setLeaderData] = useState<any>([]);
  const [leaderDataList, setLeaderDataList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [startDate, setStartDate] = useState<any | undefined>(getCurrentDate());
  const [endDate, setEndDate] = useState<any | undefined>(getCurrentDate());
  const [dashobardData, setDashboardData] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataLoader, setDataLoader] = useState<boolean>(false);
  const { t } = useTranslation();
  const [globalFilters, setGlobalFilters] = useState<any>(null);
  const [salesOffice, setSelectedSalesOffice] = useState<any>("");
  const [salesRegion, setSelectedSalesRegion] = useState<any>("");
  const [timeFrame, setSelectTimeFrame] = useState<any>("Today");
  const [regionList, setRegionList] = useState<any>([]);
  const location = useLocation();
  const [emp, setEmployee] = useState<any>(
    location.state ? Array(location.state) : []
  );
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [channel, setChannel] = useState<any>("");
  const [channelId, setChannelId] = useState<any>("");
  const [teretorryId, setTeretorryId] = useState<any>();
  const scrollContainerRef = useRef(null);
  const [loadingFilter, setLoadingFilter] = useState<boolean>(false);
  const [selectedEmpStatus, setSelectedEmpStatus] = useState<any>("");
  const [userType, setUserType] = useState<any>("");
  const defaultChannel = 20;

  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    checkTimeFrame(e.target.value, endDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
    checkTimeFrame(startDate, e.target.value);
  };

  const getGlobalFilters = async (data: any) => {
    try {
      if (loadingFilter) {
        return;
      }
      setLoadingFilter(true);
      const response = await getGlobalFilter(data);
      setGlobalFilters(response);
      const exists = response?.channels?.some(
        (item: any) => Number(item.id) === defaultChannel
      );
      if (exists) {
        console.log("exties");
        setChannel(defaultChannel);
      } else {
        console.log("res", response);
        setChannel(Number(response?.channels[0]?.id));
      }
    } catch (error) {
      console.error("Error fetching global filters:", error);
    } finally {
      setLoadingFilter(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClearFilters = () => {
    // Define default values
    const defaultStartDate = getCurrentDate();
    const defaultEndDate = getCurrentDate();

    // Reset state to default values
    setSelectedSalesRegion([]);
    setEmployee([]);
    setRegionList([]);
    setSelectedSalesOffice([]);
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    setChannel(defaultChannel);
    setChannelId("");
    setUserType("");
    setSelectedEmpStatus("");
    setSelectTimeFrame("Today");
    setTeretorryId("");
    handleClearApply(defaultStartDate, defaultEndDate);
  };

  const handleClearApply = (startDate: string, endDate: string) => {
    setOpen(false);

    const data: any = {};
    if (startDate) {
      data["start_date"] = formatCurrentStartDate(new Date(startDate));
    }
    if (endDate) {
      data["end_date"] = formatCurrentEndDate(new Date(endDate));
    }
    getDashboardData(data);
    setChannel(defaultChannel);
    const param = {
      page: 1,
      size: 15,
    };
    setCurrentPage(1);

    const dataLeader: any = {};
    if (startDate) {
      dataLeader["start_date"] = formatCurrentStartDate(new Date(startDate));
    }
    if (endDate) {
      dataLeader["end_date"] = formatCurrentEndDate(new Date(endDate));
    }
    getLeaderBoardData(param, dataLeader);
  };

  const getIndex = (data: any, index: any) => {
    return Number(index + 1);
  };

  const getDashboardData = async (data: any) => {
    await getDashboardKpiData(data)
      .then((res) => {
        setDashboardData(res.data);
        if (channelId) {
          setChannel(Number(channelId));
        } else {
          setChannel(Number(defaultChannel));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLeaderBoardData = async (param: any, data: any) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    await getLeaderBoardList(param, data)
      .then((res) => {
        setLeaderData(res.data);
        setIsLoading(false);
        if (currentPage === 1) {
          setLeaderDataList(res.data.items);
        } else {
          setLeaderDataList((prevData: any) => [
            ...prevData,
            ...res.data.items,
          ]);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleFiltersUpdate = () => {
    const data: any = {};

    if (salesRegion.length > 0) {
      data["regions"] = salesRegion;
    }
    if (salesOffice.length > 0) {
      data["sales_office_ids"] = salesOffice;
    }
    if (emp.length > 0) {
      data["subordinate_ids"] = emp;
    }
    if (teretorryId.length > 0) {
      data["territory_ids"] = teretorryId;
    }

    data["endpoint"] = "visit-summary";
    data["start_date"] = formatCurrentStartDate(new Date(startDate));
    data["end_date"] = formatCurrentEndDate(new Date(endDate));
    console.log("Filters Updated:", data);
    getGlobalFilters(data);
  };

  useEffect(() => {
    const data: any = {};
    if (startDate) {
      data["start_date"] = formatCurrentStartDate(new Date(startDate));
    }
    if (startDate) {
      data["end_date"] = formatCurrentEndDate(new Date(endDate));
    }
    if (salesOffice.length > 0) {
      data["sales_office_ids"] = salesOffice;
    }
    if (channelId) {
      data["channel_ids"] = [String(channelId)];
    }
    if (salesRegion.length > 0) {
      data["regions"] = salesRegion;
    }
    if (emp.length > 0) {
      data["subordinate_ids"] = emp;
    }
    if (userType) {
      data["user_type"] = userType;
    }
    getDashboardData(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApply = () => {
    setOpen(false);
    const data: any = {};
    if (startDate) {
      data["start_date"] = formatCurrentStartDate(new Date(startDate));
    }
    if (startDate) {
      data["end_date"] = formatCurrentEndDate(new Date(endDate));
    }
    if (salesOffice.length > 0) {
      data["sales_office_ids"] = salesOffice;
    }
    if (channelId) {
      data["channel_ids"] = [String(channelId)];
    }
    if (salesRegion.length > 0) {
      data["regions"] = salesRegion;
    }
    if (selectedEmpStatus) {
      data["status"] = selectedEmpStatus;
    }
    if (emp.length > 0) {
      data["subordinate_ids"] = emp;
    }
    if (userType) {
      data["user_type"] = userType;
    }
    if (teretorryId.length > 0) {
      data["territory_ids"] = teretorryId;
    }
    getDashboardData(data);

    const param = {
      page: 1,
      size: 15,
    };

    setCurrentPage(1);
    const dataLeader: any = {};

    if (startDate) {
      dataLeader["start_date"] = formatCurrentStartDate(new Date(startDate));
    }
    if (startDate) {
      dataLeader["end_date"] = formatCurrentEndDate(new Date(endDate));
    }
    if (salesOffice.length > 0) {
      dataLeader["sales_office_ids"] = salesOffice;
    }
    if (salesRegion.length > 0) {
      dataLeader["regions"] = salesRegion;
    }
    if (selectedEmpStatus) {
      dataLeader["status"] = selectedEmpStatus;
    }
    if (emp.length > 0) {
      dataLeader["subordinate_ids"] = emp;
    }
    if (userType) {
      dataLeader["user_type"] = userType;
    }
    if (channelId) {
      dataLeader["channel_ids"] = [String(channelId)];
    }
    if (teretorryId.length > 0) {
      dataLeader["territory_ids"] = teretorryId;
    }
    getLeaderBoardData(param, dataLeader);
  };

  useEffect(() => {
    const param = {
      page: currentPage,
      size: 15,
    };

    const data: any = {};

    if (startDate) {
      data["start_date"] = formatCurrentStartDate(new Date(startDate));
    }
    if (startDate) {
      data["end_date"] = formatCurrentEndDate(new Date(endDate));
    }
    if (salesOffice) {
      data["sales_office_ids"] = salesOffice;
    }
    if (channelId) {
      data["channel_ids"] = [String(channelId)];
    }
    if (salesRegion) {
      data["regions"] = salesRegion;
    }
    if (selectedEmpStatus) {
      data["status"] = selectedEmpStatus;
    }
    if (emp) {
      data["subordinate_ids"] = emp;
    }
    if (userType) {
      data["user_type"] = userType;
    }
    getLeaderBoardData(param, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    const data: any = {};

    if (salesRegion) {
      data["regions"] = salesRegion;
    }
    if (salesOffice) {
      data["sales_office_ids"] = salesOffice;
    }
    if (emp) {
      data["subordinate_ids"] = emp;
    }
    if (teretorryId) {
      data["territory_ids"] = teretorryId;
    }
    data["endpoint"] = "dashboard";
    data["start_date"] = formatCurrentStartDate(new Date(startDate));
    data["end_date"] = formatCurrentEndDate(new Date(endDate));

    getGlobalFilters(data);
  }, []);

  const checkTimeFrame = (start: any, end: any) => {
    if (start === formatDate(new Date()) && end === formatDate(new Date())) {
      setSelectTimeFrame("Today");
    } else if (
      start === formatDate(new Date().setDate(new Date().getDate() - 1)) &&
      end === formatDate(new Date().setDate(new Date().getDate() - 1))
    ) {
      setSelectTimeFrame("Yesterday");
    } else if (
      start === formatDate(new Date().setDate(1)) &&
      end === formatDate(new Date())
    ) {
      setSelectTimeFrame("Month Till Date");
    } else {
      setSelectTimeFrame("");
    }
  };

  useEffect(() => {
    setSelectedSalesOffice([]);
    setTeretorryId([]);
    setEmployee([]);
  }, [salesRegion]);

  useEffect(() => {
    setTeretorryId([]);
    setEmployee([]);
  }, [salesOffice]);

  useEffect(() => {
    setChannelId("");
    setEmployee([]);
  }, [teretorryId]);

  useEffect(() => {
    if (emp) {
      globalFilters?.employees?.map((value: any) => {
        if (emp === value.id) {
          setUserType(value.position);
        }
      });
    } else {
      setUserType("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emp]);

  return (
    <DashboardWrapper className="fixed-dashboard-wrapper">
      <div className="bg-white"></div>

      <div className="flex gap-3 pb-0 md:pb-0 p-4 md:p-3 w-full lg:flex-row flex-col">
        {dataLoader && (
          <div className="absolute top-[50%] left-[50%] z-50">
            <Loader />
          </div>
        )}
        <div className="lg:w-[63%] w-full">
          <div className="flex justify-between items-center">
            <div className="flex flex-col gap-1 pl-1 pb-3 text-13 font-medium">
              <p>{t("DashboardAnalytics.reportTitle")}</p>
              <p className="text-[20px] font-semibold">
                {timeFrame ? (
                  <>
                    {timeFrame === "Today" && t("DashboardAnalytics.today")}
                    {timeFrame === "Yesterday" &&
                      t("timeframeselector.yesterday")}
                    {timeFrame === "Month Till Date" &&
                      t("DashboardAnalytics.monthtilldate")}
                  </>
                ) : (
                  startDate?.split("-").reverse().join("-") +
                  " To " +
                  endDate?.split("-").reverse().join("-")
                )}
              </p>
            </div>
            <div>
              <button
                className="flex items-center px-4 p-3 gap-2 bg-[#FAFAFA] border-2 border-[#EBEBEB]"
                onClick={() => setOpen(true)}
              >
                <img src={Filter} alt="" />{" "}
                {t("DashboardAnalytics.filterButton")}
              </button>
            </div>
          </div>

          {channel === 20 && <Channel20 dashobardData={dashobardData} />}
          {(channel === 10 || channel === 60) && (
            <Channel1060 dashobardData={dashobardData} />
          )}
          {channel === 50 && <Channel50 dashobardData={dashobardData} />}
          {channel === 40 && <Channel40 dashobardData={dashobardData} />}
        </div>

        {open && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end sm:items-start items-center z-50">
            <div className="bg-white shadow-lg w-full max-w-md h-full">
              <div className="flex justify-between p-6 border-b-2">
                <div className="text-16 font-bold">
                  {t("DashboardAnalytics.filtersTitle")}
                </div>
                <div>
                  <img
                    className="cursor-pointer"
                    onClick={() => setOpen(false)}
                    src={Close}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between h-[calc(100vh-100px)] px-12 pt-6  overflow-y-auto">
                <div className="relative flex flex-col gap-[30px]">
                  <div className="absolute z-30 top-[50%] left-[45%]">
                    {loadingFilter && <Loader />}
                  </div>
                  <div className="flex flex-col sm:flex-row gap-4 w-full">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.region")}
                        options={globalFilters?.regions.map((sales: any) => ({
                          value: sales,
                          label: sales,
                        }))}
                        selectedValues={salesRegion}
                        onClose={handleFiltersUpdate}
                        onChange={setSelectedSalesRegion}
                        placeholder={t("visitreports.filters.selectRegion")}
                      />
                    </div>
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.salesOffice")}
                        options={
                          globalFilters?.sales_offices.map((sales: any) => ({
                            value: sales.id,
                            label: sales.name,
                          })) || []
                        }
                        selectedValues={salesOffice}
                        onClose={handleFiltersUpdate}
                        onChange={setSelectedSalesOffice}
                        disabled={!salesRegion || loadingFilter}
                        placeholder={t(
                          "visitreports.filters.selectSalesOffice"
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4 w-full">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.territoryID")}
                        options={
                          globalFilters?.territories?.map((emp: any) => ({
                            value: String(emp.territory_id),
                            label: emp.territory_identifier,
                          })) || []
                        }
                        selectedValues={teretorryId}
                        onChange={setTeretorryId}
                        onClose={handleFiltersUpdate}
                        disabled={!salesRegion || loadingFilter}
                        placeholder={t(
                          "visitreports.filters.selectTerritoryID"
                        )}
                      />
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label={t("visitreports.filters.channelID")}
                        options={
                          globalFilters?.channels?.map((emp: any) => ({
                            value: `${emp.id}`,
                            label: emp.name,
                          })) || []
                        }
                        selectedValue={channelId}
                        onChange={setChannelId}
                        disabled={!salesRegion || loadingFilter}
                        placeholder={t("visitreports.filters.selectChannelID")}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("ActivityReport.employeeName")}
                        options={
                          globalFilters?.employees.map((emp: any) => ({
                            value: emp.id,
                            label: emp.name_eng,
                          })) || []
                        }
                        selectedValues={emp}
                        onClose={handleFiltersUpdate}
                        onChange={setEmployee}
                        disabled={!salesRegion || loadingFilter}
                        placeholder={t("ActivityReport.employeeName")}
                        searchable={true}
                      />
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label={t("DashboardAnalytics.usertype")}
                        options={
                          globalFilters?.user_types.map((emp: any) => ({
                            value: emp,
                            label: emp,
                          })) || []
                        }
                        selectedValue={userType}
                        onChange={setUserType}
                        // disabled={!salesRegion || loadingFilter}
                        placeholder={t("DashboardAnalytics.usertype")}
                      />
                    </div>
                  </div>
                  <Dropdown
                    label={t("DashboardAnalytics.status")}
                    options={
                      globalFilters?.employee_status.map((emp: any) => ({
                        value: emp,
                        label: emp === "A" ? "Active" : "Inactive",
                      })) || []
                    }
                    selectedValue={selectedEmpStatus}
                    onChange={setSelectedEmpStatus}
                    // disabled={!salesRegion || loadingFilter}
                    placeholder={t("DashboardAnalytics.status")}
                  />
                  {/* <div className="w-full">
                    <div className="w-full">
                      <Dropdown
                        label={t("DashboardAnalytics.usertype")}
                        options={
                          globalFilters?.user_types.map((emp: any) => ({
                            value: emp,
                            label: emp,
                          })) || []
                        }
                        selectedValue={userType}
                        onChange={setUserType}
                        disabled={!salesRegion || loadingFilter}
                        placeholder={t("DashboardAnalytics.usertype")}
                      />
                    </div>
                  </div> */}
                  <div className="date-range-picker">
                    <div className="flex flex-col sm:flex-row gap-4 w-full">
                      <div className="w-1/2">
                        <label className="block text-10 text-gray-500 pb-1">
                          {t("DashboardAnalytics.startDateLabel")}
                        </label>
                        <input
                          type="date"
                          className="rounded p-2 border-2 border-gray-400 w-full"
                          value={startDate}
                          onChange={handleStartDateChange}
                          onKeyDown={(e) => e.preventDefault()}
                          max={new Date(endDate).toISOString().split("T")[0]}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block text-10 text-gray-500 pb-1">
                          {t("DashboardAnalytics.endDateLabel")}
                        </label>
                        <input
                          type="date"
                          className="rounded p-2 border-2 border-gray-400 w-full"
                          value={endDate}
                          onChange={handleEndDateChange}
                          onKeyDown={(e) => e.preventDefault()}
                          max={new Date().toISOString().split("T")[0]}
                          min={new Date(startDate).toISOString().split("T")[0]}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <TimeFrameSelector
                      customClass="w-full border border-gray-300 rounded p-2"
                      setSelectTimeFrame={setSelectTimeFrame}
                      timeFrame={timeFrame}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                </div>

                <div className="flex justify-between mt-4">
                  <button
                    onClick={handleClearFilters}
                    className="px-4 py-2 text-black bg-gray-200 rounded transition duration-200 hover:bg-gray-300"
                  >
                    {t("DashboardAnalytics.clearFilterButton")}
                  </button>
                  <button
                    onClick={handleApply}
                    className="px-4 py-2 text-white bg-purple rounded transition duration-200 hover:bg-[#F3E6F4] hover:text-purple"
                  >
                    {t("DashboardAnalytics.applyFilterButton")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="lg:max-w-[35%] w-full rounded">
          <h2 className="text-base font-bold p-4 text-white bg-purple">
            {t("DashboardAnalytics.leaderboardTitle")}
          </h2>
          <div
            ref={scrollContainerRef}
            id="scorll-container"
            className="overflow-x-hidden overflow-scroll h-[645px] md:h-[calc(100vh-130px)] scrollbar scrollbar-thin"
          >
            <LeaderTable
              leaderData={leaderDataList}
              loader={isLoading}
              getIndex={getIndex}
            />
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default Dashboard;
