import Loader from "../../../components/Loader";
import React, { useState, useEffect } from "react";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import saveAddress from "../api/editAddress";
import { toast, ToastContainer } from "react-toastify";
import { addAddress } from "../api/editAddress";
import { getAddressDropdown } from "../api/createOrder";
import { Map, APIProvider,Marker } from "@vis.gl/react-google-maps";

const saudiCenter = { lat: 24.774265, lng: 46.738586 };
const saudiBounds = {
  north: 33.0,
  south: 16.0,
  east: 60.0,
  west: 34.0,
};

interface ContactAndEmailTableProps {
  loading: boolean;
  setLoading: (value: boolean) => void;
  currentPage: any;
  address: any;
  getAddressData: any;
}
const ContactAndEmailTable: React.FC<ContactAndEmailTableProps> = ({
  loading,
  setLoading,
  currentPage,
  address,
  getAddressData,
}) => {
  const [addressOptions, setAddressOptions] = useState<any>();
  const [addTitle, setAddressTitle] = useState<any>();
  const [buildNo, setBuildNo] = useState<any>();
  const [city, setCity] = useState<any>();
  const [distict, setDistrict] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [lat, setLat] = useState<any>();
  const [lng, setLng] = useState<any>();
  const [mobile, setMobile] = useState<any>();
  const [name, setName] = useState<any>();
  const [postalCode, setPostalCode] = useState<any>();
  const [street, setStreet] = useState<any>();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const [menuOpen, setMenuOpen] = useState<number | null>(null);
  const toggleMenu = (id: number) => {
    setMenuOpen(menuOpen === id ? null : id);
  };

  const [showModal, setShowModal] = useState(false);
  const [dynamicID, setDynamicId] = useState();
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const handleOpenModal = (postal_code: any, addressData: any) => {
    setDynamicId(postal_code); // Set dynamic ID if needed
    setFormData({
      name: addressData?.name || "",
      address_title: addressData?.address_title || "",
      street: addressData?.street || "",
      building_no: addressData?.building_no || "",
      city: addressData?.city || "",
      district: addressData?.district || "",
      postal_code: addressData?.postal_code || "",
      latitude: addressData?.latitude || 24.774265, // Default latitude
      longitude: addressData?.longitude || 46.738586, // Default longitude
      mobile: addressData?.mobile || "",
      email: addressData?.email || "",
    });
    setShowModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [formData, setFormData] = useState({
    name: "",
    address_title: "",
    street: "",
    building_no: "",
    city: "",
    district: "",
    postal_code: "",
    latitude: 24.774265,
    longitude: 46.738586,
    mobile: "",
    email: "",
  });

  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const mockData = {
      name: "keda Doe",
      address_title: "Home",
      street: "123 Main St",
      building_no: "A1",
      city: "Riyadh2",
      district: "Central",
      postal_code: "32567",
      latitude: 24.774265,
      longitude: 46.738586,
      mobile: "5551234567",
      email: "change.doe@example.com",
    };

    // setFormData(mockData);
    setFormData({ ...formData, [name]: value });
  };

  const handleSaveAddress = async () => {
    setLoading(true);
    setError(null);

    try {
      const params = {}; // Add any query parameters if required
      const response = await saveAddress(params, formData, dynamicID);
      toast.success("Address saved successfully!");
      setMenuOpen(null);
      setShowModal(false);
      setTimeout(() => {
        setFormData({
          name: "",
          address_title: "",
          street: "",
          building_no: "",
          city: "",
          district: "",
          postal_code: "",
          latitude: 24.774265,
          longitude: 46.738586,
          mobile: "",
          email: "",
        });

        const param = {
          page_number: currentPage,
          page_size: 20,
        };
        const data = {
          search: "",
        };
        getAddressData(param, null);
      }, 2000);
    } catch (err: any) {
      toast.error("Error saving address:", err.message);
      setError("Failed to save address. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchAddresses = async () => {
    setLoading(true);
    try {
      const response = await getAddressDropdown({});
      setAddressOptions(response.items);
    } catch (err: any) {
      setError("Failed to load addresses");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);
  const handleMapClick = (e: any) => {
    const geocoder = new google.maps.Geocoder();
    const lat = e.detail.latLng.lat;
    const lng = e.detail.latLng.lng;
    setLng(lng);
    setLat(lat);
    setMarkerPosition({ lat, lng });
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results && results.length > 0) {
        const result = results[0];
        const addressComponents = result.address_components;
console.log('addressComponentsaddressComponents',addressComponents);
        // Extract desired information
        const getComponent = (type: string) =>
          addressComponents.find((component) => component.types.includes(type))
            ?.long_name;

        const address = result.formatted_address;
        const city = getComponent("locality");
        const postalCode = getComponent("postal_code");
        const district = getComponent("administrative_area_level_2");
        const street = getComponent("route");

        setCity(city);
        setPostalCode(postalCode);
        setDistrict(district);
        setStreet(street);

        setFormData({
          ...formData,
          street:street|| "",

          city: city || "",
          district: district || "",
          postal_code: postalCode || "",
      
        });

      } else {
        console.error("Geocode failed due to: " + status);
      }
    });
  };
  const onClose = () => {
    setIsPopupOpen(false);
  };

  const handleContinue = async () => {
    try {
      const data = {
        address_title: addTitle,
        building_no: buildNo,
        city: city,
        district: distict,
        email: email,
        latitude: String(lat),
        longitude: String(lng),
        mobile: mobile,
        name: name,
        postal_code: postalCode,
        street: street,
      };
      const response = await addAddress(data);
      toast.success("Address Added Successfully");
      fetchAddresses();

      setTimeout(() => {
        setShowModal(false);
        setIsPopupOpen(false);
      }, 2000);
    } catch (err: any) {
      console.error("Error saving address:", err.message);
      setError("Failed to save address. Please try again.");
      toast.error("Error!");
    } finally {
      setLoading(false);
    }
  };

  const handleViewAddress = (item: any) => {
    setSelectedAddress(item);
    setIsPopupOpen(true);
  };
  
  return (
    <div className="p-6 bg-gray-50">
      <ToastContainer />
      <div className="bg-white shadow-sm rounded-md overflow-hidden">
        <div
          className="h-[71vh] overflow-y-auto scrollbar-hidden"
          style={{
            WebkitOverflowScrolling: "touch",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <table className="min-w-full border-collapse">
            <thead className="bg-blue-50 sticky top-0 z-20">
              <tr>
                {[
                  "Title",
                  "SAP Adress ID",
                  "Receiver Name",
                  "Contact And Emal Id",
                  "Building",
                  "Street",
                  "City",
                  "District",
                  "Postal Code",
                  " ",
                ].map((header) => (
                  <th
                    key={header}
                    className="px-6 py-3 text-left text-xs text-gray-900 font-semibold border border-gray-300"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={11} className="text-center py-4">
                    <Loader />
                  </td>
                </tr>
              ) : address?.length > 0 ? (
                address.map((item: any, index: number) => (
                  <tr
                    key={item.SAPAdressID || index}
                    className="text-sm hover:bg-gray-50 relative"
                  >
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {item.address_title || "-"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {item.outlet_id || "-"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {item.name || "-"}
                    </td>
                    <td
                      className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap"
                      dangerouslySetInnerHTML={{
                        __html:
                          item.mobile && item.email
                            ? `${item.mobile}<br />${item.email}`
                            : item.mobile || item.email || "-",
                      }}
                    ></td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {item.building_no || "-"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {item.street || "-"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {item.city || "-"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {item.district || "-"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {item.postal_code || "-"}
                    </td>
                    <td className="px-4 py-2 border relative">
                      <button
                        onClick={() => toggleMenu(index)}
                        className="text-gray-500 hover:text-gray-800"
                      >
                        ⋮
                      </button>
                      {menuOpen === index && (
                        <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-200 shadow-lg rounded z-10">
                          <ul className="py-1 text-sm text-gray-900">
                            <li
                              className="px-4 py-2 hover:bg-gray-500 cursor-pointer"
                              onClick={() => handleViewAddress(item)}
                            >
                              View Address
                            </li>
                            <li
                              className="px-4 py-2 hover:bg-gray-500 cursor-pointer"
                              onClick={() => handleOpenModal(item.id, item)}
                            >
                              Edit Address
                            </li>
                          </ul>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={11} className="text-center py-4">
                    No addresses found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 w-[95%] h-[90%] max-w-7xl relative flex flex-col">
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-xl"
            >
              &times;
            </button>

            <h2 className="text-lg font-semibold mb-4">Edit Address</h2>
            <div className="flex flex-grow">
              {" "}
              {/* Use flex-grow to allow the content to expand */}
              {/* Map Container */}
              <div className="w-1/2 h-full border bg-gray-100 rounded-md">
                <APIProvider
                  apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY || ""}
                >
                  <Map
                    style={{ width: "100%", height: "100%" }} // Full width and height
                    defaultCenter={saudiCenter}
                    defaultZoom={5} // Adjust as needed
                    gestureHandling={"greedy"}
                    disableDefaultUI={true}
                    minZoom={5}
                    maxZoom={20}
                    zoomControl={true}
                    restriction={{
                      latLngBounds: saudiBounds,
                      strictBounds: true,
                    }}
                    // onClick={handleMapClick}
                  >
{markerPosition && (
                      <Marker
                        position={markerPosition} // Use the marker's position state
                      />
                    )}

                  </Map>
                </APIProvider>
              </div>
              <div className="w-1/2 pl-6 h-full overflow-y-auto">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      Building Number*
                    </label>
                    <input
                      type="text"
                      name="building_no"
                      value={formData.building_no}
                      onChange={handleInputChange}
                      disabled
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      Street*
                    </label>
                    <input
                      type="text"
                      name="street"
                      value={formData.street}
                      disabled
                      onChange={handleInputChange}
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      City*
                    </label>
                    <input
                      type="text"
                      name="city"
                      disabled
                      value={formData.city}
                      onChange={handleInputChange}
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      District*
                    </label>
                    <input
                      type="text"
                      name="district"
                      value={formData.district}
                      disabled
                      onChange={handleInputChange}
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      Postal Code*
                    </label>
                    <input
                      type="text"
                      name="postal_code"
                      value={formData.postal_code}
                      disabled
                      onChange={handleInputChange}
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      Address Title*
                    </label>
                    <input
                      type="text"
                      name="address_title"
                      value={formData.address_title}
                      disabled
                      onChange={handleInputChange}
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  </div>
                  <br />
                  <div className="col-span-2">
                    <label className="block text-sm font-bold text-gray-700">
                      Receiver Name*
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      Receiver Mobile*
                    </label>
                    <div className="flex items-center">
                      <span className="border border-gray-300 bg-gray-100 px-3 py-2 text-sm rounded-l-md">
                      +966
                      </span>
                      <input
                      type="text"
                      name="mobile"
                      value={formData.mobile}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d{0,9}$/.test(value)) {
                          handleInputChange(e);
                        }
                      }}
                      onBlur={(e) => {
                        const value = e.target.value;
                        if (value.length !== 9) {
                          toast.error("Mobile number must be 9 digits");
                        }
                      }}
                      className="w-full border border-gray-300 rounded-r-md py-2 px-3 text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      Receiver Email ID*
                    </label>
                    <input
                      type="text"
                      name="email"
                        onChange={handleInputChange}
                        onBlur={(e) => {
                        const email = e.target.value;
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(email)) {
                          toast.error("Invalid email format");
                        }
                        }}
                      value={formData.email}
                      // onChange={handleInputChange}
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  </div>
                </div>
                <div className="absolute bottom-6 right-6">
                  <button
                    onClick={handleSaveAddress}
                    className="bg-[#1dbda1] text-white text-sm font-medium px-6 py-2 rounded-md shadow mt-4"
                  >
                    Update Address
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

{isPopupOpen && selectedAddress && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-[60%] p-6 relative">
            {/* Header Section */}
            <div className="flex justify-between items-center p-4">
              <h2 className="text-lg font-semibold">Address</h2>
              <button
                className="text-gray-500 hover:text-gray-800"
                onClick={onClose}
              >
                ✕
              </button>
            </div>

            {/* Address Details Section */}
            <div className="flex bg-[#F5F5F5] p-6 gap-6">
              {/* Left Section: Address Details */}
              <div className="w-1/2">
                <div className="flex mt-2 gap-6">
                  <div>
                    <p className="text-sm">Address Title</p>
                    <p className="text tracking-wide font-semibold">
                      {selectedAddress.address_title || "-"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm">Building Number</p>
                    <p className="tracking-wide font-semibold">
                      {selectedAddress.building_no || "-"}
                    </p>
                  </div>
                </div>

                <div className="flex mt-2 gap-6">
                  <div>
                    <p className="text-sm">Street</p>
                    <p className="tracking-wide font-semibold">
                      {selectedAddress.street || "-"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm">City</p>
                    <p className="tracking-wide font-semibold">
                      {selectedAddress.city || "-"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm">District</p>
                    <p className="tracking-wide font-semibold">
                      {selectedAddress.district || "-"}
                    </p>
                  </div>
                </div>

                <p className="text-sm mt-2">Postal Code</p>
                <p className="tracking-wide font-semibold">
                  {selectedAddress.postal_code || "-"}
                </p>
              </div>

              {/* Divider */}
              <div className="border-l border-gray-400"></div>

              {/* Right Section: Receiver Details */}
              <div className="w-1/2">
                <p className="text-sm">Receiver Name</p>
                <p className="tracking-wide font-semibold">
                  {selectedAddress.name || "-"}
                </p>

                <p className="text-sm mt-2">Receiver Mobile</p>
                <p className="tracking-wide font-semibold">
                  {selectedAddress.mobile || "-"}
                </p>

                <p className="text-sm mt-2">Receiver Email ID</p>
                <p className="tracking-wide font-semibold">
                  {selectedAddress.email || "-"}
                </p>
              </div>
            </div>

            <div className="flex justify-center mt-6">
              <button
                className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600"
                onClick={() => {
                  // handleContinue();
                  onClose();
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactAndEmailTable;
