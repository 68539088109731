import React, { useEffect, useState } from "react";
import icon from "../assets/summesion.png";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";

interface Warehouse {
  warehouse_name: string;
  production_id: string;
  UnitText: string;
  description: string;
  unit: string;
  available_stocks: number;
  in_quantity: number;
  blocked: number;
  returns: number;
  in_transits: number;
}

interface WarehouseData {
  items: Warehouse[];
}

interface TableData {
  results: Array<{
    StorageLocName: string;
    NavStockToItem: Array<{
      MaterialId: string;
      MaterialDesc: string;
      AvailableStock: string;
      UnitText: string;
      InQualityInspection: string;
      Blocked: string;
      Return: string;
      InTransit: string;
    }>;
  }>;
}

interface ViewInventoryProps {
  showSummesion: boolean;
  loading: boolean;
  setLoading: (value: boolean) => void;
  tableData: TableData | null;
}

const Table: React.FC<ViewInventoryProps> = ({
  showSummesion,
  loading,
  setLoading,
  tableData,
}) => {
  const [warehouseData, setWarehouseData] = useState<WarehouseData | null>(null);
  const [sumData, setSumData] = useState({
    total_available_stocks: 0,
    total_in_quantity: 0,
    total_blocked: 0,
    total_returns: 0,
    total_in_transits: 0,
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (tableData) {
      const transformedData = {
        items: tableData?.results.flatMap((result) =>
          result.NavStockToItem.map((item) => ({
            warehouse_name: result.StorageLocName,
            production_id: item.MaterialId,
            description: item.MaterialDesc,
            unit: "N/A",
            UnitText: item.UnitText,
            available_stocks: parseFloat(item.AvailableStock) || 0,
            in_quantity: parseFloat(item.InQualityInspection) || 0,
            blocked: parseFloat(item.Blocked) || 0,
            returns: parseFloat(item.Return) || 0,
            in_transits: parseFloat(item.InTransit) || 0,
          }))
        ),
      };

      setTimeout(() => {
        setWarehouseData(transformedData);
        setLoading(false);
      }, 2000);
    }
  }, [tableData, setLoading]);

  const getAllSubmissions = () => {
    if (!warehouseData || !warehouseData.items) return;

    let totals = {
      total_available_stocks: 0,
      total_in_quantity: 0,
      total_blocked: 0,
      total_returns: 0,
      total_in_transits: 0,
    };

    warehouseData.items.forEach((item) => {
      totals.total_available_stocks += item.available_stocks;
      totals.total_in_quantity += item.in_quantity;
      totals.total_blocked += item.blocked;
      totals.total_returns += item.returns;
      totals.total_in_transits += item.in_transits;
    });

    setSumData(totals);
  };

  useEffect(() => {
    if (showSummesion) {
      getAllSubmissions();
    }
  }, [showSummesion, warehouseData]);

  return (
    <div className="pt-5 bg-gray-50">
      <div className="bg-white shadow-sm rounded-md overflow-hidden">
        <div
          className="h-[65vh] overflow-y-auto scrollbar-hidden"
          style={{
            WebkitOverflowScrolling: "touch",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <table className="min-w-full border-collapse">
            <thead className="bg-[#E8F6FC] sticky top-0 z-10">
              <tr>
                <th className="px-4 py-3 border text-left text-xs font-medium text-black-500 whitespace-nowrap">
                {t("inventory.warehouseName")}
                </th>
                <th className="px-4 py-3 border text-left text-xs font-medium text-black-500">
                {t("inventory.productionID")}
                </th>
                <th className="px-4 py-3 border text-left text-xs font-medium text-black-500">
                {t("inventory.description")}
                </th>
                <th className="px-4 py-3 border text-left text-xs font-medium text-black-500">
                {t("inventory.unit")}
                </th>
                <th className="px-2 py-3 border text-left text-xs font-medium text-black-500">
                  <div className="flex items-center space-x-1">
                    <p className="text-xs">{t("inventory.availableStocks")}</p>
                    <img className="cursor-pointer" src={icon} alt="summesion" />
                  </div>
                </th>
                <th className="px-4 py-3 border text-left text-xs font-medium text-black-500">
                  <div className="flex items-center space-x-2">
                    <p className="text-xs">{t("inventory.inQuantity")}</p>
                    <img className="cursor-pointer" src={icon} alt="summesion" />
                  </div>
                </th>
                <th className="px-4 py-3 border text-left text-xs font-medium text-black-500">
                  <div className="flex items-center space-x-2">
                    <p className="text-xs">{t("inventory.blocked")}</p>
                    <img className="cursor-pointer" src={icon} alt="summesion" />
                  </div>
                </th>
                <th className="px-4 py-3 border text-left text-xs font-medium text-black-500">
                  <div className="flex items-center space-x-2">
                    <p className="text-xs">{t("inventory.returns")}</p>
                    <img className="cursor-pointer" src={icon} alt="summesion" />
                  </div>
                </th>
                <th className="px-4 py-3 border text-left text-xs font-medium text-black-500">
                  <div className="flex items-center space-x-2">
                    <p className="text-xs">{t("inventory.inTransits")}</p>
                    <img className="cursor-pointer" src={icon} alt="summesion" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {warehouseData && warehouseData.items?.length > 0 ? (
                warehouseData.items.map((warehouse, index) => (
                  <tr key={index} className="text-xs hover:bg-gray-50">
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.warehouse_name}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.production_id || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.description || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.UnitText || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.available_stocks || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.in_quantity || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.blocked || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.returns || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.in_transits || 0}
                    </td>
                  </tr>
                ))
              ) : loading ? (
                <tr>
                  <td colSpan={9} className="h-40 text-center align-middle">
                    <div className="flex justify-center items-center h-full">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={9} className="text-center py-4 text-xs">
                    No results found
                  </td>
                </tr>
              )}
            </tbody>
            {showSummesion && (
              <thead className="bg-[#005879] sticky bottom-0 z-10 text-gray-100">
                <tr>
                  <th className="px-4 py-3 border text-left text-xs font-bold">
                  {t("inventory.total")}
                  </th>
                  <th className="px-4 py-3 border text-left text-xs font-bold"></th>
                  <th className="px-4 py-3 border text-left text-xs font-bold"></th>
                  <th className="px-4 py-3 border text-left text-xs font-bold"></th>
                  <th className="px-4 py-3 border text-left text-xs font-bold">
                    {sumData.total_available_stocks}
                  </th>
                  <th className="px-4 py-3 border text-left text-xs font-bold">
                    {sumData.total_in_quantity}
                  </th>
                  <th className="px-4 py-3 border text-left text-xs font-bold">
                    {sumData.total_blocked}
                  </th>
                  <th className="px-4 py-3 border text-left text-xs font-bold">
                    {sumData.total_returns}
                  </th>
                  <th className="px-4 py-3 border text-left text-xs font-bold">
                    {sumData.total_in_transits}
                  </th>
                </tr>
              </thead>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;