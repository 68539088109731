import React, { useState } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { CiSearch } from "react-icons/ci";

interface FilterAndPaginationProps {
  toggeleLoding: () => void;
  currentPage:any,
  setCurrentPage:any,
  setSearch:any,
  search:any,
  totalPages:any
  handleSearch:any
}

const PaginationAndSearch: React.FC<FilterAndPaginationProps> = ({
  toggeleLoding,
  currentPage,
  setCurrentPage,
  search,
  setSearch,
  totalPages,
  handleSearch
}) => {


  return (
    <div className="flex items-center justify-between space-y-4 p-4 bg-white shadow-sm rounded-md md:flex-row md:space-y-0 md:items-center md:justify-between">
      <div className="flex items-center justify-between text-sm text-gray-6800 md:justify-start md:space-x-2">
        <span>
          Showing Page {currentPage} out of {totalPages}
        </span>
        <div className="ms-3 flex items-center justify-center space-x-4">
          <div
            className={`flex items-center justify-center space-x-1 ${
              currentPage === 1 ? "text-gray-400 cursor-not-allowed" : ""
            }`}
          >
            <IoIosArrowBack className="text-lg" />
            <button
              onClick={() => setCurrentPage((prev:any) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          </div>
          <div
            className={`flex items-center justify-center${
              currentPage === totalPages
                ? "text-gray-400 cursor-not-allowed"
                : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prev:any) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              Next
            </button>
            <IoIosArrowForward className="text-lg" />
          </div>
        </div>
      </div>

      <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-4">
        <div className="flex items-center border rounded-md px-3 py-2 w-full lg:w-80">
          <CiSearch className="text-gray-500 text-lg mr-2" />
          <input
            type="text"
            placeholder="Search by receiver name"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            className="border-none outline-none w-full text-sm text-gray-700 placeholder-gray-500"
          />
        </div>

        
        <button
          onClick={handleSearch}
          className="bg-[#005879] text-gray-100 border rounded-md p-2 text-sm w-20 mt-5"
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default PaginationAndSearch;
