import Cookies from "js-cookie";
import axios from "./config";
import { jwtDecode } from "jwt-decode";

interface DecodedToken {
    address_id: any;
}

export function getHeader() {
  const token = Cookies.get("token") || "";
  const header = {
    Authorization: token,
  };
  return header;
}

const getUserInfo = () => {
  const token = Cookies.get("token");

  if (!token) {
    throw new Error("Authorization token not found");
  }

  return jwtDecode<DecodedToken>(token);
};

const saveAddress = async (params: any, data: any,id:any): Promise<any> => {


  const response = await axios.put<any>(
    `/nwc/update-address/${id}/`,
    data,
    {
      headers: getHeader(),
      params: params,
    }
  );
  return response.data;
};
export const addAddress = async ( data: any): Promise<any> => {
   const response = await axios.post<any>(
    `/nwc/add-address`,
    data,
    {
      headers: getHeader()
    }
  );
  return response.data;
};

export const placeOrder = async ( order_id:string,address_id:string): Promise<any> => {
   const response = await axios.put<any>(
    `/nwc/order/confirm/${order_id}/${address_id}`,
    {
      headers: getHeader()
    }
  );
  return response.data;
};

export default saveAddress;
