import React from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { CiSearch } from "react-icons/ci";

interface FilterAndPaginationProps {
  search: string;
  setSearch(search: string): void;
  dateRange: { from: string; to: string };
  setDateRange: React.Dispatch<
    React.SetStateAction<{ from: string; to: string }>
  >;
  status: string;
  setStatus(status: string): void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalPages: number;
  getTableData: (page: number | null) => void;
  handlePreviousPage: () => void;
  handleNextPage: () => void;
}

const FiltersAndPagination: React.FC<FilterAndPaginationProps> = ({
  search,
  setSearch,
  dateRange,
  setDateRange,
  status,
  setStatus,
  currentPage,
  setCurrentPage,
  totalPages,
  getTableData,
  handlePreviousPage,
  handleNextPage
}) => {
  return (
    <div className="flex flex-col space-y-4 p-4 bg-white shadow-sm rounded-md md:flex-row md:space-y-0 md:items-center md:justify-between">
            <p className="text-sm font-bold mx-4 sm:mx-5 lg:mx-0">Order History</p>
      <div className="flex items-center justify-between text-sm text-gray-6800 md:justify-start md:space-x-2 lg:space-x-4">
        <span>
          Showing Page {currentPage} out of {totalPages}
        </span>
        <div className="ml-3 flex items-center justify-center space-x-4">
          <div
            className={`flex items-center justify-center space-x-2 ${
              currentPage === 1 ? "text-gray-400 cursor-not-allowed" : ""
            }`}
            onClick={handlePreviousPage}
          >
            <IoIosArrowBack className="text-lg" />
            <button>Previous</button>
          </div>
          <div
            className={`flex items-center justify-center space-x-2 ${
              currentPage === totalPages
                ? "text-gray-400 cursor-not-allowed"
                : ""
            }`}
            onClick={handleNextPage}
          >
            <button>Next</button>
            <IoIosArrowForward className="text-lg" />
          </div>
        </div>
      </div>

      <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-4">
        <div className="flex items-center border rounded-md px-3 py-2 w-full lg:w-60">
          <CiSearch className="text-gray-500 text-lg mr-2" />
          <input
            type="text"
            placeholder="Search  "
            value={search}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                getTableData(1); 
              }
            }}
            onChange={(e) => setSearch(e.target.value)}
            className="border-none outline-none w-full text-sm text-gray-700 placeholder-gray-500"
          />
        </div>

        <div className="flex items-center space-x-2">
          <input
            type="date"
            value={dateRange.from}
            onChange={(e) =>
              setDateRange((prev) => ({ ...prev, from: e.target.value }))
            }
            className="px-3 py-2 border rounded-md text-sm text-gray-700 focus:ring-2 outline-none"
          />
          <span className="text-gray-500">-</span>
          <input
            type="date"
          
            value={dateRange.to}
            onChange={(e) =>
              setDateRange((prev) => ({ ...prev, to: e.target.value }))
            }
            className="px-3 py-2 border rounded-md text-sm text-gray-700 focus:ring-2 outline-none"
          />
        </div>
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="px-3 py-2 border rounded-sm text-sm text-gray-700 focus:ring-2 outline-none"
        >
          <option value="">Status</option>
          <option value="PLACED">Placed</option>
          <option value="CONFIRMED">Confirmed</option>
          <option value="SHIPPED">Shipped</option>
          <option value="DELIVERED">Delivered</option>
          <option value="INVOICED">Invoiced</option>
          <option value="PENDING">Pending</option>
        </select>
        <button
          onClick={() => getTableData(1)}
          className="bg-[#005879] text-gray-100 border rounded-md p-2 text-sm w-20 mt-5"
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default FiltersAndPagination;
