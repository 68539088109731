import React from "react";
import { QRCodeCanvas } from "qrcode.react";

// const qrLink = "https://www.google.in"; // URL to open when scanned
import { Link } from "react-router-dom";

const qrLink = "https://staging-backoffice.sfa.berain.com.sa/survey"; // URL to open when scanned
// https://staging-backoffice.sfa.berain.com.sa/

// for staging 
const DynamicPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center mt-8">
      <h1 className="text-2xl font-bold">Scan the QR Code.</h1>

      {/* QR Code Display */}
      <QRCodeCanvas value={qrLink} size={200} />

      {/* <p className="text-sm text-gray-600 mt-2">{qrLink}</p> */}

      {/* Direct Link to Open Google */}
    
    </div>
  );
};

export default DynamicPage;
