const ROLE_GROUPS = {
    ZAMZAM_ADMIN: ["NWC_Admin"],
    ZAMZAM_INVENTORY:["NWC_Inventory"],
    ZAMZAM_ORDER:["NWC_Order"],
    ZAMZAM_FINANCE:["NWC_Finance"],
    DELIVERY_DRIVER: ["Delivery Driver"],
    PRESELLER_SALESMAN_SUPERVISOR: [
        "Supervisor"
      ],
  };


export default ROLE_GROUPS;