import React, { useState, useEffect } from "react";
import Layerzamzam from "./Layerzamzam";
import NewOrder from "./components/NewOrder";
import FetchedData from "./components/FetchedData";
import { createOrder, confirmOrder, productList } from "./api/createOrder";
import ConfirmPoup from "./components/ConfirmPoup";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import URL_MAPPING from "../../routes/constants";
import { getAddressDropdown } from "./api/createOrder";
interface WarehouseData {
  map(
    arg0: (warehouse: any, index: any) => React.JSX.Element
  ): React.ReactNode | Iterable<React.ReactNode>;
  items: Warehouse[];
}
interface Warehouse {
  MaterialId: string;
  MaterialDesc: string;
  unit: string;
  available_stocks: number;
  sales_quantity: number;
}

const CreateOrderZamZam = () => {
  const [showTable, setShowTable] = useState(false);
  const [showSummesion, setShowSummesion] = useState(false);
  const [wareHouseId, setWareHosueId] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [showPopup, setShowPopup] = useState(false);
  const [orderId, setOrderId] = useState();
  const navigate = useNavigate();

  const toggleTableToShow = () => {
    if (!wareHouseId) {
      toast.error("No value selected for Shipment Point");
      return;
    }


    setShowTable(true);
    if (!showTable) {
      getProductOptions(wareHouseId);
    }
  };
  const handleClear = () => {
    setSelectedAddress("");
    setShowTable(false);
    setShowSummesion(false);
    setLoading(true);
    setWareHosueId("");
  };
  const toggleSummesionToHide = () => setShowSummesion(!showSummesion);
  const [addressOptions, setAddressOptions] = useState<any>();
  const [salesQuantities, setSalesQuantities] = useState<
    { productId: number; quantity: number }[]
  >([]);
  const [warehouseData, setWarehouseData] = useState<WarehouseData | null>(
    null
  );

  const getProductOptions = async (id: string) => {
    await productList(id)
      .then((res) => {
        setWarehouseData(res.results[0].NavStockToItem);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectoption = (e: any) => {
    setWareHosueId(e.target.value);
    setShowTable(false);
  };

  const createInvovice = async () => {
    let data = {
      addressId: String(selectedAddress),
      orderDetails: salesQuantities,
    };
    await createOrder(data)
      .then((res: any) => {
        setShowPopup(true);
        setOrderId(res.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const orderConfirm = async () => {
    await confirmOrder(orderId, selectedAddress)
      .then((res: any) => {
        setShowPopup(false);
        setTimeout(() => {
          navigate(URL_MAPPING.ORDERHISTORY);
        }, 1000);
      })
      .catch((error) => {
        toast.error("Error!");
        console.log(error);
        setShowPopup(false);
      });
  };

  const fetchAddresses = async () => {
    setLoading(true);
    try {
      const response = await getAddressDropdown({});
      setAddressOptions(response.items);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  return (
    <div>
      <Layerzamzam>
        <div className="bg-gray-50 sm:pl-0 pr-4">
          <ToastContainer />
          <NewOrder
            toggleTableToShow={toggleTableToShow}
            handleClear={handleClear}
            showTable={showTable}
            showSummesion={showSummesion}
            toggleSummesion={toggleSummesionToHide}
            loading={loading}
            handleSelectoption={handleSelectoption}
            wareHouseId={wareHouseId}
          />
          {showTable && (
            <FetchedData
              toggleSummesion={toggleSummesionToHide}
              showSummesion={showSummesion}
              loading={loading}
              setLoading={setLoading}
              warehouseData={warehouseData}
              createInvovice={createInvovice}
              setSalesQuantities={setSalesQuantities}
              salesQuantities={salesQuantities}
              setSelectedAddress={setSelectedAddress}
              selectedAddress={selectedAddress}
              fetchAddresses={fetchAddresses}
              addressOptions={addressOptions}
            />
          )}
          {showPopup && (
            <ConfirmPoup orderId={orderId} handleContinue={orderConfirm} />
          )}
        </div>
      </Layerzamzam>
    </div>
  );
};

export default CreateOrderZamZam;
