import Dropdown from "../components/Dropdown";
import print from "../assets/printer.svg";
import exportpng from "../assets/Export.svg";
import CustomDatePicker from "../components/CustomeDatePicker";

interface FinanceDropdownProps {
  toggleTableToShow: () => void;
  toggleTableToHide: () => void;
  showTable: boolean;
  showSummesion: boolean;
  toggleSummesion: () => void;
  loading: boolean;
  monthDropdown: {
    isOpen: boolean;
    selectedOption: string | null;
  };
  setMonthDropdown: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      selectedOption: string | null;
    }>
  >;
  monthsOptions: Array<{ warehouseId: string; warehouseName: string }>;
  selectedYear: Date | null;
  setSelectedYear: React.Dispatch<React.SetStateAction<Date | null>>;
  yearsOptions: Array<{ warehouseId: string; warehouseName: string }>;
  exportData: () => void;
  printData: () => void;
}

const FinanceDropdown: React.FC<FinanceDropdownProps> = ({
  toggleTableToShow,
  toggleTableToHide,
  showTable,
  showSummesion,
  toggleSummesion,
  loading,
  setMonthDropdown,
  monthDropdown,
  monthsOptions,
  selectedYear,
  setSelectedYear,
  yearsOptions,
  exportData,
  printData,
}) => {
  return (
    <div className="sticky top-0 left-0 z-30 w-full">
      <p className="text-sm font-bold mx-4 sm:mx-5 lg:mx-0">Customer Statement Account</p>
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between bg-gray-200 py-3 px-4 mt-3 border rounded-md w-full shadow-sm gap-4">
        <div className="flex flex-wrap justify-left lg:flex-nowrap items-center gap-4 w-full lg:w-[60%]">
          <Dropdown
            title="Select a Month"
            options={monthsOptions}
            label="Month"
            isOpen={monthDropdown.isOpen}
            selectedOption={monthDropdown.selectedOption}
            onToggle={() =>
              setMonthDropdown((prev) => ({
                ...prev,
                isOpen: !prev.isOpen,
              }))
            }
            onOptionChange={(id) =>
              setMonthDropdown({ isOpen: false, selectedOption: id })
            }
            onClose={() =>
              setMonthDropdown((prev) => ({ ...prev, isOpen: false }))
            }
            section="finance"
          />
          <CustomDatePicker
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
          />
          <div className="w-full sm:w-auto">
            {showTable ? (
              <button
                onClick={toggleTableToHide}
                className="w-full sm:w-24 bg-[#005879] text-gray-100 border rounded-md py-2 text-xs hover:bg-[#00465f] focus:outline-none focus:ring focus:ring-blue-300 lg:mt-6 sm:mt-0"
              >
                Clear Data
              </button>
            ) : (
              <button
                onClick={toggleTableToShow}
                className="w-full sm:w-28 bg-[#005879] text-gray-100 border rounded-md py-2 text-xs hover:bg-[#00465f] focus:outline-none focus:ring focus:ring-blue-300 lg:mt-6 sm-mt-0"
              >
                View Statement
              </button>
            )}
          </div>
        </div>

        <div className="flex flex-wrap items-center justify-center lg:justify-end gap-4 w-full lg:w-auto lg:mt-5 sm:mt-0">
          <button
            onClick={exportData}
            className="w-full sm:w-24 border border-[#1dbda1] text-[#1dbda1] bg-transparent rounded-md py-2 text-xs hover:bg-[#e6f7f4] focus:outline-none focus:ring focus:ring-teal-300"
          >
            <div className="flex items-center justify-center w-full">
              <img className="me-2" src={exportpng} alt="" />
              Export
            </div>
          </button>
          <button
            onClick={printData}
            className="w-full sm:w-24 bg-[#1dbda1] text-gray-100 rounded-md py-2 text-xs hover:bg-[#17a488] focus:outline-none focus:ring focus:ring-teal-300"
          >
            <div className="flex items-center justify-center w-full">
              <img className="me-2" src={print} alt="" />
              Print
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinanceDropdown;
