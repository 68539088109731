import { TableColumn } from "../Pages/ComponentToPrint";
import logo from "../../../assets/main-logo.png";
import nwc from "../../../assets/nwc.jpg";
import { InvoiceData } from "../OrderHistory";
export const metaData = {
  date: new Date(Date.now()).toLocaleString(),
  logoSrc: logo,
  logoSrcnwc: nwc,
  title: "Inventory Report",
};

export const tableHeaders: TableColumn[] = [
  { header: "Warehouse Name", key: "warehouse_name" },
  { header: "Production Id", key: "production_id" },
  { header: "Description", key: "description" },
  { header: "Unit", key: "unit" },
  {
    header: "Available Stocks",
    key: "available_stocks",
    formatter: (value) => value.toLocaleString(),
  },
  {
    header: "In Quantity",
    key: "in_quantity",
    formatter: (value) => value.toLocaleString(),
  },
  {
    header: "Blocked",
    key: "blocked",
    formatter: (value) => value.toLocaleString(),
  },
  {
    header: "Returns",
    key: "returns",
    formatter: (value) => value.toLocaleString(),
  },
  {
    header: "In Transits",
    key: "in_transits",
    formatter: (value) => value.toLocaleString(),
  },
];

export const financeTableHeaders: TableColumn[] = [
  { header: "Transaction Type", key: "transaction_type" },
  { header: "Transaction Date", key: "transaction_date" },
  { header: "Transaction Time", key: "transaction_time" },
  { header: "Document Number", key: "document_number" },
  {
    header: "Credit (SAR)",
    key: "credit",
    formatter: (value) => value.toLocaleString(),
  },
  {
    header: "Debit (SAR)",
    key: "debit",
    formatter: (value) => value.toLocaleString(),
  },
  {
    header: "Carry Forward Balance (SAR)",
    key: "carry_forward_balance",
    formatter: (value) => value.toLocaleString(),
  },
];

export const financeMetaData = {
  date: new Date(Date.now()).toLocaleString(),
  logoSrc: logo,
  logoSrcnwc:nwc,
  title: "Customer Statement",
};

export const invoiceItemKeys: string[] = [
  // "Invoice No",
  "Item No",
  "Material No",
  "Material Desc En",
  "Material Desc Ar",
  // "Plant",
  // "Store Location",
  // "Batch",
  "Act Qty Del",
  "Uom",
  "Unit Price",
  "VAT Amount",
  "Net Value",
  "Gross Value",
  "Total",
  // "Material Desc En",
  // "Material Desc Ar",
  // "UoM Text",
];

export const invoiceKeyMap: Record<string, keyof InvoiceData> = {
  "Invoice No": "InvoiceNo",
  "Invoice Date": "InvoiceDate",
  "Invoice Time": "InvoiceTime",
  "Payer ID": "PayerId",
  "Payer Name En": "PayerNameEn",
  "Payer Name Ar": "PayerNameAr",
  // "Sales Office": "SalesOffice",
  // "Sales Office Text": "SalesOfficeText",
  // "Total Value": "TotalValue",
  // "Net Value": "NetValue",
  // "VAT": "VAT",
  "Country Code": "CountryCode",
  "Address": "Adressnr",
  "Street": "Street",
  "City": "City",
  "District": "District",
  "Building No": "BuildingNo",
  
  // "Region": "Regio",
  "Post Code": "PostCode",
  // "Terms": "Terms",
  "Term Desc": "TermDesc",
  "VAT No": "VATNo",
  "CR No": "CRNo",
  // "Amount to be paid": "Amounttobepaid",
  // "QR Code": "QRCode",

  "Additional No": "AdditionalNo",
  // "Gross Value": "GrossValue",
  "Order No": "OrderNo",
  // "Customer Id": "CustomerId",
  "Outlet Name": "OutletName",
  "Receiver Name": "ReceiverName",
  "Receiver Mobile": "ReceiverMobile",
};