import React, { useEffect, useState } from "react";
import Layerzamzam from "./Layerzamzam";
import OrderTable from "./components/OrderTable";
import FiltersAndPagination from "./components/FiltersAndPagination";
import { toast, ToastContainer } from "react-toastify";
import getOrderHistory, { getDeliveryModalData, getInvoiceModalData, getItemByOrderId } from "./api/orderHistory";
import { ModalOrderData } from "./components/Models";
import ComponentToPrintModal from "./Pages/ComponentToPrintModal";
import ComponentToPrintDelivery from "./Pages/ComponentToPrintDelivery";
import ComponentToPrintInvoice from "./Pages/ComponentToPrintInvoice";

export interface Order {
  SAPOrderId: string | null;
  addressId: string;
  invoices: Invoice[];
  orderCreatedDate: string;
  orderId: number;
  deliveries: Shipment[];
  status: string;
  address_title : string;
  addressTitle: string;
}

interface Invoice {
  invoiceDate: string;
  invoiceDetails: string;
  invoiceId: number;
  invoicePath: string;
  sap_invoice_id:number
}

interface Shipment {
  shipmentDate: string;
  shipmentDetails: string;
  shipmentId: number;
  sap_delivery_id: number;
  deliveryDate:string 
  shipmentStatus: string;
}

interface DeliveryItem {
  DeliveryNo: string;
  ItemCode: string;
  MaterialCode: string;
  MaterialTextAr: string;
  MaterialTextEn: string;
  Quantity: string;
  UoM: string;
  UoMText: string;
  pending_quantity:string;
}

export interface DeliveryData {
  ShipmentNo: string;
  DeliveryNo: string;
  OrderNo: string;
  OutletName: string;
  ReceiverName: string;
  Address: string;
  Customer: string;
  SFAOrderID: string;
  Items: DeliveryItem[];
}

interface InvoiceItem {
  InvoiceNo: string;
  Itemno: string;
  MaterialNo: string;
  Plant: string;
  StorLocation: string;
  Batch: string;
  ActQtyDel: string;
  Uom: string;
  unitPrice: string;
  VATAmount: string;
  NetValue: string;
  GrossValue: string;
  Total: string;
  MaterialDescEn: string;
  MaterialDescAr: string;
  UoMText: string;
}

export interface InvoiceData {
  InvoiceNo: string;
  InvoiceDate: string;
  InvoiceTime: string;
  PayerId: string;
  PayerNameEn: string;
  PayerNameAr: string;
  SalesOffice: string;
  SalesOfficeText: string;
  TotalValue: string;
  NetValue: string;
  VAT: string;
  CountryCode: string;
  Adressnr: string;
  Street: string;
  City: string;
  District: string;
  Regio: string;
  PostCode: string;
  Terms: string;
  TermDesc: string;
  VATNo: string;
  CRNo: string;
  Amounttobepaid: string;
  QRCode: string;
  QRCodeImage: string;
  BuildingNo: string;
  AdditionalNo: string;
  GrossValue: string;
  OrderNo: string;
  CustomerId: string;
  OutletName: string;
  ReceiverName: string;
  ReceiverMobile: string;
  NavInvoiceItems: { results: InvoiceItem[] };
}

const OrderHistory = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [dateRange, setDateRange] = useState({ from: "", to: "" });
  const [tableData, setTableData] = useState<Order[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<ModalOrderData | undefined>(
    undefined
  );
  const [deliveryModalData, setDeliveryModalData] = useState<DeliveryData | undefined>();
  const [printableData, setPrintableData] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [invoiceModalData, setInvoiceModalData] = useState<InvoiceData | undefined>();
  const [printInvoiceModal, setPrintInvoiceModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);

  const handleShowModal = async (orderId: number) => {
    if (orderId === null) {
      toast.error("Invalid order id");
      return;
    }
    try {
      const response = await getItemByOrderId(orderId);
      if (response.status === 200) {
        setModalData(response.data);
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error while fetching the data:", error);
    }
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  const getTableData = async (pageNumber: number | null) => {
    const paramsObject: {
      status?: string;
      startDate?: string;
      endDate?: string;
      searchText?: string;
    } = {};

    if (status) paramsObject.status = status;
    if (dateRange.from) paramsObject.startDate = dateRange.from;
    if (dateRange.to) paramsObject.endDate = dateRange.to;
    if (search) paramsObject.searchText = search;
    if (!pageNumber) pageNumber = 1;

    console.log(paramsObject, "Post Object");

    setLoading(true);

    try {
      const response = await getOrderHistory(paramsObject, pageNumber);
      if (response.status === 200) {
        const fetchedData = response.data;
        console.log(fetchedData, "Order History")
        setTableData(fetchedData.items);
        const totalPages = Math.ceil(response.data.total / response.data.size);
        setTotalPages(totalPages);
        console.log(fetchedData.page, "Total pages");
        setCurrentPage(fetchedData.page);
      } else {
        toast.error("Error: No response from server");
        console.error("Failed to fetch data, status code:", response.status);
      }
    } catch (error) {
      console.error("Error while fetching the data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleNextPage = async () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      await getTableData(nextPage);
    }
  };

  const handlePreviousPage = async () => {
    if (currentPage > 1) {
      const previousPage = currentPage - 1;
      await getTableData(previousPage);
    }
  };

  const handleShowDeliveryModal = async(id: number) => {
    try {
      const response = await getDeliveryModalData(id);
      if (response.status === 200) {
        setDeliveryModalData(response.data[0]);
        setShowDeliveryModal(true);
        console.log(response.data[0], "<<<<<<<<< Delivery Data");
      }
    } catch (error) {
      toast.error("Error: No response from server");
    }
  };

  const handleShowInvoiceModal = async(id: number) => {
    try {
      const response = await getInvoiceModalData(id);
      if (response.status === 200) {
        setInvoiceModalData(response.data.d);
        setShowInvoiceModal(true);
        console.log(response.data.d, "<<<<<<<<< Delivery Data");
      }
    } catch (error) {
      toast.error("Error: No response from server");
    }
  };

  const handleHideDeliveryModal = () => {
    setShowDeliveryModal(false);
  };

  const handleHideInvoiceModal = () => {
    setShowInvoiceModal(false);
  };

  const handlePrintData = () => {
    setPrintInvoiceModal(!printInvoiceModal);
  };

  const printData = () => {
    setPrintableData(!printableData);
  };

  const printDelivery = () => {
    setPrintModal(!printModal)
  }

  useEffect(() => {
    if (!showInvoiceModal) {
      return;
    }
    const printContent = document.getElementById("print-content-invoice");
    if (!printContent) return;

    const newWindow = window.open("", "_blank", "width=800,height=600");
    if (newWindow) {
      printContent.classList.remove("hidden");
      printContent.classList.add("block");
      const link = newWindow.document.createElement("link");
      link.href =
        "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
      link.rel = "stylesheet";
      newWindow.document.head.appendChild(link);

      link.onload = () => {
        newWindow.document.body.innerHTML = `
          <html>
            <head>
              <title>Print</title>
            </head>
            <body>${printContent.outerHTML}</body>
          </html>
        `;

        newWindow.document.close();
        newWindow.focus();
        newWindow.print();
        newWindow.close();
        printContent.classList.remove("block");
        printContent.classList.add("hidden");
      };
    }
  }, [printInvoiceModal]);
  
  useEffect(() => {
    if (!showModal) {
      return;
    }
    const printContent = document.getElementById("print-content");
    if (!printContent) return;

    const newWindow = window.open("", "_blank", "width=800,height=600");
    if (newWindow) {
      printContent.classList.remove("hidden");
      printContent.classList.add("block");
      const link = newWindow.document.createElement("link");
      link.href =
        "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
      link.rel = "stylesheet";
      newWindow.document.head.appendChild(link);

      link.onload = () => {
        newWindow.document.body.innerHTML = `
          <html>
            <head>
              <title>Print</title>
            </head>
            <body>${printContent.outerHTML}</body>
          </html>
        `;

        newWindow.document.close();
        newWindow.focus();
        newWindow.print();
        newWindow.close();
        printContent.classList.remove("block");
        printContent.classList.add("hidden");
      };
    }
  }, [printableData]);

  useEffect(() => {
    if (!showDeliveryModal) {
      return;
    }
    const printContent = document.getElementById("print-content-delivery");
    if (!printContent) return;

    const newWindow = window.open("", "_blank", "width=800,height=600");
    if (newWindow) {
      printContent.classList.remove("hidden");
      printContent.classList.add("block");
      const link = newWindow.document.createElement("link");
      link.href =
        "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
      link.rel = "stylesheet";
      newWindow.document.head.appendChild(link);

      link.onload = () => {
        newWindow.document.body.innerHTML = `
          <html>
            <head>
              <title>Print</title>
            </head>
            <body>${printContent.outerHTML}</body>
          </html>
        `;

        newWindow.document.close();
        newWindow.focus();
        newWindow.print();
        newWindow.close();
        printContent.classList.remove("block");
        printContent.classList.add("hidden");
      };
    }
  }, [printModal]);

  useEffect(() => {
    getTableData(1);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getTableData(1);
    };
  
    fetchData();
  }, [search]);
  
  return (
    <Layerzamzam>
      <ToastContainer />
      <FiltersAndPagination
        search={search}
        setSearch={setSearch}
        dateRange={dateRange}
        setDateRange={setDateRange}
        status={status}
        setStatus={setStatus}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        getTableData={getTableData}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
      />
      <OrderTable
        tableData={tableData}
        loading={loading}
        setLoading={setLoading}
        handleShowModal={handleShowModal}
        handleHideModal={handleHideModal}
        showModal={showModal}
        data={modalData}
        printData={printData}
        handleShowDeliveryModal={handleShowDeliveryModal}
        showDeliveryModal={showDeliveryModal}
        deliveryModalData={deliveryModalData}
        handleHideDeliveryModal={handleHideDeliveryModal}
        printDelivery={printDelivery}
        handleShowInvoiceModal={handleShowInvoiceModal}
        invoiceModalData={invoiceModalData}
        showInvoiceModal={showInvoiceModal}
        handleHideInvoiceModal={handleHideInvoiceModal}
        handlePrintData={handlePrintData}
      />
      <ComponentToPrintModal data={modalData} />
      <ComponentToPrintDelivery data={deliveryModalData} />
      <ComponentToPrintInvoice data={invoiceModalData} />
    </Layerzamzam>
  );
};

export default OrderHistory;
  