import React from "react";
import { InvoiceData } from "../OrderHistory";
import logo from "../../../assets/main-logo.png";
import nwc from "../../../assets/nwc.jpg";
import { invoiceItemKeys, invoiceKeyMap } from "../constants";

const metadata = {
  date: new Date(Date.now()).toLocaleString(),
  logoSrc: logo,
  logoSrcnwc: nwc,
  title: "Invoice Report",
};

interface InvoiceModalProps {
  data: InvoiceData | undefined;
}

const ComponentToPrintInvoice: React.FC<InvoiceModalProps> = ({ data }) => {
  if (!data) return null;

  function convertToMonth(monthString: number | null): string {
    if (!monthString) {
      return "Invalid month";
    }

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthIndex = month;
    if (monthIndex >= 0 && monthIndex < 12) {
      return monthNames[monthIndex];
    } else {
      return "Invalid month";
    }
  }

  const month = new Date(Date.now()).getMonth();
  const year = new Date(Date.now()).getFullYear();

  return (
    <div id="print-content-invoice" className="hidden print:block">
      <div className="flex justify-between items-center mb-6">
        <div className="flex flex-col gap-2 text-8">
          <p>Date: {metadata?.date || "-"}</p>
    
            <div className="flex item center space-x-2">
              <img src={logo} alt="Logo" className="h-16" />
              <img
                src={nwc}
                alt="NWC Logo"
                className="h-16 self-start"
              />
                {data.QRCodeImage && (
            <div className="mt-1 ">
              <img
                src={data.QRCodeImage}
                alt="QR Code"
                className="w-24 h-24 sm:w-32 sm:h-32"
              />
            </div>
          )}
            </div>
       

        
        </div>
        <div className="text-center">
          <h1 className="text-2xl font-bold">
            {metadata?.title || "Dynamic Report"}
          </h1>
        </div>
        <div className="max-w-[50px]">
          <p className="text-sm">
            <b>Month : </b>
            {convertToMonth(month)}
          </p>
          <p className="text-sm">
            <b>Year: </b>
            {year}
          </p>
        </div>
      </div>
      <div className="p-4 space-y-4 rounded-lg text-xs sm:text-sm">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          {Object.entries(invoiceKeyMap).map(([label, dataKey]) => {
            const value = data[dataKey];

            return (
              <div key={label} className="flex justify-between border-b pb-2">
                <span className="font-semibold text-gray-700">{label}:</span>
                <span className="text-gray-600">
                  {typeof value === "object"
                    ? JSON.stringify(value)
                    : value || "-"}
                </span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="w-[100vw] mt-6">
        <table className="w-full min-w-max border-collapse text-xs sm:text-sm">
          <thead className="bg-gray-100">
            <tr>
              {invoiceItemKeys.map((key) => (
                <th
                  key={key}
                  className="px-2 sm:px-4 py-2 text-left text-gray-800 font-semibold"
                >
                  {key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
              {data.NavInvoiceItems.results.map((item, index) => {
                // Define the correct order
                const order = [
                  "Itemno",
                  "MaterialNo",
                  "MaterialDescEn", // Move after MaterialNo
                  "MaterialDescAr", // Move after MaterialNo
                  "ActQtyDel",
                  "Uom",
                  "unitPrice",
                  "VATAmount",
                  "NetValue",
                  "GrossValue",
                  "Total"
                ];

                // Sort object entries based on the defined order
                const sortedEntries = Object.entries(item)
                  .filter(([key]) => order.includes(key)) // Only keep relevant keys
                  .sort(([keyA], [keyB]) => order.indexOf(keyA) - order.indexOf(keyB));

                return (
                  <tr key={index} className="even:bg-gray-50">
                    {sortedEntries.map(([_, value], idx) => (
                      <td key={idx} className="px-2 sm:px-4 py-2 text-gray-700">
                        {value || "-"}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          <thead className="bg-gray-200 text-gray-900">
            <tr>
              <th className="px-4 py-3 text-gray-900 border text-left text-xs font-bold">
                Total
              </th>
              <th className="px-4 py-3 border text-left text-xs font-bold"></th>
              <th className="px-4 py-3 border text-left text-xs font-bold"></th>
              <th className="px-4 py-3 border text-left text-xs font-bold"></th>
              <th className="px-4 py-3 text-gray-900 border text-left text-xs font-bold"></th>
              <th className="px-4 py-3 text-gray-900 border text-left text-xs font-bold"></th>
              <th className="px-4 py-3 text-gray-900 border text-left text-xs font-bold"></th>
              <th className="px-4 py-3 text-gray-900 border text-left text-xs font-bold">
                {" "}
                {data.VAT}
              </th>
              <th className="px-4 py-3 text-gray-900 border text-left text-xs font-bold">
                {data.NetValue}
              </th>
              <th className="px-4 py-3 text-gray-900 border text-left text-xs font-bold">
                {data.GrossValue}
              </th>
              <th className="px-4 py-3 text-gray-900 border text-left text-xs font-bold">
                {data.TotalValue}
              </th>
              {/* <th className="px-4 py-3 text-gray-700 border text-left text-xs font-bold"></th> */}
              {/* <th className="px-4 py-3 text-gray-700 border text-left text-xs font-bold"></th> */}
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
};

export default ComponentToPrintInvoice;
