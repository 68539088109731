import React, { useEffect, useState } from "react";
import Layerzamzam from "./Layerzamzam";
import FinanceDropdown from "./Pages/FinanceDropdown";
import FinanceTable from "./components/FinanceTable";
import AccountBalance from "./components/AccountBalance";
import Loader from "../../components/Loader";
import { toast, ToastContainer } from "react-toastify";
import ComponentToPrintFinance from "./Pages/ComponentToPrintFinance";
import { financeTableHeaders, financeMetaData } from "./constants";
import { getDataTobePrinted, getExportData, getTableData } from "./api/finance";

interface Month {
  warehouseId: string;
  warehouseName: string;
}
interface Transaction {
  transaction_type: string;
  transaction_date: string;
  transaction_time: string;
  document_no: string;
  debit: number;
  credit: number;
  carry_forward_balance: number;
}

export interface TableData {
  opening_balance: number;
  closing_balance: number;
  payer_id: string;
  payer_name: string;
  vat: string;
  cr: string;
  total_debit: number;
  total_credit: number;
  transactions: Transaction[];
  total_carry_forward_balance: number;
}

const Finance = () => {
  const [showTable, setShowTable] = useState(false);
  const [showSummesion, setShowSummesion] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [monthsOptions, setMonthsOptions] = useState<Month[]>([]);
  const [yearsOptions, setYearsOptions] = useState<Month[]>([]);
  const [tableData, setTableData] = useState<TableData | null>(null);
  const [printableData, setPrintableData] = useState<TableData | null>(null);
  const [selectedYear, setSelectedYear] = useState<Date | null>(null);
  const [dataEmpty, setDataEmpty] = useState(false);

  const [monthDropdown, setMonthDropdown] = useState<{
    isOpen: boolean;
    selectedOption: string | null;
  }>({ isOpen: false, selectedOption: null });

  const toggleTableToShow = () => {
    getFinanceDropdown();
  };
  const toggleTableToHide = () => {
    setShowTable(false);
    setShowSummesion(false);
    setMonthDropdown({ isOpen: false, selectedOption: null });
    setSelectedYear(null);
    setLoading(true);
  };
  const toggleSummesionToShow = () => setShowSummesion(true);
  const toggleSummesionToHide = () => setShowSummesion(false);

  const exportData = async () => {
    if (selectedYear == null || monthDropdown.selectedOption == null) {
      toast.error("Error: Please Select Month and Year");
      return;
    }
    try {
      const response = await getExportData(
        monthDropdown.selectedOption,
        selectedYear.getFullYear()
      );
      if (response.status === 200) {
        const data = response.data;
        const url = window.URL.createObjectURL(data);
        const a = document.createElement("a");
        a.href = url;
        a.download = "finance_report.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } else {
        toast.error("No data available for selected month & year");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getPrintableData = async () => {
    if (selectedYear == null || monthDropdown.selectedOption == null) {
      toast.error("Error: Please Select Month and Year");
      return;
    }
    try {
      const response = await getDataTobePrinted(
        monthDropdown.selectedOption,
        selectedYear.getFullYear()
      );
      if (response.status === 200) {
        setPrintableData(response.data);
      } else {
        toast.error("No data available for selected month & year");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getFinanceDropdown = async () => {
    try {
      if (selectedYear == null || monthDropdown.selectedOption == null) {
        toast.error("Error: Please Select Month and Year");
        return;
      }
      setShowSpinner(true);
      const response = await getTableData(
        monthDropdown.selectedOption,
        selectedYear.getFullYear()
      );
      if (response.status === 200) {
        setTableData(response.data);
        setShowTable(true);
        setShowSpinner(false);
      } else {
        toast.error("No data available for selected month & year");
        setShowTable(false);
        setShowSpinner(false);
        setDataEmpty(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Internal Server Error");
      setShowTable(false);
      setShowSpinner(false);
      setDataEmpty(true);
    }
  };

  useEffect(() => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ].map((month, index) => ({
      warehouseId: String("0" + (index + 1)),
      warehouseName: month,
    }));
    setMonthsOptions(months);
  }, []);

  const printData = async () => {
    await getPrintableData();
  };

  useEffect(() => {
    if (!tableData || !showTable) {
      return;
    }

    console.log(showTable, "<<< Content of Printable Table");
    const printContent = document.getElementById("print-content");
    if (!printContent) return;

    const newWindow = window.open("", "_blank", "width=800,height=600");
    if (newWindow) {
      printContent.classList.remove("hidden");
      printContent.classList.add("block");
      const link = newWindow.document.createElement("link");
      link.href =
        "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
      link.rel = "stylesheet";
      newWindow.document.head.appendChild(link);

      link.onload = () => {
        newWindow.document.body.innerHTML = `
          <html>
            <head>
              <title>Print</title>
            </head>
            <body>${printContent.outerHTML}</body>
          </html>
        `;

        newWindow.document.close();
        newWindow.focus();
        newWindow.print();
        newWindow.close();
        printContent.classList.remove("block");
        printContent.classList.add("hidden");
      };
    }
  }, [printableData]);
  return (
    <Layerzamzam>
      <ToastContainer />
      <div className="bg-gray-50 sm:pl-0 lg:pr-4">
        <FinanceDropdown
          toggleTableToShow={toggleTableToShow}
          toggleTableToHide={toggleTableToHide}
          showTable={showTable}
          showSummesion={showSummesion}
          toggleSummesion={toggleSummesionToHide}
          loading={loading}
          monthDropdown={monthDropdown}
          setMonthDropdown={setMonthDropdown}
          monthsOptions={monthsOptions}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          yearsOptions={yearsOptions}
          exportData={exportData}
          printData={printData}
        />

        {showSpinner && !showTable && (
          <div className="mt-6">
            <Loader />
          </div>
        )}

        {showTable && <AccountBalance tableData={tableData} />}

        {showTable ? (
          <FinanceTable
            toggleSummesion={toggleSummesionToShow}
            showSummesion={showSummesion}
            loading={loading}
            setLoading={setLoading}
            tableData={tableData}
          />
        ) : (
          !showSpinner &&
          dataEmpty && <p className="text-center text-sm mt-3">No Data Found</p>
        )}
      </div>
      <div>
        <ComponentToPrintFinance
          columns={financeTableHeaders}
          tableData={printableData}
          metadata={financeMetaData}
          month={monthDropdown.selectedOption}
          year={selectedYear}
        />
      </div>
    </Layerzamzam>
  );
};

export default Finance;
