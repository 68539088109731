import React, { useEffect, useRef, useState } from "react";
import DownArrow from "../assets/down-arrow.svg";
import UpArrow from "../assets/up-arrow.svg";

interface DropdownProps {
  label?: string;
  options: { value: string | number; label: string }[];
  selectedValues: (string | number)[];
  onChange: (values: (string | number)[]) => void;
  placeholder?: string;
  searchable?: boolean;
  disabled?: boolean;
  onClose?: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  selectedValues,
  onChange,
  placeholder = "Select options",
  searchable = false,
  disabled = false,
  onClose,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        if (isDropdownOpen) {
          setIsDropdownOpen(false);
          if (onClose) onClose();
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isDropdownOpen, onClose]);

  const handleOptionClick = (value: string | number) => {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value];

    onChange(newSelectedValues);
  };

  return (
    <div
      ref={dropdownRef}
      className={`relative w-full ${
        disabled ? "opacity-50 cursor-not-allowed" : ""
      }`}
    >
      <label className="block text-10 text-gray-500 pb-1">{label}</label>
      <div
        className="h-10  bg-white flex justify-between items-center cursor-pointer border-2 border-gray-400 px-2 rounded"
        onClick={() => {
          if (!disabled) {
            setIsDropdownOpen((prevState) => {
              if (prevState && onClose) {
                onClose(); // Call onClose when closing the dropdown
              }
              return !prevState;
            });
          }
        }}
      >
        <span className="text-sm font-medium truncate">
          {selectedValues.length > 0
            ? selectedValues
                .slice(0, 1) // Show only the first 3 selected options
                .map(
                  (value) =>
                    options.find((option) => option.value === value)?.label ||
                    ""
                )
                .join(", ") +
              (selectedValues.length > 1
                ? `, +${selectedValues.length - 1} more`
                : "") // Add "+X more" if there are more than 3 options
            : placeholder}
        </span>
        <span>
          <img src={isDropdownOpen ? UpArrow : DownArrow} alt="" />
        </span>
      </div>
      {isDropdownOpen && (
        <div className="absolute max-h-[300px] overflow-auto top-full left-0 mt-1 w-full bg-white border rounded shadow-lg z-10">
          {searchable && (
            <div className="p-2">
              <input
                type="text"
                placeholder="Search..."
                className="w-full p-1 border rounded"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          )}
          <ul className="py-1">
            {options
              .filter((option) =>
                option.label.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((option) => (
                <li key={option.value}>
                  <button
                    className={`block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 ${
                      selectedValues.includes(option.value)
                        ? "font-semibold bg-gray-100"
                        : ""
                    }`}
                    onClick={() => handleOptionClick(option.value)}
                  >
                    {option.label}
                  </button>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
