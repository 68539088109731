import Cookies from "js-cookie";
import axios from "./config";
import { jwtDecode } from "jwt-decode";

interface WarehouseOption {
    warehouseId: string;  
  warehouseName: string;
}

interface ApiResponse {
  warehouses: WarehouseOption[];
}

interface ProductParams {
  warehouseId: any;
}

export function getHeader() {
  const token = Cookies.get("token") || "";
  const header = {
    Authorization: token,
  };
  return header;
}

// const getUserInfo = () => {
//   const token = Cookies.get("token");

//   if (!token) {
//     throw new Error("Authorization token not found");
//   }

//   return jwtDecode<DecodedToken>(token);
// };

const getShipmentDropdown = async (params: any): Promise<ApiResponse> => {
//   const user_info = getUserInfo();
  const response = await axios.get<ApiResponse>(
    `/nwc/warehouse`,
    {
      headers: getHeader(),
      params: params,
    }
  );
  return response.data;
};

export const getAvailableProducts = async (ProductParams: any): Promise<ApiResponse> => {
  //   const user_info = getUserInfo();
  const { warehouseId } = ProductParams
    const response = await axios.get<ApiResponse>(
      `/nwc/products/${warehouseId}`,
      {
        headers: getHeader(),
        params: ProductParams,
      }
    );
    return response.data;
  };
 
 export const getAddressDropdown = async (params: any):  Promise<any> => {
    // const user_info = getUserInfo();
    params.page_size=40
    var ok=params
    params = { ...params, ...ok };
    const response = await axios.post<any>(
      `/nwc/addresses/?page_number=1&page_size=50`,
      {
        headers: getHeader(),
        params: params,
      }
    );
    return response.data;
  };


  export const createOrder = async (data: any):  Promise<any> => {
    const response = await axios.post<any>(
      `/nwc/order`,
      data,
      {
        headers: getHeader(),
      }
    );
    return response.data;
  };


  export const confirmOrder = async (order_id: any,address_id:any):  Promise<any> => {
    const response = await axios.put<any>(
      `/nwc/order/confirm/${order_id}/${address_id}`,
      {
        headers: getHeader(),
      }
    );
    return response.data;
  };

  export const productList = async (id: any):  Promise<any> => {
    const response = await axios.get<any>(
      `nwc/inventory/${id}`,
      {
        headers: getHeader(),
      }
    );
    return response.data;
  };


  

  

export default getShipmentDropdown;


