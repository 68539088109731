import React from "react";
import { DeliveryData } from "../OrderHistory";
import print from "../assets/printer.svg";

interface DeliveryModalProps {
  data: DeliveryData | undefined;
  handleHideDeliveryModal: () => void;
  printDelivery: () => void;
}

const DeliveryModal: React.FC<DeliveryModalProps> = ({ data, handleHideDeliveryModal, printDelivery }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center p-4 z-50">
      <div className="relative bg-white rounded-lg shadow-xl w-full lg:w-[80%] p-6">
        <div className="flex justify-between items-center border-b pb-3">
          <h2 className="text-xl font-semibold text-gray-800">
            Delivery Details
          </h2>
          <div className="flex space-x-3 mr-4">
            <button onClick={handleHideDeliveryModal} className="text-gray-500 hover:text-gray-700 text-xl absolute top-1 right-3">
              &times;
            </button>
            <button onClick={printDelivery} className="bg-[#1dbda1] text-white rounded-md px-4 py-2 text-sm hover:bg-[#17a488] focus:outline-none flex items-center">
              <img className="mr-2" src={print} alt="Print" /> Print
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-700 mt-4">
          <p>
            <span className="font-medium">Shipment No:</span>{" "}
            {data?.ShipmentNo || "N/A"}
          </p>
          <p>
            <span className="font-medium">Delivery No:</span>{" "}
            {data?.DeliveryNo || "N/A"}
          </p>
          <p>
            <span className="font-medium">Order No:</span>{" "}
            {data?.OrderNo || "N/A"}
          </p>
          <p>
            <span className="font-medium">Outlet Name:</span>{" "}
            {data?.OutletName || "N/A"}
          </p>
          <p>
            <span className="font-medium">Receiver Name:</span>{" "}
            {data?.ReceiverName || "N/A"}
          </p>
          <p>
            <span className="font-medium">SFA Order ID:</span>{" "}
            {data?.SFAOrderID || "N/A"}
          </p>
          <p className="col-span-2">
            <span className="font-medium">Address:</span>{" "}
            {data?.Address || "N/A"}
          </p>
          <p>
            <span className="font-medium">Customer ID:</span>{" "}
            {data?.Customer || "N/A"}
          </p>
        </div>

        <div className="mt-4 border-t pt-2">
          <h3 className="text-lg font-semibold text-gray-800">Items</h3>
          {data?.Items && data?.Items?.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="w-full mt-2 border-collapse text-sm min-w-max">
                <thead>
                  <tr className="border-b bg-gray-100">
                    <th className="text-left font-medium text-gray-900 p-2">
                      Delivery No
                    </th>
                    <th className="text-left font-medium text-gray-900 p-2">
                      Item Code
                    </th>
                    <th className="text-left font-medium text-gray-900 p-2">
                      Material Code
                    </th>
                    <th className="text-left font-medium text-gray-900 p-2">
                      Material (EN)
                    </th>
                    <th className="text-left font-medium text-gray-900 p-2">
                      Material (AR)
                    </th>
                    <th className="text-left font-medium text-gray-900 p-2">
                      Quantity
                    </th>
                    <th className="text-left font-medium text-gray-900 p-2">
                      Remaining Order Quantity
                    </th>
                    <th className="text-left font-medium text-gray-900 p-2">
                      UoM
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.Items.map((item, index) => (
                    <tr key={index} className="border-b">
                      <td className="p-2">{item.DeliveryNo || "N/A"}</td>
                      <td className="p-2">{item.ItemCode || "N/A"}</td>
                      <td className="p-2">{item.MaterialCode || "N/A"}</td>
                      <td className="p-2">{item.MaterialTextEn || "N/A"}</td>
                      <td className="p-2">{item.MaterialTextAr || "N/A"}</td>
                      <td className="p-2">{item.Quantity || "0"}</td>
                      <td className="p-2">{item.pending_quantity || "N/A"}</td>
                      <td className="p-2">{item.UoMText || "N/A"}</td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-gray-500 mt-2">No items available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliveryModal;
