import React from "react";
import Write from "../assets/write.svg";
import { useTranslation } from "react-i18next";


const ConfirmPoup = ({orderId ,handleContinue}: { orderId: any; handleContinue: any }) => {
  const { t } = useTranslation();
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-2xl shadow-xl p-6 w-96 ">
        <div className="w-full flex items-center justify-center p-4">
          <img src={Write} alt="img" />
        </div>
        <h2 className="text-xl font-bold text-center mb-4">
        {t("createOrder.Order_Success")}
        </h2>

        <div className="text-center mb-6">
          <span className="text-gray-500">Order ID:</span>{" "}
          <span className="font-medium">{orderId}</span>
        </div>
        <div className="items-center flex justify-center">
          <button
            onClick={handleContinue}
            className="w-[40%] bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded "
          >
            {t("createOrder.Continue")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPoup;
