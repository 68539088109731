import React, { useEffect, useState } from "react";
import icon from "../assets/summesion.png";
import Loader from "../../../components/Loader";
import { TableData } from "../Finance";

interface FinanceTableDataProps {
  toggleSummesion: () => void;
  showSummesion: boolean;
  loading: boolean;
  setLoading: (value: boolean) => void;
  tableData: TableData | null;
}

interface FinanceSummesion {
  total_credit: number;
  total_debit: number;
  total_carry_forward_balance: number;
}

const FinanceTable: React.FC<FinanceTableDataProps> = ({
  toggleSummesion,
  showSummesion,
  loading,
  setLoading,
  tableData,
}) => {
  const [datasummesion, setDataSummesion] = useState<FinanceSummesion>();

  useEffect(() => {
    const totalSums = tableData?.transactions?.reduce(
      (totals, item) => {
        return {
          total_credit: totals.total_credit + item.credit,
          total_debit: totals.total_debit + item.debit,
          total_carryForwardBalance:
            totals.total_carryForwardBalance + item.carry_forward_balance,
        };
      },
      { total_credit: 0, total_debit: 0, total_carryForwardBalance: 0 }
    );
    // setDataSummesion(totalSums);
  }, []);

  return (
    <div className="bg-gray-50 pt-3">
      <div className="bg-white shadow-sm rounded-md overflow-hidden">
        <div
          className="h-[36vh] overflow-y-auto scrollbar-hidden"
          style={{
            WebkitOverflowScrolling: "touch",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <table
            className="min-w-full bg-white border-none border-black-200"
            style={{ borderSpacing: "0px 0px", borderCollapse: "separate" }}
          >
            <thead className="bg-[#E8F6FC] sticky top-0 z-10">
              <tr>
                <th className="px-4 py-3 border text-left text-xs font-semibold text-black-500 whitespace-nowrap">
                  Transcation Type
                </th>
                <th className="px-4 py-3 border text-left text-xs font-semibold text-black-500">
                  Transcation Date
                </th>
                <th className="px-4 py-3 border text-left text-xs font-semibold text-black-500">
                  Transcation Time
                </th>
                <th className="px-4 py-3 border text-left text-xs font-semibold text-black-500">
                  Document Number
                </th>
                <th className="px-2 py-3 border text-left text-xs font-semibold text-black-500">
                  <div className="flex items-center space-x-4">
                    <p className="text-xs">Credit (SAR)</p>
                    <img
                      onClick={toggleSummesion}
                      className="cursor-pointer"
                      src={icon}
                      alt="summesion"
                    />
                  </div>
                </th>
                <th className="px-4 py-3 border text-left text-xs font-semibold text-black-500">
                  <div className="flex items-center space-x-4">
                    <p className="text-xs">Debit (SAR)</p>
                    <img
                      onClick={toggleSummesion}
                      className="cursor-pointer"
                      src={icon}
                      alt="summesion"
                    />
                  </div>
                </th>
                <th className="px-4 py-3 border text-left text-xs font-semibold text-black-500">
                  <div className="flex items-center space-x-4">
                    <p className="text-xs">Carry forward balance (SAR)</p>
                    <img
                      onClick={toggleSummesion}
                      className="cursor-pointer"
                      src={icon}
                      alt="summesion"
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData && tableData.transactions?.length > 0 ? (
                tableData.transactions.map((items, index) => (
                  <tr key={index} className="text-xs hover:bg-gray-50">
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {items.transaction_type || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {items.transaction_date || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {items.transaction_time || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {items.document_no || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {items.credit || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {items.debit || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {items.carry_forward_balance || 0}
                    </td>
                  </tr>
                ))
              ) : loading ? (
                <tr>
                  <td colSpan={7} className="h-40 text-center align-middle">
                    <div className="flex justify-center items-center h-full">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={9} className="text-center py-4 text-xs">
                    No results found
                  </td>
                </tr>
              )}
            </tbody>
            <thead className="bg-[#005879] sticky bottom-0 z-10 text-gray-100">
              <tr>
                <th className="px-4 py-3 border text-left text-xs font-bold">
                  Total
                </th>
                <th className="px-4 py-3 border text-left text-xs font-bold"></th>
                <th className="px-4 py-3 border text-left text-xs font-bold"></th>
                <th className="px-4 py-3 border text-left text-xs font-bold"></th>
                <th className="px-4 py-3 border text-left text-xs font-bold">
                  {tableData?.total_credit}
                </th>
                <th className="px-4 py-3 border text-left text-xs font-bold">
                  {tableData?.total_debit}
                </th>
                <th className="px-4 py-3 border text-left text-xs font-bold">
                  {tableData?.total_carry_forward_balance}
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FinanceTable;
