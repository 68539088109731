import React, { useState, useEffect } from "react";
import Layerzamzam from "./Layerzamzam";
import AddressTable from "./components/AddressTable";
import PaginationAndSearch from "./components/PaginationAndSearch";
import getAddressTable from "./api/manageAddressTable";

const ManageAddr = () => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const toggeleLoding = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const [address, setAddressData] = useState<any>([]);
  const [totalPages, settotalPages] = useState<any>(0);

  const getAddressData = async (param: any, data: any) => {
    setLoading(true);
    await getAddressTable(param, data)
      .then((res) => {
        setLoading(false);
        setAddressData(res.items);
        const totalPages = Math.ceil(res.total / res.size);
        settotalPages(totalPages);
      })
      .catch((error) => {
        console.error("Error fetching Address data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const param = {
      page_number: currentPage,
      page_size: 20,
    };
    const data: any = {};
    if (search) {
      data["search"] = search;
    }
    getAddressData(param, data);
  }, [currentPage]);

  const handleSearch = () => {
    setCurrentPage(1);
    const param = {
      page_number: 1,
      page_size: 20,
    };
    const data: any = {};

    if (search) {
      data["search"] = search;
    }
    getAddressData(param, data);
  };

    useEffect(() => {
      const fetchData = async () => {
        await handleSearch();
      };
    
      fetchData();
    }, [search]);
  
  return (
    <div>
      <Layerzamzam>
        <p className="block text-lg font-bold text-gray-700">Manage Address</p>
        <PaginationAndSearch
          toggeleLoding={toggeleLoding}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setSearch={setSearch}
          search={search}
          totalPages={totalPages}
          handleSearch={handleSearch}
        />
        <AddressTable
          loading={loading}
          setLoading={setLoading}
          currentPage={currentPage}
          address={address}
          getAddressData={getAddressData}
        />
      </Layerzamzam>
    </div>
  );
};

export default ManageAddr;
