import axios from "./config";

const getOrderHistory = async (data: any, pageNumber: any): Promise<any> => {
  const response = await axios.post<any>(
    `/nwc/orders/history?page=${pageNumber}&size=20`,
    data
  );
  return response;
};

export const getItemByOrderId = async (orderId: Number): Promise<any> => {
  const response = await axios.get<any>(`nwc/order/${orderId}`);
  return response;
};

export const getDeliveryModalData = async (orderId: Number): Promise<any> => {
  const response = await axios.get<any>(`nwc/delivery-details/${orderId}`);
  return response;
};

export const getInvoiceModalData = async (invoiceId: Number): Promise<any> => {
  const response = await axios.get<any>(`/nwc/inventory/details/2005008897`);
  return response;
};

export default getOrderHistory;
