import React from "react";
import logo from "../../../assets/main-logo.png";
import nwc from "../../../assets/nwc.jpg";

const metadata = {
  date: new Date(Date.now()).toLocaleString(),
  logoSrc: logo,
  logoSrcnwc: nwc,
  title: "Order Report",
};

interface Item {
  item_code: string | null;
  product_id: string;
  quantity: number;
}

interface Shipment {
  shipment_id: number;
  status: string;
  shimpment_date: string;
}

interface Invoice {
  invoice_id: number;
  invoice_details: string | null;
  invoice_path: string;
  invoice_date: string;
}

export interface ModalOrderData {
  sfa_order_id: string;
  status: string;
  customer_id: string;
  address_id: string;
  items: Item[];
  shipments: Shipment[];
  invoices: Invoice[];
}

interface ModalProps {
  data: ModalOrderData | undefined;
}

const ComponentToPrintModal: React.FC<ModalProps> = ({ data }) => {
  const month = new Date();
  const year = new Date();

  function convertToMonth(monthString: number | null): string {
    if (!monthString) {
      return "Invalid month";
    }

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthIndex = monthString - 1;
    if (monthIndex >= 0 && monthIndex < 12) {
      return monthNames[monthIndex];
    } else {
      return "Invalid month";
    }
  }

  return (
    <div id="print-content" className="hidden print:block">
      <div className="flex justify-between items-center mb-6">
        <div className="flex flex-col gap-2 text-8">
          <p>Date: {metadata?.date || "-"}</p>
          {metadata?.logoSrc && (
            <div className="flex item center space-x-2">
              <img src={metadata.logoSrc} alt="Logo" className="h-16" />
              <img
                src={metadata.logoSrcnwc}
                alt="NWC Logo"
                className="h-16 self-start"
              />
            </div>
          )}
        </div>
        <div className="text-center">
          <h1 className="text-2xl font-bold">
            {metadata?.title || "Dynamic Report"}
          </h1>
        </div>
        <div className="max-w-[50px]">
          <p className="text-sm">
            <b>Month : </b>
            {convertToMonth(month.getMonth() + 1)}
          </p>
          <p className="text-sm">
            <b>Year: </b>
            {year.getFullYear()}
          </p>
        </div>
      </div>
      <div className="mt-4 text-sm flex justify-between">
        <div className="flex flex-col">
          <p>
            <strong>Order ID:</strong> {data?.sfa_order_id}
          </p>
          <p>
            <strong>Status:</strong> {data?.status}
          </p>
        </div>
        <div className="flex flex-col text-right">
          <p>
            <strong>Customer ID:</strong> {data?.customer_id}
          </p>
          <p>
            <strong>Address ID:</strong> {data?.address_id}
          </p>
        </div>
      </div>

      <div className="mt-6">
        <h3 className="text-md font-semibold border-b pb-2">Invoices</h3>
        {data && data?.invoices?.length > 0 ? (
          <table className="min-w-full mt-2 text-sm table-auto">
            <thead>
              <tr className="border-b">
                <th className="py-2 px-4 text-left">Invoice #</th>
                <th className="py-2 px-4 text-left">Date</th>
                {/* <th className="py-2 px-4 text-left">Download</th> */}
              </tr>
            </thead>
            <tbody>
              {data?.invoices.map((invoice) => (
                <tr key={invoice.invoice_id} className="border-b">
                  <td className="py-2 px-4">{invoice.invoice_id}</td>
                  <td className="py-2 px-4">
                    {new Date(invoice.invoice_date).toLocaleDateString()}
                  </td>
                  {/* <td className="py-2 px-4">
                    <a
                      href={invoice.invoice_path}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      Download
                    </a>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-sm text-gray-500 mt-2">No invoices available.</p>
        )}
      </div>

      <div className="mt-6">
        <h3 className="text-md font-semibold border-b pb-2">Shipments</h3>
        {data && data?.shipments?.length > 0 ? (
          <table className="min-w-full mt-2 text-sm table-auto">
            <thead>
              <tr className="border-b">
                <th className="py-2 px-4 text-left">Shipment #</th>
                <th className="py-2 px-4 text-left">Status</th>
                <th className="py-2 px-4 text-left">Date</th>
              </tr>
            </thead>
            <tbody>
              {data?.shipments.map((shipment) => (
                <tr key={shipment.shipment_id} className="border-b">
                  <td className="py-2 px-4">{shipment.shipment_id}</td>
                  <td className="py-2 px-4">{shipment.status}</td>
                  <td className="py-2 px-4">
                    {new Date(shipment.shimpment_date).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-sm text-gray-500 mt-2">No shipments available.</p>
        )}
      </div>

      <div className="mt-6">
        <h3 className="text-md font-semibold border-b pb-2">Items</h3>
        {data && data?.items?.length > 0 ? (
          <table className="min-w-full mt-2 text-sm table-auto">
            <thead>
              <tr className="border-b">
                <th className="py-2 px-4 text-left">Product ID</th>
                <th className="py-2 px-4 text-left">Item Code</th>
                <th className="py-2 px-4 text-left">Quantity</th>
              </tr>
            </thead>
            <tbody>
              {data?.items.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="py-2 px-4">{item.product_id}</td>
                  <td className="py-2 px-4">{item.item_code || "N/A"}</td>
                  <td className="py-2 px-4">{item.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-sm text-gray-500 mt-2">No items available.</p>
        )}
      </div>
    </div>
  );
};

export default ComponentToPrintModal;
