import React from "react";
import { InvoiceData } from "../OrderHistory";
import print from "../assets/printer.svg";
import { invoiceItemKeys, invoiceKeyMap } from "../constants";

interface InvoiceModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: InvoiceData | undefined;
  handlePrintData: () => void;
}

const InvoiceModal: React.FC<InvoiceModalProps> = ({
  isOpen,
  onClose,
  data,
  handlePrintData,
}) => {
  if (!data) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ${
        isOpen ? "block" : "hidden"
      } p-4 sm:p-6 md:p-8`}
    >
      <div className="relative max-w-5xl bg-white p-4 sm:p-6 rounded-lg shadow-lg w-full max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg sm:text-xl font-bold text-gray-900">
            Invoice Details
          </h2>
          <div className="flex space-x-3">
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 text-xl absolute right-2 top-0 z-50"
            >
              &times;
            </button>
            <button
              onClick={handlePrintData}
              className="bg-[#1dbda1] text-white rounded-md px-4 py-2 text-sm hover:bg-[#17a488] focus:outline-none flex items-center"
            >
              <img className="mr-2" src={print} alt="Print" /> Print
            </button>
          </div>
        </div>
        <div className="p-4 space-y-4 rounded-lg text-xs sm:text-sm">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            {Object.entries(invoiceKeyMap).map(([label, dataKey]) => {
              const value = data[dataKey];

              return (
                <div key={label} className="flex justify-between border-b pb-2">
                  <span className="font-semibold text-gray-700">{label}:</span>
                  <span className="text-gray-600">
                    {typeof value === "object"
                      ? JSON.stringify(value)
                      : value || "-"}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="overflow-x-auto mt-6">
          <table className="w-full min-w-max border-collapse text-xs sm:text-sm">
            <thead className="bg-gray-100">
              <tr>
                {invoiceItemKeys.map((key) => (
                  <th
                    key={key}
                    className="px-2 sm:px-4 py-2 text-left text-gray-800 font-semibold"
                  >
                    {key}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.NavInvoiceItems.results.map((item, index) => {
                // Define the correct order
                const order = [
                  "Itemno",
                  "MaterialNo",
                  "MaterialDescEn", // Move after MaterialNo
                  "MaterialDescAr", // Move after MaterialNo
                  "ActQtyDel",
                  "Uom",
                  "unitPrice",
                  "VATAmount",
                  "NetValue",
                  "GrossValue",
                  "Total"
                ];

                // Sort object entries based on the defined order
                const sortedEntries = Object.entries(item)
                  .filter(([key]) => order.includes(key)) // Only keep relevant keys
                  .sort(([keyA], [keyB]) => order.indexOf(keyA) - order.indexOf(keyB));

                return (
                  <tr key={index} className="even:bg-gray-50">
                    {sortedEntries.map(([_, value], idx) => (
                      <td key={idx} className="px-2 sm:px-4 py-2 text-gray-700">
                        {value || "-"}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
            <thead className="bg-gray-200 sticky bottom-0 z-10 text-gray-700">
              <tr>
                <th className="px-4 py-3 text-gray-700 border text-left text-xs font-bold">
                  Total
                </th>
                <th className="px-4 py-3 text-gray-700 border text-left text-xs font-bold"></th>             
                   <th className="px-4 py-3 text-gray-700 border text-left text-xs font-bold"></th>
                <th className="px-4 py-3 border text-left text-xs font-bold"></th>
                <th className="px-4 py-3 border text-left text-xs font-bold"></th>
                <th className="px-4 py-3 text-gray-700 border text-left text-xs font-bold"></th>
                <th className="px-4 py-3 text-gray-700 border text-left text-xs font-bold"></th>
                <th className="px-4 py-3 text-gray-700 border text-left text-xs font-bold">
                  {data.VAT}
                </th>
                <th className="px-4 py-3 text-gray-700 border text-left text-xs font-bold">
                  {data.NetValue}
                </th>
                <th className="px-4 py-3 text-gray-700 border text-left text-xs font-bold">
                  {data.GrossValue}
                </th>
                <th className="px-4 py-3 text-gray-700 border text-left text-xs font-bold">
                  {data.TotalValue}
                </th>
                {/* <th className="px-4 py-3 text-gray-700 border text-left text-xs font-bold"></th> */}
                {/* <th className="px-4 py-3 text-gray-700 border text-left text-xs font-bold"></th> */}
             
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InvoiceModal;
