import React, { useEffect, useState } from "react";
import Layerzamzam from "./Layerzamzam";
import ViewInventory from "./Pages/ViewInventory";
import Table from "./components/Table";
import { toast, ToastContainer } from "react-toastify";
import ComponentToPrint from "./Pages/ComponentToPrint";
import { metaData, tableHeaders } from "./constants";
import {
  getDataTobePrinted,
  getExportData,
  getProductData,
  getWarehouseData,
} from "./api/inventory";
export interface TableData {
  results: Array<{
    StorageLocName: string;
    NavStockToItem: Array<{
      MaterialId: string;
      MaterialDesc: string;
      AvailableStock: string;
      InQualityInspection: string;
      Blocked: string;
      Return: string;
      InTransit: string;
      Unit: string;
      TotalAvailableStock: string;
      TotalInQualityInspection: string;
      TotalBlocked: string;
      TotalReturn: string;
      UnitText:string
      TotalInTransit: string;
    }>;
    TotalAvailableStock: string;
    TotalInQualityInspection: string;
    TotalBlocked: string;
    TotalReturns: string;
    TotalInTransits: string;
  }>;
}

const DashboardZamzam: React.FC = () => {
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState<TableData | null>(null);
  const [showSummesion, setShowSummesion] = useState(false);
  const [printTableData, setPrintTableData] = useState<TableData | null>(null);
  const [loading, setLoading] = useState(true);
  const [dataEmpty, setDataEmpty] = useState(false);

  const [wareHouseOptions, setWareHouseOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([{productId: "null", productName: "No data available"}]);

  const [warehouseDropdown, setWarehouseDropdown] = useState<{
    isOpen: boolean;
    selectedOption: string | null;
  }>({ isOpen: false, selectedOption: null });

  const [productDropdown, setProductDropdown] = useState<{
    isOpen: boolean;
    selectedOption: string | null;
  }>({ isOpen: false, selectedOption: null });

  const toggleTableToShow = () => {
    getTableData();
  };

  const handleClearData = () => {
    setShowTable(false);
    setShowSummesion(false);
    setLoading(true);
    setWarehouseDropdown({ isOpen: false, selectedOption: null });
    setProductDropdown({ isOpen: false, selectedOption: null });
    setProductOptions([]);
  };

  const exportData = async () => {
    if (warehouseDropdown.selectedOption == null) {
      toast.error("Please select a product");
      return;
    }
    try {
      const response = await getExportData(
        warehouseDropdown.selectedOption,
        productDropdown.selectedOption
      );
      if (response.status === 200) {
        const data = response.data;
        const url = window.URL.createObjectURL(data);
        const a = document.createElement("a");
        a.href = url;
        a.download = "inventory_report.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getPrintableData = async () => {
    if (warehouseDropdown.selectedOption == null) {
      toast.error("Please select a warehouse");
      return;
    }
    try {
      const response = await getDataTobePrinted(
        warehouseDropdown.selectedOption,
        productDropdown.selectedOption
      );
      if (response.status === 200) {
        setPrintTableData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getTableData = async () => {
    if (warehouseDropdown.selectedOption == null) {
      toast.error("Please select a warehouse");
      return;
    }
    try {
      const response = await fetch(
        `https://staging.sfa.berain.com.sa/web/api/v1/nwc/inventory/${warehouseDropdown.selectedOption}?productId=${productDropdown.selectedOption}`
      );
      if (response.ok) {
        const data = await response.json();
        setTableData(data);
        setShowTable(true);
        setShowSummesion(true);
      }else {
        setShowTable(false);
        setDataEmpty(true);
        setShowSummesion(false);
        toast.error("No Data Found for this warehouse");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Internal Server Error");
      setShowTable(false);
      setDataEmpty(false);
      setShowSummesion(false);
    }
  };

  const getWareHouseOptions = async () => {
    try {
      const response = await getWarehouseData();
      if (response.status === 200) {
        const data = response.data;
        setWareHouseOptions(
          data?.warehouses?.map(
            (item: { warehouseId: string; warehouseName: string }) => ({
              warehouseId: item.warehouseId,
              warehouseName: item.warehouseName,
            })
          )
        );
      }
    } catch (error) {
      console.error("Error fetching warehouse options:", error);
    }
  };

  const getProductOptions = async (warehouseId: string) => {
    try {
      const response = await getProductData(warehouseId);
      if(response.status === 200) {
        const data = response.data;
        if(data.products.length === 0){
          setProductOptions([{ productId: "null", productName: "No data available"}]);
          return;
        }
        setProductOptions(
          data.products?.map(
            (item: { MaterialId: string; MaterialDesc: string }) => ({
              productId: item.MaterialId,
              productName: item.MaterialDesc,
            })
          )
        );
      }
    } catch (error) {
      console.error("Error fetching product options:", error);
    }
  };

  useEffect(() => {
    getWareHouseOptions();
  }, [warehouseDropdown]);

  useEffect(() => {
    if (warehouseDropdown.selectedOption) {
      getProductOptions(warehouseDropdown.selectedOption);
    }
  }, [warehouseDropdown, productDropdown]);

  const printData = async () => {
    await getPrintableData();
  };

  useEffect(() => {
    if (!printTableData || !showTable) {
      return;
    }

    console.log(showTable, "<<< Content of Printable Table");
    const printContent = document.getElementById("print-content");
    if (!printContent) return;

    const newWindow = window.open("", "_blank", "width=800,height=600");
    if (newWindow) {
      printContent.classList.remove("hidden");
      printContent.classList.add("block");
      const link = newWindow.document.createElement("link");
      link.href =
        "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
      link.rel = "stylesheet";
      newWindow.document.head.appendChild(link);

      link.onload = () => {
        newWindow.document.body.innerHTML = `
        <html>
          <head>
            <title>Print</title>
          </head>
          <body>${printContent.outerHTML}</body>
        </html>
      `;

        newWindow.document.close();
        newWindow.focus();
        newWindow.print();
        newWindow.close();
        printContent.classList.remove("block");
        printContent.classList.add("hidden");
      };
    }
  }, [printTableData]);

  return (
    <Layerzamzam>
      <ToastContainer />
      <div className="bg-gray-50 sm:pl-0 lg:pr-4">
        <ViewInventory
          toggleTableToShow={toggleTableToShow}
          toggleTableToHide={handleClearData}
          showTable={showTable}
          warehouseDropdown={warehouseDropdown}
          setWarehouseDropdown={setWarehouseDropdown}
          productDropdown={productDropdown}
          setProductDropdown={setProductDropdown}
          wareHouseOptions={wareHouseOptions}
          productOptions={productOptions}
          exportData={exportData}
          printData={printData}
        />
        {showTable ? (
          <Table
            showSummesion={showSummesion}
            loading={loading}
            setLoading={setLoading}
            tableData={tableData}
          />
        ):  dataEmpty &&<p className="text-center text-sm mt-3">No Data Found</p> }
      </div>
      <div>
        <ComponentToPrint
          columns={tableHeaders}
          data={printTableData}
          metadata={metaData}
        />
      </div>
    </Layerzamzam>
  );
};

export default DashboardZamzam;
