import Cookies from "js-cookie";
import axios from "./config";
import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  emp_id: string;
}

export function getHeader() {
  const token = Cookies.get("token") || "";
  const header = {
    Authorization: token,
  };
  return header;
}

const getUserInfo = () => {
  const token = Cookies.get("token");

  if (!token) {
    throw new Error("Authorization token not found");
  }

  return jwtDecode<DecodedToken>(token);
};

const getPlanogramList = async (params: any, data: any): Promise<any> => {
  const user_info = getUserInfo();

  const response = await axios.post<any>(
    `/reports/${user_info.emp_id}/planogram-report`,
    data,
    {
      headers: getHeader(),
      params: params,
    }
  );
  return response.data;
};

export const getExportData = async (
  warehouse: string | null,
  product: string | null
): Promise<any> => {
  const response = await axios.get<any>(
    `nwc/export/inventory/${warehouse}productId=${product}`,
    {
      responseType: "blob",
    }
  );
  return response;
};

export const getDataTobePrinted = async (
  warehouse: string | null,
  product: string | null
): Promise<any> => {
  const response = await axios.get<any>(
    `nwc/inventory/${warehouse}?productId=${product}`
  );
  return response;
};

export const getInventoryData = async (
  warehouse: string | null,
  product: string | null
): Promise<any> => {
  const response = await fetch(
    `nwc/export/inventory/${warehouse}?productId=${product}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    console.log(data, "success");
    return data;
  }else {
    return null;
  }
};

export const getWarehouseData = async (): Promise<any> => {
  const response = await axios.get<any>(`/nwc/warehouse`);
  return response;
};
export const getProductData = async (
  warehouse: string | null
): Promise<any> => {
  const response = await axios.get<any>(`/nwc/products/${warehouse}`);
  return response;
};

export default getPlanogramList;
